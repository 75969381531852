import HeaderFooterLayout, { AssetProps } from '../../js/layout/HeaderFooterLayout';
import ImportedAccountPage from '../../js/register/ImportedAccountPage';

export default function () {
    return <ImportedAccountPage />;
}

export function StaticLayout({ assets }: AssetProps) {
    return <HeaderFooterLayout assets={assets} robots="noindex,nofollow" />;
}
