import HeaderFooterLayout, { AssetProps } from '../../js/layout/HeaderFooterLayout';
import OrderHistoryPage from '../../js/order/OrderHistoryPage';
import Authenticated from '../../js/utils/Authenticated';

export default function () {
    return (
        <Authenticated>
            <OrderHistoryPage />
        </Authenticated>
    );
}

export function StaticLayout({ assets }: AssetProps) {
    return <HeaderFooterLayout assets={assets} title="Mine kjøp" robots="noindex, nofollow" page_title="Mine kjøp" />;
}
