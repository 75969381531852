import { useContext } from 'react';
import AltSR from '../generic/AltSR';
import Icon from '../generic/Icon';
import InfoBox from '../generic/InfoBox';
import { TrackingContext, WrapTrackingContext } from '../generic/TrackingContext';
import { isGiftCard, isGiftPackaging } from '../model/Product';
import { FormattedPrice, Product } from '../model/types';
import QuantityPicker from '../product/QuantityPicker';

export interface Entry {
    entryNumber: number;
    product: Product & { maxQuantity?: number };
    basePrice: FormattedPrice;
    totalPrice: FormattedPrice;
    quantity: number;
    formattedLeadTime?: string;
    error?: { message: string };
}

export interface Props {
    entry: Entry;
    onQuantityChanged?: () => Promise<unknown>;
    deleteCartEntry?: (entryNumber: number) => Promise<unknown>;
    showStockStatus?: boolean;
}

export default function CartEntry({ entry, showStockStatus = false, onQuantityChanged, deleteCartEntry }: Props) {
    const editable = !!onQuantityChanged && !!deleteCartEntry;
    const imgUrl = entry.product.images?.find((i) => i.format === 'product')?.url;

    const { item_id, index } = useContext(TrackingContext);

    return (
        <li className="product-item" key={entry.product.code} id={`cartEntryCode${entry.product.code}`}>
            <div className="product-item__image">
                <a className="button" href={entry.product.url} tabIndex={-1}>
                    <img src={imgUrl} alt={`Bilde av ${entry.product.name}`} />
                </a>
            </div>
            <div className="info-container">
                <div className="dispersed-alignment">
                    <h3 className="product__name">
                        <a className="link-text button" href={entry.product.url}>
                            {entry.product.name}
                        </a>
                    </h3>
                    {!!deleteCartEntry && (
                        <button
                            onClick={() =>
                                deleteCartEntry(entry.entryNumber).then(() =>
                                    gtag('event', 'remove_from_cart', {
                                        items: [{ item_id, index, quantity: entry.quantity }],
                                    }),
                                )
                            }
                            type="button"
                            aria-label="Fjern produkt fra handlekurven"
                            className="btn--transparent"
                        >
                            <Icon className="icon-delete" />
                        </button>
                    )}
                </div>
                <div className="product-item-price-and-volume">
                    <div className="product__price">
                        <AltSR text={entry.basePrice.formattedValue} sr={'Pris per enhet: ' + entry.basePrice.readableValue} />
                    </div>
                    <div className="volume-and-cost_per_unit">
                        <AltSR text={entry.product.volume.formattedValue} sr={entry.product.volume.readableValue} />
                    </div>
                </div>

                <div className="product-item-quantity-and-sum">
                    {!!onQuantityChanged ? (
                        <WrapTrackingContext set={{ value: isGiftCard(entry.product) ? entry.basePrice.value : undefined }}>
                            <QuantityPicker
                                onQuantityChanged={onQuantityChanged}
                                entryNumber={entry.entryNumber}
                                quantity={entry.quantity}
                                maxQuantity={entry.product.maxQuantity}
                            />
                        </WrapTrackingContext>
                    ) : (
                        <div className="product-item__quantity">
                            <AltSR text={entry.quantity + ' stk.'} sr={entry.quantity + ' stykk.'} />
                        </div>
                    )}
                    <div className="total-sum">
                        <AltSR
                            text={entry.totalPrice.formattedValue}
                            sr={`Totalpris for ${entry.quantity} produkter: ${entry.totalPrice.readableValue}`}
                        />
                    </div>
                </div>

                {editable && showStockStatus && entry.formattedLeadTime && (
                    <div className="product-item__stock-status">{entry.formattedLeadTime}</div>
                )}
                {!!entry.error && (
                    <p className="product-item__error_msg" key={'ec' + entry.product.code}>
                        {entry.error.message}
                    </p>
                )}
                {isGiftPackaging(entry.product) && <InfoBox className="product-item-info-box" message="Flasker pakkes ikke inn i gaveemballasjen" />}
            </div>
        </li>
    );
}
