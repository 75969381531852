import { CartSummaryData } from '../cart/CartSummary';
import { GreetingCard } from '../cart/greetingcard/GreetingCardModule';

export function getExtendedDeliveryInfo(cart: CartSummaryData) {
    if (cart.deliveryMode != null && cart.deliveryMode?.type === 'PICK_UP_STORE' && cart.selectedPointOfService != null) {
        return cart.selectedPointOfService.displayName;
    }
    return null;
}

export function hasGreetingCard(cart: GreetingCard) {
    return cart.greetingCardFrom && cart.greetingCardTo && cart.greetingCardMessage;
}

export function hasPackageMaterialCost(cart: CartSummaryData) {
    return (cart.deliveryMode != null && cart.packageMaterialCost != null && cart.packageMaterialCost.value > 0);
}

export function hasDeliveryCost(cart: CartSummaryData) {
    if (!cart.deliveryCost || !cart.deliveryMode) return false;
    return cart.deliveryCost.value > 0;
}
