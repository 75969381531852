import cn from 'classnames';
import { useCallback, useContext, useRef, useState } from 'react';
import { SearchResponse, search as fallbackSearch } from '../api/search';
import { ErrorBoxType } from '../generic/ErrorBox';
import InfoBox from '../generic/InfoBox';
import Page from '../generic/Page';
import Pagination from '../generic/Pagination';
import { UserContext } from '../login/userContext';
import { FacetGroup } from '../model/Facets';
import { QueryParams, SearchData, findGroupedFacet, getPageTitle, getSearchData, handleScroll, isFacetGroup, updateHistory } from '../model/Search';
import { Coords } from '../storelocator/StoreLocatorForm';
import * as url from '../utils/url';
import useBooleanState from '../utils/useBooleanState';
import { useOnMount } from '../utils/useComponentDidMount';
import ContentList from './ContentList';
import NoResults from './NoResults';
import ProductList from './ProductList';
import ContentSearchFacetBar, { ProductSearchFacetBar, SearchTypeAndSortBar } from './SearchControls';
import SearchFacets from './SearchFacets';

interface Props {
    searchType?: 'product' | 'content';
    pageTitle: string;
    press?: string;
    hideArticleFacets?: boolean;
    hideSearchControls?: boolean;
    showPrimaryCategory?: boolean;
    search?(query: string): Promise<SearchResponse>;
}

export default function SearchPage({
    pageTitle,
    hideSearchControls,
    press,
    searchType: initialSearchType,
    showPrimaryCategory,
    search: query = fallbackSearch,
}: Props) {
    const [isLoading, setIsLoading] = useBooleanState(true);
    const [error, setError] = useState<ErrorBoxType | null>(null);
    const [data, setData] = useState<SearchData | null>(null);
    const { user } = useContext(UserContext);

    /**
     * One of the following values:
     * * `FacetGroup`: Show single facet in popover
     * * `true`: Show all facets in popover
     * * `false`: Hide popover
     */
    const [facetsPopover, setFacetsPopover] = useState<FacetGroup | boolean>(false);

    const queryParams = useRef<QueryParams>({
        fields: 'FULL',
        pageSize: '24',
        searchType: initialSearchType ?? 'product',
        currentPage: '0',
        ...(press ? { press } : {}),
        q: ':',
    });

    useOnMount(() => {
        const params = url.getParams();

        params.currentPage = params.currentPage ?? '0';

        if (params.q) {
            params.q = params.q.replace('::', ':relevance:');
        }

        history.scrollRestoration = 'manual';
        search(params, true);

        window.addEventListener('popstate', () =>
            search(
                {
                    currentPage: '0',
                    ...url.getParams(),
                },
                true,
            ),
        );
    });

    const search = useCallback(
        async (params: Partial<QueryParams>, replaceHistory = false) => {
            setIsLoading.toTrue();
            setError(null);

            try {
                const queryString = new URLSearchParams({
                    ...queryParams.current,
                    ...params,
                }).toString();

            const { searchType, contentSearchResult, productSearchResult, previousKeyword } = await query(queryString);
                if (productSearchResult?.keywordRedirectUrl) {
                    window.location.assign(productSearchResult.keywordRedirectUrl);
                    return;
                }

                const data = getSearchData(searchType, contentSearchResult, productSearchResult, user?.favouriteStore);

                setData(data);
                setFacetsPopover((f) => (isFacetGroup(f) ? findGroupedFacet(data, f.code) ?? false : f));

                gtag('event', 'view_search_results', {
                    filter_string: data.currentQuery,
                    search_term: data.currentSearchTerm,
                    num_results: data.totalResults,
                });

                queryParams.current = {
                    ...queryParams.current,
                    q: data.currentQuery,
                    searchType: data.searchType,
                    currentPage: data.currentPage.toString(),
                    ...(params.latitude &&
                        params.longitude && {
                            latitude: params.latitude,
                            longitude: params.longitude,
                        }),
                    ...(previousKeyword ? {previousKeyword} : {}),
                };
                updateHistory(queryParams.current, replaceHistory, data.contentSearchResult, !!initialSearchType);
                handleScroll('searchScroll');
            } catch (error: any) {
                setError(error);
            } finally {
                setIsLoading.toFalse();
            }
        },
        [user],
    );

    const changeSorting = useCallback(
        (oldCode: string, newCode: string) => {
            search({
                q: queryParams.current.q.replace(oldCode, newCode),
            });
        },
        [search],
    );

    const changeSearchType = useCallback(
        (searchType: 'product' | 'content') => {
            search({
                searchType,
                currentPage: '0',
            });
        },
        [search],
    );

    const searchWithQuery = useCallback(
        (q: string) => {
            search({
                q,
                currentPage: '0',
            });
        },
        [search],
    );

    const goToPage = useCallback(
        (pageNumber: number) => {
            search({
                currentPage: pageNumber.toString(),
            });
        },
        [search],
    );

    const onResetFacets = useCallback(() => {
        search({
            q: ':',
            currentPage: '0',
        });
    }, [search]);

    const onLocationChange = useCallback(({ latitude, longitude }: Coords) => {
        search({
            latitude: latitude.toString(),
            longitude: longitude.toString(),
        });
    }, []);

    const showFacetsPopover = useCallback(() => setFacetsPopover(true), []);
    const hideFacetPopover = useCallback(() => setFacetsPopover(false), []);

    if (!data) {
        return <Page isSpinning={isLoading} error={error} sectionClassname="search-page-wrapper" />;
    }

    const { searchType, productSearchResult, contentSearchResult, currentQuery } = data;

    const freeTextSearch = searchType === 'product' ? productSearchResult?.freeTextSearch : contentSearchResult?.freeTextSearch;
    return (
        <Page isSpinning={isLoading} error={error} sectionClassname="search-page-wrapper" title={getPageTitle(freeTextSearch, pageTitle)}>
            {searchType === 'content' && contentSearchResult.pagination.totalResults ? (
                <>
                    <div className="search-controls">
                        <ContentSearchFacetBar facets={contentSearchResult.facets} onClickFilter={showFacetsPopover} />
                        {!hideSearchControls && (
                            <SearchTypeAndSortBar
                                selectedSearch="content"
                                totalContentResults={contentSearchResult.pagination.totalResults}
                                totalProductResults={productSearchResult?.pagination.totalResults}
                                changeSearchType={changeSearchType}
                                sortValues={contentSearchResult.sorts}
                                onSortSelect={changeSorting}
                            />
                        )}
                    </div>
                    <div className={cn('search-wrapper', { 'hide-facets': !contentSearchResult.facets.any })}>
                        <SearchFacets
                            facets={contentSearchResult.facets}
                            totalResults={contentSearchResult.pagination.totalResults}
                            facetsPopover={facetsPopover}
                            onFacetValueSelect={searchWithQuery}
                            onLocationChange={onLocationChange}
                            onFacetsCloseClicked={hideFacetPopover}
                            onResetClicked={onResetFacets}
                        />
                        <div id="search-results" tabIndex={-1} className="content-search-result-list section__productlist">
                            <ContentList
                                articles={contentSearchResult.results}
                                numberOfArticlesWidth={contentSearchResult.facets.any ? 3 : 4}
                                showPrimaryCategory={showPrimaryCategory}
                            />
                            <Pagination {...contentSearchResult.pagination} onGotoPage={goToPage} />
                        </div>
                    </div>
                </>
            ) : productSearchResult?.pagination.totalResults ? (
                <>
                    <div className="search-controls">
                        <ProductSearchFacetBar
                            facets={productSearchResult.facets}
                            onClickFilter={showFacetsPopover}
                            onFacetValueUnselect={searchWithQuery}
                            onSingleFacetChoice={setFacetsPopover}
                        />
                        <SearchTypeAndSortBar
                            selectedSearch="product"
                            totalContentResults={contentSearchResult?.pagination.totalResults}
                            totalProductResults={productSearchResult.pagination.totalResults}
                            changeSearchType={changeSearchType}
                            sortValues={productSearchResult.sorts}
                            onSortSelect={changeSorting}
                        />
                    </div>
                    <div className="search-wrapper">
                        <SearchFacets
                            facets={productSearchResult.facets}
                            totalResults={productSearchResult.pagination.totalResults}
                            facetsPopover={facetsPopover}
                            onFacetValueSelect={searchWithQuery}
                            onLocationChange={onLocationChange}
                            onFacetsCloseClicked={hideFacetPopover}
                            onResetClicked={onResetFacets}
                        />
                        <div id="search-results" tabIndex={-1} className="section__productlist">
                            <InfoBox message={productSearchResult.broadcastMessage} />
                            <ProductList
                                products={productSearchResult.products}
                                onClick={rememberScrollPosition}
                                showAvailability
                                pagination={productSearchResult.pagination}
                                onGotoPage={goToPage}
                                tracking_name="Resultater"
                                tracking_id={currentQuery}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <NoResults searchWithQuery={searchWithQuery} spellingSuggestion={productSearchResult?.spellingSuggestion} />
            )}

            <div className={cn('search-facets-overlay', { visible: facetsPopover !== false })} onClick={hideFacetPopover} />
        </Page>
    );
}

function rememberScrollPosition() {
    sessionStorage.setItem('searchScroll', scrollY.toString());
}
