import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import * as api from '../api';
import { addCartEntry, deleteCartEntry, getCart, getMiniCart, updateCartEntry } from '../api/cart';
import {
    cartDeliveryModeURL,
    cartEntriesURL,
    cartsURL,
    currentUsersCartURL,
    deliveryAddressURL,
    deliveryModesURL,
    greetingCardURL,
    listToCartURL,
    pointOfServiceURL,
} from '../config/CartAPIConfig';
import { UserContext } from '../login/userContext';
import { FormattedPrice } from '../model/types';
import { catchNotFoundAndRetry } from '../utils/catchAndRetry';
import { removeJson, saveJson, subscribeToJson } from '../utils/jsonStorage';
import { DeliveryMode } from './delivery/DeliveryMode';

const cartKey = 'cartId';

/**
 * Get a promise with an exposed resolver
 */
function getResolvablePromise(): Promise<void> & { resolve(): void } {
    let resolve: () => void = null as any;
    const promise: any = new Promise<void>((res) => (resolve = res));
    promise.resolve = resolve;
    return promise;
}

class Cart {
    private _isLoggedIn: boolean | undefined = undefined;
    private _cartId: string | null = null;
    private readonly _isReady = getResolvablePromise();
    private _miniCartListeners = new Set<(miniCart: MiniCart) => void>();

    constructor() {
        subscribeToJson<string | null>(cartKey, (cartId) => {
            this._cartId = cartId;
        });
    }

    initialize(isLoggedIn: boolean, setMiniCart: (miniCart: MiniCart) => void) {
        // remove cartId from localStorage when you log in
        if (isLoggedIn) removeJson(cartKey);

        // isLoggedIn is no longer undefined
        if (this._isLoggedIn === undefined) this._isReady.resolve();

        this._isLoggedIn = isLoggedIn;
        this._miniCartListeners.add(setMiniCart);
        return () => {
            this._miniCartListeners.delete(setMiniCart);
        };
    }

    getCartAndUserId = async () => {
        // wait for isLoggedIn to become either true or false
        await this._isReady;

        if (this._isLoggedIn) {
            return {
                cartId: 'current' as const,
                userId: 'current' as const,
            };
        } else {
            return {
                cartId: this._cartId,
                userId: 'anonymous' as const,
            };
        }
    };

    getOrCreateCartAndUserId = async () => {
        const { cartId, userId } = await this.getCartAndUserId();
        if (cartId) return { cartId, userId };

        const { guid } = await api.post<{ guid: string }>(cartsURL(userId));
        saveJson(cartKey, guid);
        return { cartId: guid, userId };
    };

    fetchMiniCart = async (reload = false) => {
        const { cartId, userId } = await this.getCartAndUserId();
        if (!cartId) return 0;

        try {
            const { totalUnitCount, entries } = await getMiniCart(userId, cartId, reload);
            const cartEntries = new Map(
                entries.map(({ product: { code }, quantity, entryNumber, basePrice }) => [code, { entryNumber, code, quantity, price: basePrice }]),
            );
            this.setMiniCart({
                totalUnitCount,
                getProduct: (code: string) => cartEntries.get(code),
            });
        } catch (error) {
            console.error(error);
        }
    };

    private setMiniCart = (miniCart: MiniCart) => {
        this._miniCartListeners.forEach((m) => m(miniCart));
    };

    getCart = catchNotFoundAndRetry(async () => {
        const { cartId, userId } = await this.getOrCreateCartAndUserId();
        return await getCart(userId, cartId).catch(removeCartIdOnNotFound);
    });

    getDeliveryModes = async () => {
        const { cartId, userId } = await this.getOrCreateCartAndUserId();
        return await api.get<{ deliveryModes: DeliveryMode[] }>(deliveryModesURL(userId, cartId)).catch(removeCartIdOnNotFound);
    };

    addToCart = catchNotFoundAndRetry(async (code: string, qty = 1, price?: number) => {
        const { cartId, userId } = await this.getOrCreateCartAndUserId();
        const response = await addCartEntry(userId, cartId, code, qty, price).catch(removeCartIdOnNotFound);
        await this.fetchMiniCart(true);
        return response;
    });

    updateCartEntry = async (entryNumber: number, newQty: number) => {
        const { cartId, userId } = await this.getOrCreateCartAndUserId();
        const response = await updateCartEntry(userId, cartId, entryNumber, newQty).catch(removeCartIdOnNotFound);
        await this.fetchMiniCart(true);
        return response;
    };

    deleteCartEntry = async (entryNumber: number) => {
        const { cartId, userId } = await this.getOrCreateCartAndUserId();
        const response = await deleteCartEntry(userId, cartId, entryNumber).catch(removeCartIdOnNotFound);
        await this.fetchMiniCart(true);
        return response;
    };

    deleteCartEntries = async () => {
        const { cartId, userId } = await this.getOrCreateCartAndUserId();
        await api.delete(cartEntriesURL(userId, cartId)).catch(removeCartIdOnNotFound);
        await this.fetchMiniCart(true);
    };

    mergePreviousCart = async (oldCartId: string | null) => {
        if (oldCartId && oldCartId !== 'current') {
            await api.post(currentUsersCartURL(), { oldCartId }).catch(removeCartIdOnNotFound);
        }
    };

    selectPointOfService = async (storeId: string) => {
        const { cartId, userId } = await this.getOrCreateCartAndUserId();
        await api.putJson(pointOfServiceURL(userId, cartId, storeId)).catch(removeCartIdOnNotFound);
    };

    setDeliveryMode = async (mode: string) => {
        const { cartId, userId } = await this.getOrCreateCartAndUserId();
        await api.putJson(cartDeliveryModeURL(userId, cartId, mode)).catch(removeCartIdOnNotFound);
    };

    setDeliveryAddress = async (addressId: string) => {
        const { cartId, userId } = await this.getOrCreateCartAndUserId();
        await api.putJson(deliveryAddressURL(userId, cartId, addressId)).catch(removeCartIdOnNotFound);
    };

    setGreetingCard = async (from: string, to: string, message: string) => {
        const { cartId, userId } = await this.getOrCreateCartAndUserId();
        await api.post(greetingCardURL(userId, cartId), { from, to, message }).catch(removeCartIdOnNotFound);
    };

    removeGreetingCard = async () => {
        const { cartId, userId } = await this.getOrCreateCartAndUserId();
        await api.delete(greetingCardURL(userId, cartId)).catch(removeCartIdOnNotFound);
    };

    addListToCart = async (listId: string) => {
        const { cartId, userId } = await this.getOrCreateCartAndUserId();
        await api.post(listToCartURL(userId, cartId, listId)).catch(removeCartIdOnNotFound);
        await this.fetchMiniCart(true);
    };
}

export const cart = new Cart();

export const CartContext = createContext<Cart>(cart);

export interface MiniCartProduct {
    code: string;
    quantity: number;
    entryNumber: number;
    price: FormattedPrice;
}

export interface MiniCart {
    totalUnitCount: number;
    getProduct(code: string): undefined | MiniCartProduct;
}

export const MiniCartContext = createContext<MiniCart | null>(null);

export function CartContextProvider({ children }: PropsWithChildren) {
    const { isLoggedIn } = useContext(UserContext);
    const [miniCart, setMiniCart] = useState<MiniCart | null>(null);

    useEffect(() => {
        if (isLoggedIn !== undefined) {
            return cart.initialize(isLoggedIn, setMiniCart);
        }
    }, [isLoggedIn]);

    return (
        <CartContext.Provider value={cart}>
            <MiniCartContext.Provider value={miniCart}>{children}</MiniCartContext.Provider>
        </CartContext.Provider>
    );
}

function removeCartIdOnNotFound(e: any): never {
    if (e instanceof api.NotFoundError) {
        removeJson(cartKey);
    }

    throw e;
}
