import * as api from '../api';
import { authURL } from '../config/NewAPIConfig';
import * as SiteConfig from '../config/SiteConfig';
import { currentUserURL } from '../config/UserAPIConfig';
import { User } from '../model/types';
import { clearToken, getToken, onTokenChange, setToken, setUser } from './authToken';
import syncBlock from './syncBlock';

const client_id = 'web_client';
const expirationFuzz = 30;

interface ErrorResponse {
    error: string;
    error_description: string;
}

interface SuccessResponse {
    access_token: string;
    token_type: string;
    refresh_token: string;
    expires_in: number;
    scope: string;
    refresh_token_expires_in: number;
    device_id: string;
}

type GetTokenParams =
    | {
          grant_type: 'password';
          username: string;
          password: string;
          device_id: string;
      }
    | {
          grant_type: 'refresh_token';
          refresh_token: string;
          device_id: string;
      };

async function fetchOauthToken(params: GetTokenParams) {
    const response = await fetch(authURL, {
        method: 'POST',
        body: new URLSearchParams({
            client_id,
            ...params,
        }),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
    });

    if (!response.ok) {
        if (response.status == 400) {
            const error = (await response.json()) as ErrorResponse;

            throw error;
        } else {
            throw new Error(await response.text());
        }
    }

    const token = (await response.json()) as SuccessResponse;

    const now = Date.now() - expirationFuzz * 1000;

    return {
        accessToken: {
            value: token.access_token,
            expiresAt: now + token.expires_in * 1000,
        },
        refreshToken: {
            value: token.refresh_token,
            expiresAt: now + token.refresh_token_expires_in * 1000,
        },
        deviceId: token.device_id,
    };
}

const refreshAccessToken = syncBlock(async () => {
    const token = getToken();
    if (!token) return null;

    try {
        const { accessToken, refreshToken, deviceId } = await fetchOauthToken({
            grant_type: 'refresh_token',
            refresh_token: token.refreshToken.value,
            device_id: token.deviceId,
        });

        return setToken(accessToken, refreshToken, deviceId);
    } catch (error) {
        console.error('Refresh token not valid', error);
        clearToken();
        return null;
    }
});

export async function getAccessToken() {
    let token = getToken();
    if (!token) return null;

    if (token.refreshToken.expiresAt < Date.now()) {
        console.error('Refresh token has expired');
        clearToken();
        return null;
    }

    if (token.accessToken.expiresAt < Date.now()) {
        console.warn('Access token has expired, refreshing it');
        token = await refreshAccessToken();
        await refreshUser();
    }

    return token?.accessToken.value ?? null;
}

export async function login(username: string, password: string) {
    const { accessToken, refreshToken, deviceId } = await fetchOauthToken({
        grant_type: 'password',
        username,
        password,
        device_id: getDeviceId(),
    });

    setToken(accessToken, refreshToken, deviceId);
    await refreshUser();
}

function getDeviceId() {
    return `${navigator.userAgent} ${new Date().toISOString()}`.replace('Mozilla/5.0 ', '').replace('(KHTML, like Gecko) ', '').substring(0, 255);
}

export const refreshUser = syncBlock(async () => {
    const user = await api.get<User>(currentUserURL());
    setUser(user);
});

export function onUserChange(onChange: (user: User | null) => void): () => void {
    return onTokenChange((t) => onChange(t?.user ?? null));
}

export async function logout() {
    clearToken();
}

export function handleUnauthorizedError() {
    // Make sure the token is gone, so we don't get more errors
    clearToken();

    // Trigger the Logged Out modal window
    window.dispatchEvent(new CustomEvent('vmpUserLoggedOut'));
}

export function handleNotAuthenticated() {
    // Make sure the token is gone, so we don't get more errors
    clearToken();

    // Redirect to login page, unless we are on some certain pages
    switch (window.location.pathname) {
        // copied from notRedirectedPages used in MissingEmailVerificationFilter.java
        case '/emailverification/verificationrequired':
        case '/emailverification/verify':
        case '/logout':
        case '/login':
        case '/personvern':
            return;
        default:
            location.assign(SiteConfig.loginURLWithRedirect());
    }
}
