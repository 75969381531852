import CartPage from '../js/cart/CartPage';
import HeaderFooterLayout, { AssetProps } from '../js/layout/HeaderFooterLayout';

export default function () {
    return <CartPage />;
}

export function StaticLayout({ assets }: AssetProps) {
    return (
        <HeaderFooterLayout
            assets={assets}
            title="Din handlekurv"
            robots="noindex,nofollow"
            className="page-cartPage"
            pageLabel="cart"
            page_title={false}
        />
    );
}
