import cn from 'classnames';
import { CardPaymentInfo } from '../api/payment';
import PaymentInfoLogo from './PaymentInfoLogo';

export interface Props {
    className?: string;
    paymentInfo: CardPaymentInfo;
}

export default function PaymentInfo({ className, paymentInfo }: Props) {
    return (
        <div className={cn('payment-info', className)}>
            <PaymentInfoLogo logo={paymentInfo.cardType.code} />
            <div>
                <div>{paymentInfo.cardType.name}</div>
                <div className="payment-info__card-number">{paymentInfo.cardNumber}</div>
                <div>{paymentInfo.expired ? 'Utgått kort' : `${paymentInfo.expiryMonth} / ${paymentInfo.expiryYear}`}</div>
            </div>
        </div>
    );
}
