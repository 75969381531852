import { DependencyList, useEffect } from 'react';
import { useUpdateEffect } from 'usehooks-ts';

export type AsyncEffectCalback = (signal: AbortSignal) => Promise<void>;

export default function useAsyncEffect(effect: AsyncEffectCalback, deps?: DependencyList) {
    useEffect(() => {
        const abortController = new AbortController();
        effect(abortController.signal);
        return () => abortController.abort();
    }, deps);
}

export function useAsyncUpdateEffect(effect: AsyncEffectCalback, deps?: DependencyList) {
    useUpdateEffect(() => {
        const abortController = new AbortController();
        effect(abortController.signal);
        return () => abortController.abort();
    }, deps);
}
