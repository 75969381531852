import HeaderFooterLayout, { AssetProps } from '../js/layout/HeaderFooterLayout';
import ProductGuide from '../js/productGuide';

export default function () {
    return <ProductGuide />;
}

export function StaticLayout({ assets }: AssetProps) {
    return <HeaderFooterLayout assets={assets} title="Drikkevelger" page_title="Drikkevelger" />;
}
