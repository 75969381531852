import { Component } from 'react';
import * as api from '../api';
import { userAPI } from '../config';
import ErrorBox from '../generic/ErrorBox';
import PasswordStrengthIndicator from '../generic/PasswordStrengthIndicator';
import SpinnerContainer from '../generic/SpinnerContainer';
import { ToastContext } from '../generic/Toast/toast-context';
import SubmitButton from '../generic/forms/SubmitButton';
import eventTargetValue from '../utils/eventTargetValue';
import preventDefault from '../utils/preventDefault';

export default class ChangePasswordSection extends Component {
    context!: ToastContext;
    static contextType = ToastContext;

    state = {
        oldPassword: '',
        newPassword: '',
        repeatNewPassword: '',
        isEditMode: false,
        error: null,
        isLoading: false,
    };

    changeEditMode = () => {
        this.setState({
            oldPassword: '',
            newPassword: '',
            repeatNewPassword: '',
            isEditMode: !this.state.isEditMode,
            error: null,
            isLoading: false,
        });
    };

    updatePassword = () => {
        this.setState({
            error: null,
            isLoading: true,
        });
        api.putJson(userAPI.updatePasswordURL(), {
            currentPassword: this.state.oldPassword,
            password: this.state.newPassword,
            checkPassword: this.state.repeatNewPassword,
        }).then(
            () => {
                this.context.addToast('Ditt passord er endret.');
                this.changeEditMode();
            },
            (error) => {
                this.setState({
                    error: error,
                    isLoading: false,
                });
            }
        );
    };

    render() {
        return (
            <SpinnerContainer isSpinning={this.state.isLoading}>
                <section className="white form-section">
                    <div className="form-section-info">
                        <h2>Passord</h2>
                        {this.state.isEditMode && <p>Passordet må bestå av minst 6 tegn.</p>}
                    </div>
                    <fieldset>
                        {this.state.isEditMode ? (
                            <div>
                                <ErrorBox errors={this.state.error} className="margin-bottom" />
                                <form className="formVmp" onSubmit={preventDefault(this.updatePassword)} id="change-password-form">
                                    <div className="form-group">
                                        <label className="control-label">Nåværende passord</label>
                                        <input
                                            type="password"
                                            name="oldPassword"
                                            className="form-control"
                                            defaultValue=""
                                            onChange={eventTargetValue((oldPassword) => this.setState({ oldPassword }))}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">Nytt passord</label>
                                        <input
                                            type="password"
                                            name="newPassword"
                                            className="form-control"
                                            defaultValue=""
                                            onChange={eventTargetValue((newPassword) => this.setState({ newPassword }))}
                                        />
                                        <PasswordStrengthIndicator password={this.state.newPassword} />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">Bekreft nytt passord</label>
                                        <input
                                            type="password"
                                            name="repeatNewPassword"
                                            className="form-control"
                                            defaultValue=""
                                            onChange={eventTargetValue((repeatNewPassword) => this.setState({ repeatNewPassword }))}
                                        />
                                    </div>
                                </form>
                                <div className="form-buttons">
                                    <button className="btn-secondary" onClick={this.changeEditMode}>
                                        Avbryt
                                    </button>
                                    <SubmitButton isLoading={this.state.isLoading} form="change-password-form">
                                        Lagre
                                    </SubmitButton>
                                </div>
                            </div>
                        ) : (
                            <div className="form-buttons-nomargin">
                                <button className="btn-secondary" onClick={this.changeEditMode}>
                                    Endre
                                </button>
                            </div>
                        )}
                    </fieldset>
                </section>
            </SpinnerContainer>
        );
    }
}
