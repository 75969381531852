import { PropsWithChildren } from 'react';
import { PaymentCardLogos } from '../../api/payment';
import PaymentInfoLogo from '../../checkout/PaymentInfoLogo';
import RadioButton from '../../generic/RadioButton';

export interface Props {
    title: string;
    selected: boolean;
    onSelect(): void;
    logo: PaymentCardLogos;
}

export default function CheckoutOptionItem({ selected = false, title, onSelect, logo, children }: PropsWithChildren<Props>) {
    return (
        <div className={selected ? 'selected' : ''}>
            <label className="option-list-item-content">
                <RadioButton selected={selected} value={title} name="Betalingsmåte" onSelect={onSelect} />
                <PaymentInfoLogo logo={logo} />
                {title}
            </label>
            {selected && children}
        </div>
    );
}
