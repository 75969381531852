import EmailConfirmationPage from '../../js/account/EmailConfirmationPage';
import HeaderFooterLayout, { AssetProps } from '../../js/layout/HeaderFooterLayout';

export default function VerificationRequired() {
    return <EmailConfirmationPage />;
}

export function StaticLayout({ assets }: AssetProps) {
    return <HeaderFooterLayout assets={assets} robots="noindex, nofollow" />;
}
