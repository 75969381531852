import Address, { AddressT } from '../../account/Address';
import ErrorBox from '../../generic/ErrorBox';
import useBooleanState from '../../utils/useBooleanState';
import AddressFinder from './AddressFinder';
import CheckoutButton from './CheckoutButton';
import { DeliveryProps } from './DeliveryMode';

export interface PickupPostOfficeDeliveryMode extends DeliveryProps {
    type: 'PICKUP_POST_OFFICE';
    error?: { message: string };
    infoMessage?: string;
}

export interface Props {
    deliveryMode: PickupPostOfficeDeliveryMode;
    deliveryAddress?: AddressT;
    onSelectAddress(addressId: string): void;
}

export default function PickupPostOfficeDetails({ deliveryMode, deliveryAddress, onSelectAddress }: Props) {
    const [isAddressFinderVisible, setIsAddressFinderVisible] = useBooleanState(!deliveryAddress);

    return (
        <div>
            {deliveryMode.infoMessage && <div className="option-block">{deliveryMode.infoMessage}</div>}
            {deliveryAddress && (
                <div className="option-block">
                    <h3>
                        Mottaker
                        {!isAddressFinderVisible && (
                            <CheckoutButton buttonText="Endre" onButtonClick={setIsAddressFinderVisible.toTrue} ariaLabel="Endre mottaker" />
                        )}
                    </h3>

                    <Address address={deliveryAddress} />
                    <ErrorBox errors={deliveryMode.error?.message} />
                </div>
            )}
            {isAddressFinderVisible && (
                <AddressFinder
                    selectedAddress={deliveryAddress}
                    onSelectAddress={({ id }) => {
                        setIsAddressFinderVisible.toFalse();
                        onSelectAddress(id);
                    }}
                    onCancel={setIsAddressFinderVisible.toFalse}
                />
            )}
        </div>
    );
}
