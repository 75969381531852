import { useContext, useState } from 'react';
import * as api from '../api';
import { site, userAPI } from '../config';
import ErrorBox, { ErrorBoxType } from '../generic/ErrorBox';
import Modal from '../generic/Modal';
import Page from '../generic/Page';
import { UserContext } from '../login/userContext';
import StoreDetails, { StoreSelectionLink } from '../storelocator/StoreDetails';
import StoreLocator from '../storelocator/StoreLocator';
import useBooleanState from '../utils/useBooleanState';

export default function MyStorePage() {
    const [isOpen, setIsOpen] = useBooleanState();
    const [error, setError] = useState<ErrorBoxType | null>(null);
    const [isLoading, setIsLoading] = useBooleanState();

    const { refreshUser, user } = useContext(UserContext);

    const onSelectPointOfService = async (id: string) => {
        setError(null);
        setIsLoading.toTrue();
        try {
            await api.patchJson(userAPI.favouriteStoreURL(), { id });
            refreshUser();
        } catch (error: any) {
            setError(error);
        } finally {
            setIsOpen.toFalse();
            setIsLoading.toFalse();
        }
    };

    return (
        <Page title="Mitt Vinmonopol" backUrl={site.accountURL()} isSpinning={isLoading} sectionsWrapperClassName="my-page">
            {!user?.favouriteStore ? (
                !isLoading && (
                    <section className="form-section white medium top center">
                        <div className="form-section-content">
                            <ErrorBox errors={error} className="margin-bottom" />
                            <p>Velg hvilken butikk du foretrekker å se vareutvalg for, og hente bestilte varer i.</p>
                            <div className="form-buttons center">
                                <button className="btn-primary" onClick={setIsOpen.toTrue}>
                                    Velg mitt Vinmonopol
                                </button>
                            </div>
                        </div>
                    </section>
                )
            ) : (
                <section className="form-section white top">
                    <div className="form-section-info">
                        <p>Velg hvilken butikk du foretrekker å se vareutvalg for, og hente bestilte varer i.</p>
                    </div>

                    <div className="form-section-content">
                        <ErrorBox errors={error} className="margin-bottom" />
                        <h2>{user?.favouriteStore.displayName}</h2>
                        <StoreDetails storeId={user?.favouriteStore.name} showStoreSelectionLink={false} />
                        <div className="store-info__actions">
                            <button className="btn-secondary" onClick={setIsOpen.toTrue}>
                                Endre min butikk
                            </button>
                            <StoreSelectionLink name={user?.favouriteStore.name ?? ''} />
                        </div>
                    </div>
                </section>
            )}
            <Modal onClose={setIsOpen.toFalse} isOpen={isOpen} padding>
                <h2>Velg butikk</h2>
                <StoreLocator onSelectPointOfService={onSelectPointOfService} />
            </Modal>
        </Page>
    );
}
