import Address, { AddressT } from '../../account/Address';
import useBooleanState from '../../utils/useBooleanState';
import AddressFinder from './AddressFinder';
import CheckoutButton from './CheckoutButton';
import { DeliveryProps } from './DeliveryMode';

export interface LetterDeliveryMode extends DeliveryProps {
    type: 'LETTER_MAIL';
}

export interface Props {
    deliveryMode: LetterDeliveryMode;
    deliveryAddress?: AddressT;
    onSelectAddress(id: string): void;
}

export default function LetterMailDetails({ deliveryAddress, onSelectAddress }: Props) {
    const [addressFinderVisible, setAddressFinderVisible] = useBooleanState(!deliveryAddress);
    return (
        <>
            {deliveryAddress && (
                <div className="option-block">
                    <h3>
                        Mottaker
                        {!addressFinderVisible && (
                            <CheckoutButton buttonText="Endre" onButtonClick={setAddressFinderVisible.toTrue} ariaLabel="Endre mottaker" />
                        )}
                    </h3>

                    <Address address={deliveryAddress} />
                </div>
            )}
            {addressFinderVisible && (
                <AddressFinder
                    selectedAddress={deliveryAddress}
                    onSelectAddress={({ id }) => {
                        setAddressFinderVisible(false);
                        onSelectAddress(id);
                    }}
                    onCancel={setAddressFinderVisible.toFalse}
                />
            )}
        </>
    );
}
