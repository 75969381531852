import CheckoutButton from '../../cart/delivery/CheckoutButton';
import Icon from '../../generic/Icon';
import GiftCardForm from '../../giftcard/GiftCardForm';
import { FormattedPrice } from '../../model/types';
import useBooleanState from '../../utils/useBooleanState';

export interface Props {
    cart: {
        restAmount: FormattedPrice;
        payableByGiftCard: boolean;
        giftcardAmount?: FormattedPrice;
    };
    setGiftCardOnCart(giftcardNumber: string, pincode: string): void;
    removeGiftCard(): void;
}

export default function GiftCardModule({ cart: { restAmount, payableByGiftCard, giftcardAmount }, setGiftCardOnCart, removeGiftCard }: Props) {
    const hasGiftCard = !!giftcardAmount;
    const willBePaidInPartWithGiftcard = hasGiftCard && restAmount.value > 0;

    const [isFormVisible, setIsFormVisible] = useBooleanState();

    const onChooseGiftCard = (giftcardNumber: string, pincode: string) => {
        setIsFormVisible.toFalse();
        setGiftCardOnCart(giftcardNumber, pincode);
        gtag('event', 'add_coupon', {
            coupon: 'Gavekort',
        });
    };

    if (!payableByGiftCard && !hasGiftCard) return null;

    return (
        <div className="cart-section">
            <h2>Gavekort</h2>
            {isFormVisible ? (
                <div className="expandable-form expandable-form--active">
                    <h3>
                        Registrer gavekort
                        <CheckoutButton buttonText="Avbryt" onButtonClick={setIsFormVisible.toFalse} />
                    </h3>
                    <GiftCardForm onChooseGiftCard={onChooseGiftCard} buttonText="Bruk gavekort" />
                </div>
            ) : hasGiftCard ? (
                <div className="gift-card--used expandable-form expandable-form--active dispersed-alignment">
                    <h3>
                        {willBePaidInPartWithGiftcard
                            ? 'Bestillingen vil bli delvis betalt med gavekort'
                            : 'Bestillingen vil bli betalt med gavekort'}
                    </h3>
                    <button className="btn--transparent" onClick={removeGiftCard} aria-label="Fjern gavekort">
                        <Icon className="icon-delete" />
                    </button>
                </div>
            ) : (
                <div className="expandable-form" onClick={setIsFormVisible.toTrue} aria-label="Tast [enter] for å registrer gavekort" tabIndex={0}>
                    <h3>Registrer gavekort</h3>
                </div>
            )}
        </div>
    );
}
