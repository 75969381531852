import React, { createElement } from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import skyra from './analytics/skyra';
import Footer from './footer/Footer';
import GlobalContextProvider from './generic/GlobalContextProvider';
import Header from './header/Header';
import SkipLinks from './layout/SkipLinks';
import detectSanityPresentationMode, { lazyLoadVisualEditing } from './sanity/detectSanityPresentationMode';

const components: Record<string, React.ComponentType<any>> = {
    Header,
    Footer,
    SkipLinks,
};

/**
 * This is the method used to load the components defined in the pages folder. It uses import() to
 * dynamically find the page from a string, but webpack is nice enough to include the module in the
 * bundle anyway, so it's not actually doing lazy loading.
 */

function getPage(path: string) {
    // this import uses magic comments, see https://webpack.js.org/api/module-methods/#magic-comments
    return import(
        /* webpackInclude: /\.tsx$/ */
        /* webpackExports: "default" */
        /* webpackMode: "eager" */
        `../pages/${path}`
    );
}

/**
 * This is run on page load. It will look for html elements with data-react-component="Something" and
 * apply that react component to the element. It will use data-react-props="{}" as the props for the react component.
 *
 * It will try to rehydrate the element, assuming that it has been prerendered on the server.
 */

document.querySelectorAll<HTMLElement>('[data-react-component]').forEach(async (elm) => {
    const componentName = elm.dataset['reactComponent'];
    if (!componentName) throw new Error('Missing value in data-react-component');

    // First look in the components object defined above, and if it's not fonud there then
    // try to lazy load the page using getPage(). The default export is the component.
    const component = components[componentName] ?? (await getPage(componentName).then((m) => m.default));
    if (!component) throw new Error(`Could not find component ${componentName}`);

    // Build the props object from data-react-props="{}"
    const props = JSON.parse(elm.dataset['reactProps'] ?? '{}');

    // Wrap the component with the GlobalContextProvider, which provides all the useful contexts
    // that should be available everywhere.
    const element = createElement(GlobalContextProvider, {}, createElement(component, props));

    // Rehydrate the element, unless it is empty
    if (elm.childElementCount === 0) {
        createRoot(elm).render(element);
    } else {
        hydrateRoot(elm, element);
    }
});

skyra();
detectSanityPresentationMode(lazyLoadVisualEditing);
