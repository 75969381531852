import { PropsWithChildren, useCallback, useState } from 'react';
import { Toast, ToastContext } from './toast-context';

let id = 1;

export default function ToastProvider({ children }: PropsWithChildren) {
    const [toasts, setToasts] = useState<Toast[]>([]);

    const addToast = useCallback((message: string) => {
        setToasts((toasts) => [
            ...toasts,
            {
                id: id++,
                message,
            },
        ]);
    }, []);

    const removeToast = useCallback((id: number) => {
        setToasts((toasts) => toasts.filter((t) => t.id !== id));
    }, []);

    return (
        <ToastContext.Provider
            value={{
                toasts,
                addToast,
                removeToast,
            }}
        >
            {children}
        </ToastContext.Provider>
    );
}
