import cn from 'classnames';
import { PaymentCardLogos } from '../api/payment';
import style from './PaymentInfoLogo.module.less';

export interface Props {
    logo: PaymentCardLogos;
}

export default function PaymentInfoLogo({ logo }: Props) {
    return <div className={cn(style.logo, style[logo])} />;
}
