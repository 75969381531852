import * as api from '.';
import { userAPI } from '../config';

interface Response {
    firstName: string;
    lastName: string;
    line1: string;
    postalCode: string;
    town: string;
}

export async function getCustomerInformation(phoneNumber: string) {
    return await api.getAnonymously<Response>(userAPI.customerInformationLookup(), { phoneNumber });
}
