import FreeDeliveryCheckPage from '../js/freeDeliveryChecker';
import HeaderFooterLayout, { AssetProps } from '../js/layout/HeaderFooterLayout';

export default function () {
    return <FreeDeliveryCheckPage />;
}

export function StaticLayout({ assets }: AssetProps) {
    return <HeaderFooterLayout assets={assets} title="Dekker vi dine fraktkostnader?" />;
}
