import cn from 'classnames';
import { KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { useDebounce, useUpdateEffect } from 'usehooks-ts';
import * as api from '../api';
import { autocompleteURL } from '../config/SearchAPIConfig';
import { searchURL } from '../config/SiteConfig';
import Icon from '../generic/Icon';
import eventTargetValue from '../utils/eventTargetValue';
import { useAsyncUpdateEffect } from '../utils/useAsyncEffect';
import useBooleanState from '../utils/useBooleanState';
import style from './SearchBox.module.less';

export default function SearchBox() {
    const [query, setQuery] = useState(getQuery);
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [selectedSuggestion, setSelectedSuggestion] = useState<string>();

    useUpdateEffect(() => {
        setSelectedSuggestion(undefined);
    }, [query]);

    const debouncedQuery = useDebounce(query, 300);

    useAsyncUpdateEffect(
        async (signal) => {
            const suggestions = await autoComplete(debouncedQuery.toLowerCase(), signal);
            if (signal.aborted) return;
            setSuggestions(suggestions);
        },
        [debouncedQuery],
    );

    const onKeyDown = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                const direction = event.key === 'ArrowUp' ? -1 : +1;
                setSelectedSuggestion((selectedSuggestion = '') => suggestions[suggestions.indexOf(selectedSuggestion) + direction]);
                event.preventDefault();
            }
        },
        [suggestions],
    );

    // Only set the transition class after the component has mounted,
    // So that initial style isn't animated
    const [hasLoaded, setHasLoaded] = useBooleanState();
    useEffect(setHasLoaded.toTrue, []);

    return (
        <form action={searchURL()} className={cn(style.search, { [style.transition]: hasLoaded })} autoComplete="off">
            <button type="submit" tabIndex={-1} aria-label="Start søk i nettsiden">
                <Icon className="icon-global-search" />
            </button>
            <input
                type="search"
                name="q"
                value={selectedSuggestion ?? query}
                onChange={eventTargetValue(setQuery)}
                placeholder="Søk"
                onKeyDown={onKeyDown}
                autoComplete="off"
            />
            {/* This is a hacky solution to filling the searchbox before react loads, since that takes a very long time */}
            <script
                dangerouslySetInnerHTML={{
                    __html: `
document.currentScript.previousSibling.value = new URLSearchParams(location.search).get('q')?.split(':')[0] ?? '';
                `,
                }}
            ></script>
            {!!suggestions.length && (
                <ul>
                    {suggestions.map((suggestion) => (
                        <li key={suggestion} className={cn({ [style.active]: suggestion === selectedSuggestion })}>
                            <a href={searchURL(suggestion)} tabIndex={-1}>
                                {suggestion}
                            </a>
                        </li>
                    ))}
                </ul>
            )}
        </form>
    );
}

function getQuery() {
    if (typeof location === 'undefined') return '';
    return new URLSearchParams(location.search).get('q')?.split(':')[0] ?? '';
}

interface Response {
    suggestions: {
        value: string;
    }[];
}

async function autoComplete(term: string, signal: AbortSignal) {
    if (term.length < 3) return [];
    const { suggestions } = await api.getAnonymously<Response>(autocompleteURL(), { term }, { signal });
    return suggestions.map(({ value }) => value);
}
