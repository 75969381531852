export interface Props {
    onButtonClick(): void;
    buttonText: string;
    className?: string;
    ariaLabel?: string;
}

export default function CheckoutButton({ onButtonClick, className, ariaLabel, buttonText }: Props) {
    return (
        <a onClick={onButtonClick} className={className} tabIndex={0} aria-label={ariaLabel}>
            {buttonText}
        </a>
    );
}
