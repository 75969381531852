import { ApiResponseError } from '../api';
import { ErrorType, ValidationErrors } from '../model/types';

export function isValidEmail(email?: string) {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return email && email !== '' && emailRegex.test(email);
}

export function isEmptyString(value?: string) {
    return !value || value?.trim() === '';
}

export interface ValidationError<K extends string = string> extends ErrorType {
    subject: K;
}

export function isValidationErrors(errors: any): errors is ValidationError[] | ApiResponseError<ValidationError[]> {
    if (errors instanceof ApiResponseError) return isValidationErrors(errors.errors);
    return Array.isArray(errors) && errors.every((e) => e?.subject);
}

export function getValidationErrors<K extends string = string>(
    errors: ValidationError<K>[] | ApiResponseError<ValidationError<K>[]>,
): ValidationErrors<K> {
    if (errors instanceof ApiResponseError) {
        return getValidationErrors(errors.errors);
    }

    const validationErrors = {} as ValidationErrors<K>;
    for (const { subject, ...error } of errors) {
        if (subject) {
            if (!validationErrors[subject]) {
                validationErrors[subject] = error;
            }
        }
    }
    return validationErrors;
}
