import { SanityDocument } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import { ComponentType, lazy } from 'react';
import { ImageUrlBuilderContext } from './ImageUrlBuilderContext';
import { SanityProps } from './getSanityPageProps';

const SanityLiveMode = lazy(() => import('../../js/sanity/SanityLiveMode'));

export type SanityPageComponent<T extends SanityDocument> = ComponentType<{
    data: T;
}>;

export default function SanityPage<T extends SanityDocument>(Component: SanityPageComponent<T>) {
    return (props: SanityProps<T>) => (
        <ImageUrlBuilderContext.Provider value={imageUrlBuilder(props.sanityProjectDetails)}>
            {props.draftMode ? <SanityLiveMode {...props} component={Component as any} /> : <Component data={props.initial} />}
        </ImageUrlBuilderContext.Provider>
    );
}
