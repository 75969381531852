import Page from '../generic/Page';
import RegisterForm from '../register/RegisterForm';
import { getRedirectUrl } from '../utils/url';
import LoginForm from './LoginForm';

export default function LoginRegisterPage() {
    const onLoggedIn = () => {
        location.assign(getRedirectUrl());
    };

    return (
        <Page title="Logg inn">
            <LoginForm onLoggedIn={onLoggedIn} />
            <RegisterForm onLoggedIn={onLoggedIn} />
        </Page>
    );
}
