import { useContext, useState } from 'react';
import { CartContext, MiniCartContext } from '../cart/CartContext';
import ErrorBox from '../generic/ErrorBox';
import { TrackingContext } from '../generic/TrackingContext';
import SubmitButton from '../generic/forms/SubmitButton';
import preventDefault from '../utils/preventDefault';
import useBooleanState from '../utils/useBooleanState';
import style from './AddToCartAction.module.less';
import QuantityPicker from './QuantityPicker';

export interface Props {
    code: string;
    buyable: boolean;
    maxQuantity?: number;
}

export default function AddToCartAction({ code, buyable, maxQuantity }: Props) {
    const cartEntry = useContext(MiniCartContext)?.getProduct(code);

    if (!buyable) {
        return <div className={style.notBuyable} />;
    }

    if (cartEntry) {
        return <QuantityPicker {...cartEntry} maxQuantity={maxQuantity} />;
    }

    return <AddToCartButton code={code} />;
}

function AddToCartButton({ code }: { code: string }) {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useBooleanState();

    const cart = useContext(CartContext);
    const { item_id, index, item_list_id, item_list_name } = useContext(TrackingContext);

    const addToCart = async () => {
        setError(null);
        setIsLoading.toTrue();

        try {
            await cart.addToCart(code);
            gtag('event', 'add_to_cart', {
                items: [{ item_id, index, quantity: 1 }],
                item_list_id,
                item_list_name,
            });
        } catch (error: any) {
            setError(error);
        } finally {
            setIsLoading.toFalse();
        }
    };

    return (
        <form onSubmit={preventDefault(addToCart)} className="no-print">
            <BuyButton isLoading={isLoading} />
            <ErrorBox errors={error} />
        </form>
    );
}

export function BuyButton({ isLoading }: { isLoading: boolean }) {
    return (
        <SubmitButton isLoading={isLoading} className={style.addToCart}>
            Kjøp
        </SubmitButton>
    );
}
