import CheckoutPage from '../../js/checkout/CheckoutPage';
import HeaderFooterLayout, { AssetProps } from '../../js/layout/HeaderFooterLayout';

export default function () {
    return <CheckoutPage />;
}

export function StaticLayout({ assets }: AssetProps) {
    return (
        <HeaderFooterLayout
            assets={assets}
            title="Din handlekurv"
            robots="noindex,nofollow"
            className="page-cartPage"
            pageLabel="checkout"
            page_title="Kasse"
        />
    );
}
