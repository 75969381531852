import { useContext, useState } from 'react';
import * as api from '../api';
import { currentUserURL } from '../config/UserAPIConfig';
import ErrorBox from '../generic/ErrorBox';
import SpinnerContainer from '../generic/SpinnerContainer';
import { ToastContext } from '../generic/Toast/toast-context';
import { InputGroup } from '../generic/forms';
import SubmitButton from '../generic/forms/SubmitButton';
import { UserContext } from '../login/userContext';
import { ValidationErrors } from '../model/types';
import eventTargetValue from '../utils/eventTargetValue';
import preventDefault from '../utils/preventDefault';
import useBooleanState from '../utils/useBooleanState';
import { getValidationErrors, isValidationErrors } from '../utils/validation';

export default function ChangeProfileSection() {
    const { user, refreshUser } = useContext(UserContext);
    const toast = useContext(ToastContext);

    const [firstName, setFirstName] = useState(user?.firstName ?? '');
    const [lastName, setLastName] = useState(user?.lastName ?? '');
    const [birthDate, setBirthDate] = useState(user?.birthDate?.formattedApiValue ?? '');
    const [errors, setErrors] = useState<ValidationErrors<'firstName' | 'lastName' | 'birthDate'>>({});
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useBooleanState();

    const updateProfile = async () => {
        setError(null);
        setErrors({});
        setIsLoading.toTrue();
        try {
            await api.patchJson(currentUserURL(), {
                firstName,
                lastName,
                birthDate,
            });

            toast.addToast('Din profil er endret.');
            await refreshUser();
        } catch (errors: any) {
            if (!isValidationErrors(errors)) {
                setError(errors);
            } else {
                setErrors(getValidationErrors(errors));
            }
        } finally {
            setIsLoading.toFalse();
        }
    };

    return (
        <SpinnerContainer isSpinning={isLoading}>
            <section className="form-section white top">
                <div className="form-section-info">
                    <h2>Min informasjon</h2>
                    <p>
                        Kunder over 18 år kan bestille varer med inntil 21 prosent alkohol.
                        <br />
                        Kunder over 20 år kan bestille alle varer i nettbutikken.
                    </p>
                </div>
                <div className="form-section-content">
                    <ErrorBox errors={error} className="margin-bottom" />
                    <form className="formVmp" onSubmit={preventDefault(updateProfile)} id="change-profile-form">
                        <InputGroup
                            label="Fornavn *"
                            id="firstName"
                            name="firstName"
                            autoComplete="given-name"
                            onChange={eventTargetValue(setFirstName)}
                            value={firstName}
                            error={errors.firstName}
                        />
                        <InputGroup
                            label="Etternavn *"
                            id="lastName"
                            name="lastName"
                            autoComplete="family-name"
                            onChange={eventTargetValue(setLastName)}
                            value={lastName}
                            error={errors.lastName}
                        />
                        <InputGroup
                            label="Fødselsdato (DDMMÅÅÅÅ) *"
                            id="birthDate"
                            name="birthDate"
                            autoComplete="off"
                            onChange={eventTargetValue(setBirthDate)}
                            value={birthDate}
                            error={errors.birthDate}
                            inputMode="numeric"
                            maxLength={8}
                            className="small"
                        />
                    </form>
                    <div className="form-buttons">
                        <SubmitButton isLoading={isLoading} form="change-profile-form">
                            Lagre
                        </SubmitButton>
                    </div>
                </div>
            </section>
        </SpinnerContainer>
    );
}
