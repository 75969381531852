import HeaderFooterLayout, { AssetProps } from '../../js/layout/HeaderFooterLayout';
import Authenticated from '../../js/utils/Authenticated';
import WishListPage from '../../js/wishlist/WishListPage';

export default function () {
    return (
        <Authenticated>
            <WishListPage />
        </Authenticated>
    );
}

// This prevents prerender.mjs from prerendering this file
// TODO: Remove this when we are fully in cloudflare
export const preventPrerender = true;

export function StaticLayout({ assets }: AssetProps) {
    return <HeaderFooterLayout assets={assets} className="page-wishlistPage" robots="noindex, nofollow" page_title="Liste - Favoritter" />;
}
