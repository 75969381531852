export default function skyra() {
    const script = document.createElement('script');
    script.src = 'https://survey.staging.skyra.no/skyra-survey.js';
    script.onload = function () {
        window.skyra.inject({
            slug: getSlug(),
        });
    };
    document.body.appendChild(script);
}

function getSlug() {
    if (isDrikkeTilMatArticle()) {
        return 'vinmonopolet/anbefalt-drikke';
    } else {
        return 'vinmonopolet/top-task-survey-1';
    }
}

function isDrikkeTilMatArticle() {
    if (!location.pathname.startsWith('/content/drikke-til-mat/')) return false;

    switch (location.pathname) {
        case '/content/drikke-til-mat/drikke-til-torsk-og-skrei':
        case '/content/drikke-til-mat/drikke-til-julematen':
        case '/content/drikke-til-mat/drikke-til-grillmat':
        case '/content/drikke-til-mat/drikke-til-lam-og-kje':
            return false;
        default:
            return true;
    }
}
