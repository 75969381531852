import { useContext } from 'react';
import { UserData } from '../api/product';
import Icon from '../generic/Icon';
import { UserContext } from '../login/userContext';
import { ProductCardDetails } from '../product/ProductCard';
import useBooleanState from '../utils/useBooleanState';
import ProductListModal from './ProductListModal';

interface Props {
    product: ProductCardDetails;
    inUserDefinedList: boolean | undefined;
    onUserDataChange(userData: UserData): void;
}

export default function ProductListTool({ product, inUserDefinedList = false, onUserDataChange }: Props) {
    const { showLoginModal } = useContext(UserContext);
    const [isOpen, setIsOpen] = useBooleanState();

    const openModal = () => {
        if (!showLoginModal()) {
            setIsOpen.toTrue();
        }
    };

    return (
        <>
            <button className="btn-icon" onClick={openModal} title="Min liste" aria-label="Min liste">
                <Icon className={inUserDefinedList ? 'icon-my-list selected' : 'icon-my-list'} />
            </button>
            {isOpen && <ProductListModal product={product} onClose={setIsOpen.toFalse} onUserDataChange={onUserDataChange} />}
        </>
    );
}
