import { useState } from 'react';
import * as api from '../api';
import { getCustomerInformation } from '../api/customerLookup';
import { userAPI } from '../config';
import ErrorBox from '../generic/ErrorBox';
import OkCancelButtons from '../generic/OkCancelButtons';
import SpinnerContainer from '../generic/SpinnerContainer';
import { InputGroup, ZipGroup } from '../generic/forms';
import { formatPhoneNumber } from '../model/Forms';
import { ValidationErrors } from '../model/types';
import eventTargetValue from '../utils/eventTargetValue';
import useBooleanState from '../utils/useBooleanState';
import { getValidationErrors, isValidationErrors } from '../utils/validation';
import { AddressT } from './Address';

export interface Props {
    address?: AddressT;
    headerText: string;
    buttonText?: string;
    onSavedAddress(address: AddressT): void;
    onCancel(): void;
}

export default function AddressForm({ address, headerText, buttonText = 'Lagre', onSavedAddress, onCancel }: Props) {
    const [firstName, setFirstName] = useState(address?.firstName ?? '');
    const [lastName, setLastName] = useState(address?.lastName ?? '');
    const [line1, setLine1] = useState(address?.line1 ?? '');
    const [postalCode, setPostalCode] = useState(address?.postalCode ?? '');
    const [town, setTown] = useState(address?.town ?? '');
    const [phone, setPhone] = useState(address?.phone ?? '');

    const [error, setError] = useState(null);
    const [errors, setErrors] = useState<ValidationErrors<'firstName' | 'lastName' | 'line1' | 'postalCode' | 'phone'>>({});
    const [isLoading, setIsLoading] = useBooleanState();

    const handleSubmit = async () => {
        setIsLoading.toTrue();
        setError(null);

        try {
            if (address == null) {
                await api
                    .postJson<AddressT>(userAPI.addressesURL(), {
                        firstName,
                        lastName,
                        line1,
                        postalCode,
                        town,
                        phone,
                    })
                    .then(onSavedAddress);
            } else {
                await api
                    .patchJson<AddressT>(userAPI.addressURL(address.id), {
                        firstName,
                        lastName,
                        line1,
                        postalCode,
                        town,
                        phone,
                    })
                    .then(onSavedAddress);
            }
        } catch (errors: any) {
            if (!isValidationErrors(errors)) {
                setError(errors);
            } else {
                setErrors(getValidationErrors(errors));
            }
        } finally {
            setIsLoading.toFalse();
        }
    };

    const customerInformationLookup = async () => {
        if (!phone) return;
        const phoneNumber = formatPhoneNumber(phone);
        setPhone(phoneNumber);
        setIsLoading.toTrue();
        try {
            const { firstName, lastName, line1, postalCode } = await getCustomerInformation(phoneNumber);
            setFirstName((fn) => fn || firstName || '');
            setLastName((ln) => ln || lastName || '');
            setLine1((l1) => l1 || line1 || '');
            setPostalCode((pc) => pc || postalCode || '');
        } catch {
            // do nothing
        } finally {
            setIsLoading.toFalse();
        }
    };

    return (
        <SpinnerContainer isSpinning={isLoading}>
            <h2>{headerText}</h2>
            <ErrorBox errors={error} className="margin-bottom" />
            <form onSubmit={handleSubmit}>
                <InputGroup
                    label="Mobiltelefonnummer *"
                    id="phone"
                    name="phone"
                    onChange={eventTargetValue(setPhone)}
                    value={phone}
                    error={errors.phone}
                    type="tel"
                    autoComplete="tel-local"
                    onBlur={customerInformationLookup}
                />
                <InputGroup
                    label="Fornavn *"
                    id="firstName"
                    name="firstName"
                    className="half-width"
                    autoComplete="given-name"
                    onChange={eventTargetValue(setFirstName)}
                    value={firstName}
                    error={errors.firstName}
                />
                <InputGroup
                    label="Etternavn *"
                    id="lastName"
                    name="lastName"
                    className="half-width right"
                    autoComplete="family-name"
                    onChange={eventTargetValue(setLastName)}
                    value={lastName}
                    error={errors.lastName}
                />
                <InputGroup
                    label="Adresse *"
                    id="line1"
                    name="line1"
                    autoComplete="street-address"
                    onChange={eventTargetValue(setLine1)}
                    value={line1}
                    error={errors.line1}
                />
                <ZipGroup
                    zipCodeName="postalCode"
                    zipCodeLabelText="Postnummer *"
                    townLabelText="Poststed"
                    onChange={eventTargetValue(setPostalCode)}
                    onChangeTown={setTown}
                    zipCodeValue={postalCode}
                    townValue={town}
                    className="form-group zip-code"
                    error={errors.postalCode}
                />
            </form>
            <OkCancelButtons onCancel={onCancel} onOK={handleSubmit} okButtonText={buttonText} />
        </SpinnerContainer>
    );
}
