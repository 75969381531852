import cn from 'classnames';
import { useContext } from 'react';
import * as api from '../api';
import { CardPaymentInfo } from '../api/payment';
import PaymentInfo from '../checkout/PaymentInfo';
import { userAPI } from '../config';
import ConfirmModal from '../generic/ConfirmModal';
import ListActionButtons from '../generic/ListActionButtons';
import Spinner from '../generic/Spinner';
import { ToastContext } from '../generic/Toast/toast-context';
import useBooleanState from '../utils/useBooleanState';

export interface Props {
    paymentInfo: CardPaymentInfo;
    onChange(): void;
    onError(error: any): void;
}

export default function PaymentInfoListItem({ paymentInfo, onChange, onError }: Props) {
    const toast = useContext(ToastContext);
    const [isDeleteModalOpen, setDeleteModalOpen] = useBooleanState();
    const [isLoading, setIsLoading] = useBooleanState();

    const setDefaultPaymentInfo = async () => {
        setIsLoading.toTrue();
        try {
            await api.patchJson(userAPI.paymentDetailDefaultURL(), { id: paymentInfo.id });
            toast.addToast('Standardkort er endret.');
            onChange();
        } catch (e) {
            onError(e);
        } finally {
            setIsLoading.toFalse();
        }
    };

    const deletePaymentInfo = () => {
        setDeleteModalOpen.toFalse();
        setIsLoading.toTrue();
        try {
            api.delete(userAPI.paymentDetailURL(paymentInfo.id));
            toast.addToast('Betalingskortet er slettet.');
            onChange();
        } catch (e) {
            onError(e);
        } finally {
            setIsLoading.toFalse();
        }
    };

    return (
        <li className={cn('content-align--left', 'spinner-container', getPaymentState(paymentInfo))}>
            <Spinner isSpinning={isLoading} className="spinner--internal" />
            <PaymentInfo className="list-content" paymentInfo={paymentInfo} />
            <ListActionButtons
                onClickDelete={setDeleteModalOpen.toTrue}
                editButton={
                    !paymentInfo.expired &&
                    !paymentInfo.defaultPayment && (
                        <a className="default-link" onClick={setDefaultPaymentInfo}>
                            Sett som standardkort
                        </a>
                    )
                }
            />
            <ConfirmModal
                onClose={setDeleteModalOpen.toFalse}
                onConfirm={deletePaymentInfo}
                mainText="Er du sikker på at du vil slette kortet?"
                isOpen={isDeleteModalOpen}
            />
        </li>
    );
}

function getPaymentState(paymentInfo: CardPaymentInfo) {
    if (paymentInfo.defaultPayment) {
        return 'selected';
    } else if (paymentInfo.expired) {
        return 'expired';
    } else {
        return '';
    }
}
