import { useContext, useState } from 'react';
import { UserData, removeEmailReminder } from '../../api/product';
import ConfirmModal from '../../generic/ConfirmModal';
import ErrorBox, { ErrorBoxType } from '../../generic/ErrorBox';
import Icon from '../../generic/Icon';
import { UserContext } from '../../login/userContext';
import preventDefault from '../../utils/preventDefault';
import useBooleanState from '../../utils/useBooleanState';
import { DetailedProduct } from '../productdetails/ProductDetailsPage';
import EmailReminderModal from './EmailReminderModal';

export interface Props {
    product: DetailedProduct;
    statusNotificationRegistered: boolean | undefined;
    onUserDataChange(userData: UserData): void;
}

export default function ProductEmailReminder({ product, statusNotificationRegistered, onUserDataChange }: Props) {
    const [isModalOpen, setIsModalOpen] = useBooleanState();
    const [error, setError] = useState<ErrorBoxType | null>(null);

    const { showLoginModal } = useContext(UserContext);

    const onClick = () => {
        if (!showLoginModal()) {
            setIsModalOpen.toTrue();
        }
    };

    const onDelete = () => {
        setError(null);
        removeEmailReminder(product.code).then(onUserDataChange, setError).finally(setIsModalOpen.toFalse);
    };

    if (!product.statusNotification) return null;

    return (
        <div className="product-email-reminder">
            <ErrorBox errors={error} />
            {statusNotificationRegistered ? (
                <>
                    <div className="info-message">
                        <div className="message-text">Du vil få en e-post hvis varen blir tilgjengelig</div>
                        <button
                            onClick={preventDefault(onClick)}
                            type="button"
                            aria-label={`Fjern varsling for ${product.name}`}
                            className="btn--transparent"
                        >
                            <Icon className="icon-delete black" />
                        </button>
                    </div>
                    <ConfirmModal
                        onClose={setIsModalOpen.toFalse}
                        onConfirm={onDelete}
                        mainText="Ønsker du å slette varsling?"
                        isOpen={isModalOpen}
                    />
                </>
            ) : (
                <>
                    <button className="no-print btn-tertiary" onClick={preventDefault(onClick)}>
                        <Icon className="icon-email" />
                        <span>Send meg en epost hvis varen blir tilgjengelig</span>
                    </button>
                    <EmailReminderModal
                        isOpen={isModalOpen}
                        product={product}
                        onClose={setIsModalOpen.toFalse}
                        onConfirmReminder={(userData) => {
                            setIsModalOpen.toFalse();
                            onUserDataChange(userData);
                        }}
                    />
                </>
            )}
        </div>
    );
}
