import { ErrorType } from '../../model/types';
import Checkbox, { Props as CheckboxProps } from '../CheckBox';

export interface Props extends Omit<CheckboxProps, 'isChecked'> {
    className?: string;
    checked: boolean;
    error?: ErrorType | null;
}

export default function CheckboxGroup({ className, label, name, error, id, checked, onChange }: Props) {
    return (
        <div className={`checkbox-container ${className || ''} ${error ? 'error' : ''}`}>
            <Checkbox id={id} name={name} label={label} isChecked={checked} onChange={onChange} />
            {error && <div className="error">{error.message}</div>}
        </div>
    );
}
