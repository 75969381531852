import { useState } from 'react';
import * as api from '../api';
import { giftCardBalanceUrl } from '../config/MiscAPIConfig';
import ErrorBox, { ErrorBoxType } from '../generic/ErrorBox';
import SubmitButton from '../generic/forms/SubmitButton';
import { FormattedPrice } from '../model/types';
import eventTargetValue from '../utils/eventTargetValue';
import preventDefault from '../utils/preventDefault';
import useBooleanState from '../utils/useBooleanState';

export interface Props {
    buttonText?: string;
    onChooseGiftCard?(giftcardNumber: string, pincode: string): void;
}
export default function GiftCardForm({ buttonText = 'Sjekk et annet gavekort', onChooseGiftCard }: Props) {
    const [giftcardNumber, setGiftcardNumber] = useState('');
    const [pincode, setPincode] = useState('');
    const [balance, setBalance] = useState<FormattedPrice | null>(null);
    const [expiryDate, setExpiryDate] = useState<string | null>(null);
    const [error, setError] = useState<ErrorBoxType | null>(null);
    const [isLoading, setIsLoading] = useBooleanState();

    const fetchBalance = () => {
        if (pincode.length && giftcardNumber.length) {
            setIsLoading.toTrue();
            setError(null);

            api.post(giftCardBalanceUrl(giftcardNumber), { pincode })
                .then((data) => {
                    if (data.balance.value === 0) {
                        setGiftcardNumber(data.giftcardnumber);
                        setBalance(null);
                        setExpiryDate(null);
                        setError('Gavekortet er tomt');
                    } else {
                        setGiftcardNumber(data.giftcardnumber);
                        setBalance(data.balance);
                        setExpiryDate(data.evoDate);
                    }
                }, setError)
                .finally(setIsLoading.toFalse);
        } else {
            setError('Du må fylle ut gavekortnummer og pinkode.');
        }
    };

    const onClick = () => {
        if (onChooseGiftCard != null) {
            onChooseGiftCard(giftcardNumber, pincode);
        } else {
            setGiftcardNumber('');
            setPincode('');
            setExpiryDate(null);
            setBalance(null);
            setError(null);
        }
    };

    if (balance?.value) {
        return (
            <div className="giftcard-form">
                <fieldset>
                    <div className="form-group">
                        <label>Gavekortnummer</label>
                        <div>{giftcardNumber}</div>
                    </div>
                    <div className="form-group">
                        <label>Saldo</label>
                        <div>{balance.formattedValue}</div>
                    </div>
                    <div className="form-group">
                        <label>Utløpsdato</label>
                        <div>{expiryDate}</div>
                    </div>
                    <div className="form-buttons">
                        <button className="btn-primary" onClick={preventDefault(onClick)}>
                            {buttonText}
                        </button>
                    </div>
                </fieldset>
            </div>
        );
    } else {
        return (
            <div className="giftcard-form form-section-content">
                <ErrorBox errors={error} className="margin-bottom"/>
                <form className="formVmp" onSubmit={preventDefault(fetchBalance)} id="giftcard-form">
                    <div className="form-group">
                        <label aria-hidden="true" htmlFor="giftcardNumber">
                            Gavekortnummer *
                        </label>
                        <input
                            className="gift-card__number form-control"
                            aria-required="true"
                            type="text"
                            name="giftcardNumber"
                            id="giftcardNumber"
                            value={giftcardNumber}
                            onChange={eventTargetValue(setGiftcardNumber)}
                            autoFocus
                            placeholder="Gavekortnummer"
                        />
                    </div>
                    <div className="form-group small">
                        <label aria-hidden="true" htmlFor="pincode">
                            Pinkode *
                        </label>
                        <input
                            className="form-control"
                            aria-required="true"
                            type="text"
                            name="pincode"
                            id="pincode"
                            value={pincode}
                            placeholder="Pinkode"
                            onChange={eventTargetValue(setPincode)}
                        />
                    </div>
                </form>
                <p>På fysiske gavekort finner du PIN-koden på baksiden av gavekortet i et skrapefelt til høyre for strekkoden.</p>
                <div className="form-buttons">
                    <SubmitButton isLoading={isLoading} form="giftcard-form">
                        Sjekk saldo
                    </SubmitButton>
                </div>
            </div>
        );
    }
}
