import cn from 'classnames';
import { useState } from 'react';
import * as api from '../js/api';
import { ReactPageFunction } from '../js/cloudflare/types';
import { getArray } from '../js/cms';
import type { CmsPage } from '../js/cms/PageData';
import { cmsAPI } from '../js/config';
import ArticlePage, { Props as ArticlePageProps } from '../js/content/ArticlePage';
import CategoryPage, { Props as CategoryPageProps } from '../js/content/CategoryPage';
import SubCategoryPage, { Props as SubCategoryPageProps } from '../js/content/SubCategoryPage';
import ErrorBox from '../js/generic/ErrorBox';
import HomePage, { Props as HomePageProps } from '../js/home/HomePage';
import HeaderFooterLayout, { StaticLayoutProps } from '../js/layout/HeaderFooterLayout';
import NotFoundPage from '../js/notFoundPage';
import ArticleCategorySearchPage, { Props as ArticleCategorySearchPageProps } from '../js/search/ArticleCategorySearchPage';
import ArticleTagSearchPage, { Props as ArticleTagSearchPageProps } from '../js/search/ArticleTagSearchPage';
import Authenticated from '../js/utils/Authenticated';
import { setCacheControl } from '../js/utils/responseHelpers';
import Slot from '../js/utils/Slot';
import { useOnMount } from '../js/utils/useComponentDidMount';
import { SmartEditScript } from './preview-content';

type PageProps<MasterTemplate extends string, Props = object> = { masterTemplate: MasterTemplate } & Props;

type Page =
    | PageProps<'LandingPage2Template', HomePageProps>
    | PageProps<'ArticlePage1Template', ArticlePageProps>
    | PageProps<'ArticleCategoryPageTemplate', ArticleCategorySearchPageProps>
    | PageProps<'ArticleSubCategoryPageTemplate', SubCategoryPageProps>
    | PageProps<'ArticleTagsPageTemplate', ArticleTagSearchPageProps>
    | PageProps<'ArticleCategoryFrontPageTemplate', CategoryPageProps>
    | PageProps<'ContentPage1Template'>;

type MasterTemplateKey = Page['masterTemplate'];

interface MetaTag {
    property?: string;
    name?: string;
    content: string;
}

type Props =
    | (Page & {
          pageUid: string;
          pageTitle: string;
          metaTags: MetaTag[];
          metaRobots?: string;
          backofficeDomain: string | null;
      })
    | { masterTemplate: '403'; pageUid?: undefined; pageTitle?: undefined; metaTags?: undefined; metaRobots?: undefined };

type Response =
    | { status: 403 }
    | { status: 301; redirection: string }
    | {
          status?: undefined;
          uid: string;
          masterTemplate: string;
          pageTitle: string;
          metaTags?: { elements: MetaTag | MetaTag[] };
          metaRobots?: string;
          tag?: string;
          code?: string;
      };

const CACHE_MINUTES = 5;
export const onRequestGet: ReactPageFunction<'/[[slug]]', Props> = async ({ request, next, renderToResponse, env }) => {
    const url = new URL(request.url);

    url.searchParams.delete('preview');

    const response = await env.BACKEND.fetch(cmsAPI.cmsDetectURL(url.pathname, url.search));

    if (response.status === 404) return next();

    if (response.status !== 200)
        throw new Error(
            `Request to ${cmsAPI.cmsDetectURL(url.pathname, url.search)} with referer ${url.pathname}${url.search} responded with ${response.status}`,
        );

    const info = await response.json<Response>();

    switch (info.status) {
        case 301:
            return Response.redirect(new URL(info.redirection, url), 301);
        case 403: {
            return renderToResponse({ masterTemplate: '403' }).then(setCacheControl(`private, max-age=${5 * 60}`));
        }
        default: {
            const pageUid = info.uid;
            const cmsTicketId = url.searchParams.get('cmsTicketId');
            const contentUrl = `${cmsAPI.pagesURL}?${new URLSearchParams({
                pageUid,
                pageType: 'ContentPage',
                code: pageUid,
                fields: 'DEFAULT',
                ...(cmsTicketId && { cmsTicketId }),
            })}`;

            const initialContent = await env.BACKEND.fetch(contentUrl)
                .then((r) => r.json<CmsPage>())
                .catch(() => undefined);

            return await renderToResponse({
                pageUid,
                masterTemplate: info.masterTemplate as any,
                pageTitle: info.pageTitle,
                metaTags: getArray(info.metaTags?.elements),
                metaRobots: info.metaRobots,
                tag: info.tag,
                categoryCode: info.code,
                initialContent,
                backofficeDomain: cmsTicketId && env.BACKOFFICE_DOMAIN,
            }).then(setCacheControl(cmsTicketId ? 'no-store' : `public, s-maxage=${CACHE_MINUTES * 60}, stale-while-revalidate=30`));
        }
    }
};

export default function CmsPage(props: Props) {
    switch (props.masterTemplate) {
        case 'LandingPage2Template':
            return <HomePage {...props} />;

        case 'ArticlePage1Template':
            return (
                <div className="article-page">
                    <ArticlePage {...props} />
                </div>
            );

        case 'ArticleTagsPageTemplate':
            return (
                <div className="search-page search-page--tag">
                    <ArticleTagSearchPage {...props} />
                </div>
            );

        case 'ArticleCategoryPageTemplate':
            return (
                <div className="search-page search-category-page">
                    <ArticleCategorySearchPage {...props} />
                </div>
            );

        case 'ArticleSubCategoryPageTemplate':
            return (
                <div className="article-page">
                    <SubCategoryPage {...props} />
                </div>
            );

        case 'ArticleCategoryFrontPageTemplate':
            return (
                <div className="category-home-page">
                    <CategoryPage {...props} />
                </div>
            );

        case '403':
            return (
                <Authenticated>
                    <ForbidenCmsPage />
                </Authenticated>
            );

        case 'ContentPage1Template':
        default:
            return <NotFoundPage />;
    }
}

function ForbidenCmsPage() {
    const [data, setData] = useState<Props | null>(null);
    const [error, setError] = useState<string | null>(null);

    useOnMount(() => {
        api.get<Response>(cmsAPI.cmsDetectURL(location.pathname, location.search)).then((json) => {
            if (json.status === 403) {
                setError('Du har ikke tilgang til denne siden');
            } else if (json.status === 301) {
                location.replace(json.redirection);
            } else {
                setData({
                    pageUid: json.uid,
                    masterTemplate: json.masterTemplate,
                    pageTitle: json.pageTitle,
                    metaTags: getArray(json.metaTags?.elements),
                    metaRobots: json.metaRobots,
                    tag: json.tag,
                    categoryCode: json.code,
                    backofficeDomain: null,
                } as Props);
            }
        });
    });

    if (error) return <ErrorBox errors={error} />;

    if (!data) return null;

    return <CmsPage {...data} />;
}

export function StaticLayout({ assets, props }: StaticLayoutProps<Props>) {
    if (props.masterTemplate === '403') {
        return <HeaderFooterLayout assets={assets} />;
    }

    const { pageTitle, metaTags, metaRobots, masterTemplate, ...rest } = props;

    return (
        <HeaderFooterLayout
            assets={assets}
            title={pageTitle}
            robots={metaRobots}
            className={cn(bodyClassNames[masterTemplate], 'initialContent' in rest && rest.initialContent?.properties?.smartedit.classes)}
            page_title={pageTitles[masterTemplate]}
            meta={metaTags}
        >
            <SmartEditScript backofficeDomain={rest.backofficeDomain} />
            <Slot elm="main" className="site__body" id="page" tabIndex={-1} />
        </HeaderFooterLayout>
    );
}

const bodyClassNames: { [P in MasterTemplateKey]?: string | undefined } = {
    LandingPage2Template: 'page-homepage',
    ArticleCategoryPageTemplate: 'page-articleCategory',
};

const pageTitles: { [P in Page['masterTemplate']]?: string | undefined } = {
    LandingPage2Template: 'Startside',
};
