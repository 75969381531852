import { SanityDocument } from '@sanity/client';
import { ReactPageFunction } from '../js/cloudflare/types';
import HeaderFooterLayout, { StaticLayoutProps } from '../js/layout/HeaderFooterLayout';
import Grid from '../js/sanity/Grid';
import SanityPage from '../js/sanity/SanityPage';
import { WineMagazine, WineMagazineProps, wineMagazineGroq } from '../js/sanity/WineMagazine';
import getSanityPageProps, { SanityProps } from '../js/sanity/getSanityPageProps';
import { setCacheControl } from '../js/utils/responseHelpers';

interface ListPage extends SanityDocument {
    slug: { current: string };
    title: string;
    ingress: string;
    list: WineMagazineProps[];
}

const query = /* groq */ `
    *[_type == "listPage" && slug.current == "vinbladet"][0]{
        _id,
        _type,
        slug,
        title,
        ingress,
        'list': *[_type == ^.listType]${wineMagazineGroq} | order(releaseNumber desc)
    }
`;
export const onRequestGet: ReactPageFunction<'/vinbladet', SanityProps<ListPage>> = async ({ renderToResponse, next, ...ctx }) => {
    const response = await getSanityPageProps<ListPage>(ctx, query);

    if (response.notFound) {
        return next();
    }

    return renderToResponse(response) //
        .then(setCacheControl(`public, s-maxage=${5 * 60}, stale-while-revalidate=30`));
};

export default SanityPage<ListPage>(function ({ data }) {
    return (
        <article className="sanity">
            <div className="breadcrumbs"></div>
            <header>
                <h1>{data.title}</h1>
                <p>{data.ingress}</p>
            </header>
            <Grid list={data.list} component={WineMagazine} />
        </article>
    );
});

export function StaticLayout({ assets, props }: StaticLayoutProps<SanityProps<ListPage>>) {
    return <HeaderFooterLayout assets={assets} title={props.draftMode ? props.initial.data.title : props.initial.title} />;
}
