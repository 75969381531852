import { useState } from 'react';
import * as api from '../api';
import { updateWishlistNameURL } from '../config/WishListAPIConfig';
import ErrorBox from '../generic/ErrorBox';
import OkCancelButtons from '../generic/OkCancelButtons';
import Spinner from '../generic/Spinner';
import eventTargetValue from '../utils/eventTargetValue';
import preventDefault from '../utils/preventDefault';
import useBooleanState from '../utils/useBooleanState';

export interface Props {
    list: { code: string; name: string; numberOfEntries: number; userDefined: boolean };
    labelText: string;
    onCancel: () => void;
    onSuccess: () => void;
}

export default function EditListForm({ list, labelText, onCancel, onSuccess }: Props) {
    const [name, setName] = useState(list.name);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useBooleanState();

    const updateWishlistName = () => {
        if (list.name === name) {
            onCancel();
        } else {
            setError(null);
            setIsLoading.toTrue();
            api.patch(updateWishlistNameURL(list.code, name)).then(onSuccess).catch(setError).finally(setIsLoading.toFalse);
        }
    };

    return (
        <li>
            <Spinner isSpinning={isLoading} />
            <form onSubmit={preventDefault(updateWishlistName)}>
                <div className="form-group">
                    <label>{labelText}</label>
                    <input className="form-control" value={name} onChange={eventTargetValue(setName)} autoFocus />
                </div>
                <ErrorBox errors={error} />
                <OkCancelButtons onCancel={onCancel} isLoading={isLoading} okButtonText="Lagre" />
            </form>
        </li>
    );
}
