import { useContext, useState } from 'react';
import { CartResponse } from '../api/cart';
import { site } from '../config';
import EmptyState from '../generic/EmptyState';
import ErrorBox from '../generic/ErrorBox';
import InfoBox from '../generic/InfoBox';
import Page from '../generic/Page';
import { WrapTrackingContext } from '../generic/TrackingContext';
import { UserContext } from '../login/userContext';
import * as Cart from '../model/Cart';
import useBooleanState from '../utils/useBooleanState';
import { useOnMount } from '../utils/useComponentDidMount';
import { CartContext } from './CartContext';
import CartEntries from './CartEntries';
import CartSummary from './CartSummary';
import { DeliveryMode } from './delivery/DeliveryMode';
import DeliveryModes from './delivery/DeliveryModes';
import GreetingCardModule from './greetingcard/GreetingCardModule';

const delayedErrors = [
    // Error messages are supressed until user wants to continue
    'vmp.cart.validation.error.store.deliverymode',
    'vmp.checkout.delivery.validation.error.deliveryDate.notprovided',
    'checkout.multi.deliveryAddress.notprovided',
    'vmp.basket.validation.error.no.stores',
];
function getError(error?: { code: string; message: string }) {
    return error && !delayedErrors.includes(error.code) ? error.message : null;
}

export default function CartPage() {
    const cartContext = useContext(CartContext);
    const { isLoggedIn, showLoginModal } = useContext(UserContext);
    const [cart, setCart] = useState<CartResponse>();
    const [deliveryModes, setDeliveryModes] = useState<DeliveryMode[]>();
    const [error, setError] = useState<any>(null);
    const [isLoading, setIsLoading] = useBooleanState(true);

    const refreshCart = async (before?: () => Promise<void>) => {
        try {
            setIsLoading.toTrue();
            await before?.().catch(setError);
            const cart = await cartContext.getCart();
            if (isLoggedIn) {
                const { deliveryModes } = await cartContext.getDeliveryModes();
                setDeliveryModes(deliveryModes);
            }
            setCart(cart);
            setError(getError(cart.error));
            return cart;
        } catch (error: any) {
            setError(error);
        } finally {
            setIsLoading.toFalse();
        }
    };

    const refreshCartAfter =
        <T extends any[]>(action: (...args: T) => Promise<any>) =>
        (...args: T) =>
            refreshCart(() => action(...args));

    useOnMount(async (user) => {
        gtag('event', 'page_view', {
            page_title: user.isLoggedIn ? 'Levering' : 'Handlekurv',
        });

        const cart = await refreshCart();
        if (!cart) return;

        if (user.isLoggedIn) {
            gtag('event', 'begin_checkout', {
                items: cart.entries.map((e) => ({
                    item_id: e.product.code,
                    quantity: e.quantity,
                    index: e.entryNumber,
                })),
                value: cart.subTotal.value,
                currency: 'NOK',
                shipping_tier: cart.deliveryMode.name?.toLowerCase(),
                payment_type: cart.paymentMode?.toLowerCase(),
            });
        } else {
            gtag('event', 'view_cart', {
                items: cart.entries.map((e) => ({
                    item_id: e.product.code,
                    quantity: e.quantity,
                    index: e.entryNumber,
                })),
                value: cart.subTotal.value,
                currency: 'NOK',
            });
        }
    });

    const checkForErrors = () => {
        if (cart?.error) {
            setError(cart?.error?.message);
        } else {
            location.assign(site.checkoutURL());
        }
    };

    return (
        <Page title="Handlekurv" isSpinning={isLoading} sectionsWrapperClassName="cart">
            {!cart ? (
                <ErrorBox errors={error} />
            ) : !cart.entries?.length ? (
                <EmptyState icon="icon-cart" text="Handlekurven er tom" />
            ) : (
                <>
                    <div className="infobox-centered">
                        <InfoBox className="cart-section" message={cart.broadcastMessage} />
                    </div>
                    <div className="main-side-columns">
                        <div className="main-column">
                            {deliveryModes && (
                                <WrapTrackingContext
                                    set={{
                                        items: cart.entries.map((e) => ({
                                            item_id: e.product.code,
                                            quantity: e.quantity,
                                            index: e.entryNumber,
                                        })),
                                        value: cart.subTotal.value,
                                        currency: 'NOK',
                                    }}
                                >
                                    <DeliveryModes
                                        cart={cart}
                                        deliveryModes={deliveryModes}
                                        onSelectDeliveryMethod={refreshCartAfter(cartContext.setDeliveryMode)}
                                        onSelectPointOfService={refreshCartAfter(cartContext.selectPointOfService)}
                                        onSelectAddress={refreshCartAfter(cartContext.setDeliveryAddress)}
                                    />
                                </WrapTrackingContext>
                            )}

                            {isLoggedIn && (cart.greetingCardAvailable || Cart.hasGreetingCard(cart)) && (
                                <GreetingCardModule
                                    cart={cart}
                                    onUpdateGreetingCard={refreshCart}
                                    removeGreetingCard={refreshCartAfter(cartContext.removeGreetingCard)}
                                />
                            )}

                            <CartEntries
                                cart={cart}
                                onQuantityChanged={refreshCart}
                                deleteCartEntry={refreshCartAfter(cartContext.deleteCartEntry)}
                                deleteCart={refreshCartAfter(cartContext.deleteCartEntries)}
                                showStockStatus={isLoggedIn}
                            />
                        </div>
                        <div className="side-column">
                            <div className="cart-order-summary">
                                <CartSummary
                                    cart={cart}
                                    cartHasError={error || cart.deliveryError || cart.entries.some((e) => e.error)}
                                    error={error}
                                    onGoToCheckout={() => showLoginModal() || checkForErrors()}
                                    onContinueShopping={() => location.assign(site.searchURL())}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Page>
    );
}
