import { PropsWithChildren } from 'react';
import Icon from '../generic/Icon';
import InfoBox from '../generic/InfoBox';

export interface OrderDetailsProps {
    status: Details[];
    summary: Details[];
}

interface Details {
    info?: string;
    readableValue?: string;
    caption: string;
    values: string[];
    link?: { href: string; value: string };
}

export default function OrderDetails({ status, summary }: OrderDetailsProps) {
    return (
        <>
            {status.length != 0 && (
                <div className="order-detail-info">
                    {status.map((status: Details) => (
                        <DetailValue key={status.caption} status={status}>
                            {status.values}
                        </DetailValue>
                    ))}
                </div>
            )}
            <div className="order-detail-info">
                {summary.map((summary: Details) => (
                    <DetailValue key={summary.caption} status={summary}>
                        {summary.link ? <Link link={summary.link} /> : <RenderValues values={summary.values} />}
                    </DetailValue>
                ))}
            </div>
        </>
    );
}
function DetailValue({ status, children }: PropsWithChildren<{ status: Details }>) {
    return (
        <>
            <InfoBox message={status.info} />
            <div className="order-detail-value">
                <span className="label">{status.caption}</span>
                {!!status.readableValue && <span className="sr-only">{status.readableValue}</span>}
                <span aria-hidden={!!status.readableValue} className="value" id={status.caption}>
                    {children}
                </span>
            </div>
        </>
    );
}
function RenderValues({ values }: { values: string[] }) {
    return values.map((value) => <span key={value}>{value}</span>);
}
function Link({ link }: { link: { href: string; value: string } }) {
    return (
        <a target="_blank" className="link-external" href={link.href} rel="noreferrer">
            <span>{link.value}</span> <Icon className="icon-external-link" />
        </a>
    );
}
