import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import FormGroup, { Props as FormGroupProps } from './FormGroup';

export interface Props
    extends FormGroupProps,
        Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'className' | 'id'> {
    inputClassName?: string;
    onInitialValue?(value: string): void;
}

export default function InputGroup({ className, label, error, inputClassName, onInitialValue, ...inputProps }: Props) {
    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!ref.current?.value) return;
        if (inputProps.value !== '') return;

        onInitialValue?.(ref.current.value);
    }, [ref.current]);

    return (
        <FormGroup className={className ?? ''} error={error} id={inputProps.id} label={label}>
            <input
                aria-describedby={'error_feedback_' + inputProps.id}
                aria-invalid={!!error}
                className={classNames('form-control', inputClassName, { error })}
                {...inputProps}
                ref={ref}
            />
        </FormGroup>
    );
}
