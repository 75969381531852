import { CardPaymentInfo } from '../api/payment';
import PaymentInfo from './PaymentInfo';

export interface Props {
    paymentInfo: CardPaymentInfo;
    selectPaymentInfo(id: string): void;
}

export default function PaymentInfoButton({ paymentInfo, selectPaymentInfo }: Props) {
    if (paymentInfo.expired) return null;

    return (
        <button
            className="payment-info-button"
            onClick={() => selectPaymentInfo(paymentInfo.id)}
            aria-label={`Velg lagret kort ${paymentInfo.cardType.name} med kortnummer ${paymentInfo.cardNumber} som er gyldig til ${paymentInfo.expiryMonth}/${paymentInfo.expiryYear}.`}
        >
            <PaymentInfo paymentInfo={paymentInfo} />
        </button>
    );
}
