import { List } from "../wishlist/ListOfWishlistsPage";

const FAVOURITE = 'favourite';
const PREVIOUSLY_BOUGHT = 'previouslyBought';
const RATED_PRODUCTS = 'ratedProducts';

export function getListIcon(list: List) {
    switch (list.code) {
        case FAVOURITE:
            return 'icon-favorite';
        case PREVIOUSLY_BOUGHT:
            return 'icon-cart';
        case RATED_PRODUCTS:
            return 'icon-comment';
        default:
            return 'icon-my-list selected';
    }
}