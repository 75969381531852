import { useContext } from 'react';
import Toast from './Toast';
import { ToastContext } from './toast-context';

export default function ToastContainer() {
    const { toasts } = useContext(ToastContext);

    if (!toasts.length) return null;

    return (
        <div className="toast-container">
            <div className="toast-inner-container">
                {toasts.map((toast) => (
                    <Toast key={toast.id} id={toast.id} message={toast.message} />
                ))}
            </div>
        </div>
    );
}
