import { useContext, useState } from 'react';
import * as api from '../api';
import { emailVerificationURL } from '../config/SiteConfig';
import { updateEmailURL } from '../config/UserAPIConfig';
import ConfirmModal from '../generic/ConfirmModal';
import ErrorBox, { ErrorBoxType } from '../generic/ErrorBox';
import InputGroup from '../generic/forms/InputGroup';
import SubmitButton from '../generic/forms/SubmitButton';
import { UserContext } from '../login/userContext';
import { ValidationErrors } from '../model/types';
import delay from '../utils/delay';
import eventTargetValue from '../utils/eventTargetValue';
import preventDefault from '../utils/preventDefault';
import useBooleanState from '../utils/useBooleanState';
import { getValidationErrors, isValidationErrors } from '../utils/validation';

export default function ChangeEmailSection() {
    const [isEditMode, setIsEditMode] = useBooleanState();
    const { user } = useContext(UserContext);
    return (
        <section className="white form-section">
            <div className="form-section-info">
                <h2>E-postadresse</h2>
                <p>{user?.displayUid}</p>
            </div>
            <div className="form-section-content">
                {isEditMode ? (
                    <ChangeEmailForm onClose={setIsEditMode.toFalse} />
                ) : (
                    <div className="form-buttons-nomargin">
                        <button className="btn-secondary" onClick={setIsEditMode.toTrue} aria-label="Endre E-postadresse">
                            Endre
                        </button>
                    </div>
                )}
            </div>
        </section>
    );
}

function ChangeEmailForm({ onClose }: { onClose: () => void }) {
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useBooleanState();
    const [mail, setMail] = useState('');
    const [repeatMail, setRepeatMail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<ErrorBoxType>();
    const [errors, setErrors] = useState<ValidationErrors<'email' | 'chkEmail' | 'password'>>({});
    const [isLoading, setIsLoading] = useBooleanState();
    const { login } = useContext(UserContext);

    const updateEmail = async () => {
        setIsLoading.toTrue();
        setIsConfirmModalOpen.toFalse();
        setError(undefined);
        setErrors({});
        try {
            await api.patchJson(updateEmailURL(), {
                email: mail,
                chkEmail: repeatMail,
                password: password,
            });
            await delay(1_000);
            await login(mail, password);
            location.assign(emailVerificationURL());
        } catch (error: any) {
            if (isValidationErrors(error)) {
                setErrors(getValidationErrors(error));
            } else {
                setError(error);
            }
        } finally {
            setIsLoading.toFalse();
        }
    };

    return (
        <>
            <ErrorBox errors={error} className="margin-bottom" />
            <form className="formVmp" onSubmit={preventDefault(setIsConfirmModalOpen.toTrue)} id="change-email-form" noValidate>
                <InputGroup
                    label="Ny e-postadresse"
                    id="email"
                    name="email"
                    onChange={eventTargetValue(setMail)}
                    value={mail}
                    type="email"
                    error={errors.email}
                    autoComplete="email"
                    disabled={isLoading}
                />
                <InputGroup
                    label="Gjenta ny e-postadresse"
                    id="email"
                    name="repeatMail"
                    onChange={eventTargetValue(setRepeatMail)}
                    value={repeatMail}
                    type="email"
                    error={errors.email}
                    autoComplete="email"
                    disabled={isLoading}
                />
                <InputGroup
                    label="Passord"
                    id="password"
                    name="password"
                    onChange={eventTargetValue(setPassword)}
                    value={password}
                    error={errors.password}
                    type="password"
                    autoComplete="password"
                    disabled={isLoading}
                />
            </form>
            <div className="form-buttons">
                <button className="btn-secondary" onClick={onClose} type="button">
                    Avbryt
                </button>
                <SubmitButton isLoading={isLoading} type="submit" form="change-email-form">
                    Endre
                </SubmitButton>
            </div>
            <ConfirmModal
                onClose={setIsConfirmModalOpen.toFalse}
                onConfirm={updateEmail}
                mainText={`Vennligst bekreft at du vil endre din e-postadresse til: ${mail}`}
                isOpen={isConfirmModalOpen}
            />
        </>
    );
}
