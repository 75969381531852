import React, { useState } from 'react';
import * as api from '../../api';
import { townLookupURL } from '../../config/MiscAPIConfig';
import { ErrorType } from '../../model/types';
import useAsyncEffect from '../../utils/useAsyncEffect';
import { InputGroup } from './index';

export interface Props {
    className?: string;
    zipCodeName?: string;
    zipCodeLabelText?: string;
    townLabelText?: string;
    zipCodeValue?: string;
    townValue: string;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    onChangeTown(town: string): void;
    checkDeliveryNotFree?: (deliveryNotFree: boolean | null) => void;
    required?: boolean;
    error?: ErrorType;
}

interface TownResponse {
    name: string;
    deliveryNotFree: boolean;
}

export default function ZipGroup({
    zipCodeValue,
    zipCodeLabelText,
    zipCodeName,
    townValue,
    townLabelText,
    className,
    required,
    error: errorProp,
    onChange,
    onChangeTown,
    checkDeliveryNotFree,
}: Props) {
    const [error, setError] = useState<ErrorType>();

    useAsyncEffect(
        async (signal) => {
            // validation on autocomplete
            if (!zipCodeValue) return;

            try {
                if (!/^\d{4}$/.test(zipCodeValue)) {
                    throw { message: 'Du må fylle ut gyldig 4-sifret postnummer.' };
                }

                const { name, deliveryNotFree } = await api.getAnonymously<TownResponse>(townLookupURL(), { zipcode: zipCodeValue }, { signal });

                setError(undefined);
                onChangeTown(name);
                checkDeliveryNotFree?.(deliveryNotFree);
            } catch (error: any) {
                if (error instanceof Error && error.name === 'AbortError') return;
                if (error instanceof api.NotFoundError) error = error.errors[0];
                setError(error);
                onChangeTown('');
                checkDeliveryNotFree?.(null);
            }
        },
        [zipCodeValue],
    );

    return (
        <div className={className}>
            <InputGroup
                className="small-group"
                label={zipCodeLabelText ?? 'Postnummer *'}
                id={zipCodeName ?? 'zipCode'}
                name={zipCodeName ?? 'zipCode'}
                value={zipCodeValue}
                onChange={onChange}
                maxLength={4}
                error={error ?? errorProp}
                aria-required={required ?? true}
                type="text"
                inputMode="numeric"
                autoComplete="postal-code"
            />
            <div className="form-group half-width">
                <label>{townLabelText ?? 'Poststed'}</label>
                <div className={'plain-text'}>{townValue}</div>
            </div>
        </div>
    );
}
