import ConfirmModal from '../generic/ConfirmModal';
import Icon from '../generic/Icon';
import { WrapTrackingContext } from '../generic/TrackingContext';
import useBooleanState from '../utils/useBooleanState';
import CartEntry, { Entry } from './CartEntry';

export interface Props {
    cart: {
        totalUnitCount: number;
        entries: Entry[];
    };
    deleteCart?: () => Promise<unknown>;
    deleteCartEntry?: (entryNumber: number) => Promise<unknown>;
    onQuantityChanged?: () => Promise<unknown>;
    editCart?: () => void;
    showStockStatus?: boolean;
}

export default function CartEntries({ cart, showStockStatus = true, deleteCart, deleteCartEntry, onQuantityChanged, editCart }: Props) {
    return (
        <div className="cart-section cart-page-entries" id="cart-page-entries" tabIndex={-1}>
            <div className="dispersed-alignment">
                <h2>{cart.totalUnitCount} Varer</h2>
                {deleteCart && (
                    <DeleteCartButton
                        onClick={() =>
                            deleteCart().then(() =>
                                gtag('event', 'remove_from_cart', {
                                    items: cart.entries.map((e) => ({
                                        item_id: e.product.code,
                                        quantity: e.quantity,
                                        index: e.entryNumber + 1,
                                    })),
                                }),
                            )
                        }
                    />
                )}
                {editCart && <EditCartButton onClick={editCart} />}
            </div>
            <ul className="product-list product-list-horizontal">
                {cart.entries.map((entry) => (
                    <WrapTrackingContext
                        key={entry.product.code}
                        set={{
                            item_id: entry.product.code,
                            quantity: entry.quantity,
                            index: entry.entryNumber + 1,
                        }}
                    >
                        <CartEntry
                            entry={entry}
                            onQuantityChanged={onQuantityChanged}
                            deleteCartEntry={deleteCartEntry}
                            showStockStatus={showStockStatus}
                        />
                    </WrapTrackingContext>
                ))}
            </ul>
        </div>
    );
}

function EditCartButton({ onClick }: { onClick: () => void }) {
    return (
        <button className="btn-text-and-icon" onClick={onClick}>
            <span>Endre handlekurv</span>
            <Icon className="icon-cart" />
        </button>
    );
}

function DeleteCartButton({ onClick }: { onClick: () => void }) {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useBooleanState();
    return (
        <>
            <button className="btn-text-and-icon" onClick={setIsDeleteModalOpen.toTrue}>
                <span>
                    Fjern alle <span className="hidden-xs">varer</span>
                </span>
                <Icon className="icon-delete" />
            </button>
            <ConfirmModal
                onClose={setIsDeleteModalOpen.toFalse}
                onConfirm={onClick}
                mainText="Vil du slette handlekurven?"
                isOpen={isDeleteModalOpen}
            />
        </>
    );
}
