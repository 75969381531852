import { site } from '../js/config';
import Page from '../js/generic/Page';
import HeaderFooterLayout, { AssetProps } from '../js/layout/HeaderFooterLayout';
import StoreLocator from '../js/storelocator/StoreLocator';

export default function StoreFinderPage() {
    return (
        <Page title="Finn butikker og åpningstider">
            <div className="store-finder-wrapper">
                <StoreLocator
                    showBroadcastMessage
                    onSelectPointOfService={(storeName: string) => {
                        window.location.href = site.storeURL(storeName);
                    }}
                    showArrow
                />
            </div>
        </Page>
    );
}

export function StaticLayout({ assets }: AssetProps) {
    return (
        <HeaderFooterLayout
            assets={assets}
            title="Butikker og åpningstider"
            className="page-storefinderPage"
            page_title="Velg butikk"
            meta={[{ name: 'description', content: 'Finn adresse og åpningstider for et Vinmonopol nær deg.' }]}
        />
    );
}
