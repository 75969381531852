import { useContext, useEffect, useState } from 'react';
import { getProduct } from '../api/product';
import { CartContext, MiniCartContext, MiniCartProduct } from '../cart/CartContext';
import { giftCardBalanceCheckUrl } from '../config/SiteConfig';
import ErrorBox, { ErrorBoxType } from '../generic/ErrorBox';
import Page from '../generic/Page';
import TabButton from '../generic/TabButton';
import { WrapTrackingContext } from '../generic/TrackingContext';
import { Product } from '../model/types';
import { BuyButton } from '../product/AddToCartAction';
import QuantityPicker from '../product/QuantityPicker';
import ProductDetailsImage from '../product/productdetails/ProductDetailsImage';
import eventTargetValue from '../utils/eventTargetValue';
import preventDefault from '../utils/preventDefault';
import useBooleanState from '../utils/useBooleanState';
import style from './GiftCardPage.module.less';

export interface Props {
    productId: 'egavekort' | 'gavekort';
    egavekort?: Product;
    gavekort?: Product;
}

export default function GiftcardPage({ productId, egavekort: initialEgavekort, gavekort: initialGavekort }: Props) {
    const [error, setError] = useState<ErrorBoxType | null>(null);
    const [egavekort, setEgavekort] = useState(initialEgavekort);
    const [gavekort, setGavekort] = useState(initialGavekort);
    const [selectedProduct, setSelectedProduct] = useState(productId);

    const product = selectedProduct === 'egavekort' ? egavekort : gavekort;

    const miniCart = useContext(MiniCartContext);
    const inCart = miniCart?.getProduct(selectedProduct);

    useEffect(() => {
        if (product) {
            if (product.url !== location.pathname) {
                history.replaceState(null, '', product.url);
            }

            gtag('event', 'view_item', {
                items: [{ item_id: product.code }],
            });
        } else {
            switch (selectedProduct) {
                case 'egavekort':
                    getProduct('egavekort').then(setEgavekort).catch(setError);
                    break;
                case 'gavekort':
                    getProduct('gavekort').then(setGavekort).catch(setError);
                    break;
            }
        }
    }, [selectedProduct, product]);

    return (
        <Page title="Gavekort" sectionsWrapperClassName="product" error={error}>
            <p className={style.conditions}>
                Kortet er gyldig i tre år fra kjøpsdatoen. Etter tre år blir det ubrukte beløpet slettet.
                <br />
                Du kan også sjekke <a href={giftCardBalanceCheckUrl()}>saldo på ditt gavekort</a>.
            </p>
            <div className="tab-group--centered" role="tablist" aria-label="Velg digitalt gavekort eller fysisk gavekort">
                <WrapTrackingContext set={{ tab_name: 'gavekort' }}>
                    <TabButton selected={selectedProduct === 'egavekort'} onSelect={() => setSelectedProduct('egavekort')} large>
                        Digitalt gavekort
                    </TabButton>
                    <TabButton selected={selectedProduct === 'gavekort'} onSelect={() => setSelectedProduct('gavekort')} large>
                        Fysisk gavekort
                    </TabButton>
                </WrapTrackingContext>
            </div>
            <div className={style.giftcard}>
                <div className={style.giftcardImage}>{product && <ProductDetailsImage product={product} />}</div>

                <p className={style.conditions}>
                    {selectedProduct === 'gavekort' ? (
                        <>
                            Mistet kort refunderes ikke. Kortet kan ikke innløses i kontanter.
                            <br />
                            Gavekortet blir sendt fra oss i løpet av 1-2 virkedager.
                        </>
                    ) : (
                        <>Gavekortet blir sendt til din e-postadresse.</>
                    )}
                </p>
                {inCart ? <GiftCardInCart {...inCart} maxQuantity={product?.maxQuantity} /> : <BuyGiftCard productCode={selectedProduct} />}
            </div>
        </Page>
    );
}

interface GiftCardInCartProps extends MiniCartProduct {
    maxQuantity?: number;
}

function GiftCardInCart({ price: { readableValue, formattedValue, value }, code, entryNumber, quantity, maxQuantity }: GiftCardInCartProps) {
    return (
        <div className={style.giftcardForm}>
            <div className={style.price} aria-label={readableValue}>
                {formattedValue}
            </div>
            <WrapTrackingContext set={{ value, currency: 'NOK', item_id: code }}>
                <QuantityPicker entryNumber={entryNumber} quantity={quantity} maxQuantity={maxQuantity} />
            </WrapTrackingContext>
        </div>
    );
}

function BuyGiftCard({ productCode }: { productCode: string }) {
    const [error, setError] = useState<ErrorBoxType | null>(null);
    const [amountStr, setAmountStr] = useState('');
    const [isLoading, setIsLoading] = useBooleanState(false);

    const cart = useContext(CartContext);

    const addToCart = async () => {
        setError(null);

        const amount = parseInt(amountStr);

        if (isNaN(amount)) {
            setError('Angi et gyldig beløp mellom 100 kr og 10 000 kr');
            return;
        }

        if (amount < 100) {
            setError('Beløp må være større enn eller lik 100 kr');
            return;
        }

        if (amount > 10000) {
            setError('Beløp må være mindre enn eller lik 10 000 kr');
            return;
        }

        setIsLoading.toTrue();

        try {
            await cart.addToCart(productCode, 1, amount);

            gtag('event', 'add_to_cart', {
                value: amount,
                currency: 'NOK',
                items: [{ quantity: 1, item_id: productCode }],
            });
        } catch (error: any) {
            setError(error);
        } finally {
            setIsLoading.toFalse();
        }
    };

    return (
        <form onSubmit={preventDefault(addToCart)} className={style.giftcardForm}>
            <div className="btn-group">
                {['200', '300', '400', '500', '800', '1000'].map((amount) => (
                    <button type="button" key={amount} className="btn-group-btn" onClick={() => setAmountStr(amount)}>
                        {amount} kr
                    </button>
                ))}
            </div>
            <div className="form-group">
                <label htmlFor="price">Beløp (maks 10 000)</label>
                <input
                    size={7}
                    type="number"
                    id="price"
                    name="price"
                    className="form-control"
                    value={amountStr}
                    min={100}
                    max={10_000}
                    onChange={eventTargetValue(setAmountStr)}
                />
            </div>
            <BuyButton isLoading={isLoading} />
            <ErrorBox errors={error} />
        </form>
    );
}
