import cn from 'classnames';
import Icon from './Icon';
export interface Props {
    icon: string;
    text: string;
    className?: string;
}

export default function EmptyState({ icon, text, className }: Props) {
    return (
        <div className={cn("empty-state", className)}>
            <Icon className={icon} />
            <div className="empty-state-text">{text}</div>
        </div>
    );
}
