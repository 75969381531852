export default function setTitleAndDescription(title: string, description: string) {
    document.title = title;
    const meta = document.createElement('meta');
    meta.name = "description";
    meta.content = description;
    document.getElementsByTagName('head')[0].appendChild(meta);
}

export const scrollToTop = (ref: HTMLElement | null) => {
    const top = ref?.getBoundingClientRect().top ?? 0;
    if (top <= 0) {
        window.scrollTo(0, 0);
    }
};