import { useContext } from 'react';
import { loginURLWithRedirect } from '../config/SiteConfig';
import Modal from '../generic/Modal';
import OkCancelButtons from '../generic/OkCancelButtons';
import { LoginNeededContext } from './userContext';

export default function LoginNeededModal() {
    const context = useContext(LoginNeededContext);

    return (
        <Modal onClose={context.closeLoginModal} isOpen={context.loginModalOpen} padding size="small">
            <h3>Vennligst logg inn</h3>
            <p>Denne funksjonaliteten krever at du er logget inn.</p>
            {context.loginModalOpen && (
                <OkCancelButtons
                    onCancel={context.closeLoginModal}
                    onOK={loginURLWithRedirect()}
                    okButtonText="Gå til innlogging / registrering"
                    centered
                />
            )}
        </Modal>
    );
}
