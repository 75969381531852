import Icon from "../generic/Icon";

export interface Props {
    icon: string;
    title: string;
    url: string;
}

export default function AccountMenuItem({ icon, title, url }: Props) {
    return (
        <li>
            <a className="content button" href={`/my-account/${url}`}>
                <Icon className={icon} />
                <div className="inner_content">
                    <div className="title">{title}</div>
                    <Icon className="icon-chevron-right" />
                </div>
            </a>
        </li>
    );
}
