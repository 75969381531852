import cn from 'classnames';
import { useContext, useState } from 'react';
import { CartContext } from '../cart/CartContext';
import ErrorBox from '../generic/ErrorBox';
import Icon from '../generic/Icon';
import preventDefault from '../utils/preventDefault';
import useBooleanState from '../utils/useBooleanState';

export interface Props {
    listCode: string;
}

export default function AddListToCartAction({ listCode }: Props) {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useBooleanState();

    const cart = useContext(CartContext);

    const onAdd = async () => {
        setError(null);
        setIsLoading.toTrue();
        try {
            await cart.addListToCart(listCode);
        } catch (error: any) {
            setError(error);
        } finally {
            setIsLoading.toFalse();
        }
    };

    return (
        <>
            <ErrorBox errors={error} />
            <form onSubmit={preventDefault(onAdd)}>
                <button className="btn--transparent addtocart">
                    <Icon className="icon-cart"/>
                    <span className={cn({ 'btn-spinner': isLoading })} tabIndex={-1}>
                        Legg i handlekurv
                    </span>
                </button>
            </form>
        </>
    );
}
