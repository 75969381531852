import { useEventListener } from 'usehooks-ts';
import { loginURLWithRedirect } from '../config/SiteConfig';
import Modal from '../generic/Modal';
import OkCancelButtons from '../generic/OkCancelButtons';
import useBooleanState from '../utils/useBooleanState';

export function LoggedOutModal() {
    const [isOpen, setIsOpen] = useBooleanState();

    useEventListener('vmpUserLoggedOut', setIsOpen.toTrue);

    return (
        <Modal isOpen={isOpen} padding size="small">
            <h3>Du har blitt logget ut</h3>
            <p>Du har blitt logget ut, logg inn for å fortsette.</p>
            {isOpen && (
                <OkCancelButtons
                    okButtonText="Gå til innlogging"
                    onOK={loginURLWithRedirect()}
                    cancelButtonText="Fortsett uten å logge inn"
                    onCancel={() => location.reload()}
                    centered
                />
            )}
        </Modal>
    );
}
