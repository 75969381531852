import { useState } from 'react';
import GA4 from '../../js/analytics/ga4';
import { ProcessPaymentResponse, getOrderStatus, processPayment } from '../../js/api/payment';
import { site } from '../../js/config';
import ErrorBox, { ErrorBoxType } from '../../js/generic/ErrorBox';
import Spinner from '../../js/generic/Spinner';
import { AssetProps } from '../../js/layout/HeaderFooterLayout';
import Slot from '../../js/utils/Slot';
import delay from '../../js/utils/delay';
import useBooleanState from '../../js/utils/useBooleanState';
import { useOnMount } from '../../js/utils/useComponentDidMount';
import { trackPurchase } from './beforeTerminal';

export function StaticLayout({ assets }: AssetProps) {
    return (
        <html lang="no">
            <head>
                <meta charSet="utf-8" />
                <title>Betaling - Vinmonopolet</title>
                <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
                <script type="text/javascript" defer src={assets['main.js']}></script>
                <GA4 config={{ page_title: 'Betaling' }} />
                <link rel="stylesheet" type="text/css" media="all" href={assets['main.css']} />
            </head>
            <body>
                <div className="full-page-wrapper site__body">
                    <Slot className="centered" />
                </div>
            </body>
        </html>
    );
}

export default function CheckoutAfterTerminalSpinnerPage() {
    const [error, setError] = useState<ErrorBoxType>();
    const [isLoading, setIsLoading] = useBooleanState(true);

    useOnMount(async () => {
        const urlParams = new URLSearchParams(location.search);
        const transactionId = urlParams.get('transactionId');
        const responseCode = urlParams.get('responseCode');

        if (!transactionId || !responseCode) {
            return location.assign(site.checkoutURL());
        }

        history.replaceState(null, '', site.checkoutURL());

        try {
            let data = await processPayment(transactionId, responseCode).catch<ProcessPaymentResponse>((e) => {
                if (e?.status === 504) {
                    return {
                        completed: false,
                        cancelled: false,
                        transactionId,
                    };
                } else {
                    throw e;
                }
            });

            if (data.cancelled) {
                return location.assign(site.checkoutURL());
            }

            for (let retryCount = 1; !data.completed; retryCount++) {
                const status = await getOrderStatus(transactionId, retryCount);
                if (status.completed) {
                    data = status;
                } else {
                    await delay(status.retrySeconds * 1000);
                }
            }

            try {
                await trackPurchase(data.orderCode);
            } finally {
                location.assign(site.orderConfirmationURL(data.orderCode));
            }
        } catch (error: any) {
            setError(error);
        } finally {
            setIsLoading.toFalse();
        }
    });

    if (error) {
        return (
            <div>
                <h2>Vi beklager...</h2>
                <ErrorBox errors={error} />
                <br />
                <a href={site.checkoutURL()} className="btn-primary">
                    Prøv igjen
                </a>
            </div>
        );
    }

    return (
        <div>
            <Spinner isSpinning={isLoading} />
            <h2>Behandler ordre...</h2>
            <p>Vennligst ikke naviger bort fra, eller oppdater siden, før prosessen er fullført.</p>
            <p>Dette kan ta noen sekunder.</p>
        </div>
    );
}
