import style from './RadioButton.module.less';
import { MouseEventHandler, PropsWithChildren } from 'react';

export interface Props {
    selected: boolean;
    value?: string;
    flex?: boolean;
    id?: string;
    name?: string;
    onSelect: MouseEventHandler;
}

export default function RadioButton({selected = false, value, children, id, name, onSelect}: PropsWithChildren<Props>) {
    return (
        <>
            <input type="radio" value={value} name={name} id={id} className={style.radioBtn} defaultChecked={selected} onClick={onSelect}/>
            {children}
        </>
    );
}
