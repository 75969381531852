import AltSR from './AltSR';

export interface Props {
    price?: {
        formattedValue: string;
        readableValue: string;
        value: number;
    };
    showFree?: boolean;
}

export default function PriceFormat({ price, showFree = false }: Props) {
    if (!price) {
        return <>Ukjent</>;
    }

    if (showFree && price.value === 0) {
        return <>Gratis</>;
    }

    return <AltSR text={price.formattedValue} sr={price.readableValue} />;
}
