import { PropsWithChildren, useContext } from 'react';
import { AddressT } from '../../account/Address';
import Price from '../../generic/PriceFormat';
import RadioButton from '../../generic/RadioButton';
import { TrackingContext } from '../../generic/TrackingContext';
import { FormattedPrice, PointOfService } from '../../model/types';
import EmailDetails, { EmailDeliveryMode } from './EmailDetails';
import HomeDeliveryDetails, { HomeDeliveryMode } from './HomeDeliveryDetails';
import LetterMailDetails, { LetterDeliveryMode } from './LetterMailDetails';
import PickupInStoreDetails, { PickupInStoreDeliveryMode } from './PickupInStoreDetails';
import PickupPostOfficeDetails, { PickupPostOfficeDeliveryMode } from './PickupPostOfficeDetails';

export type DeliveryMode = EmailDeliveryMode | LetterDeliveryMode | HomeDeliveryMode | PickupPostOfficeDeliveryMode | PickupInStoreDeliveryMode;

export interface DeliveryProps {
    code: string;
    selected: boolean;
    name: string;
    deliveryCost?: FormattedPrice;
    formattedLeadTime?: string;
}

export interface Props {
    cart: {
        selectedPointOfService: PointOfService;
        deliveryAddress?: AddressT;
    };
    deliveryMode?: DeliveryMode;
    onSelectDeliveryMethod(type: DeliveryMode['type']): void;
    onSelectPointOfService(name: string): void;
    onSelectAddress(addressId: string): void;
}

export default function DeliveryMode({ cart, deliveryMode, onSelectDeliveryMethod, onSelectPointOfService, onSelectAddress }: Props) {
    if (!deliveryMode) return null;
    if (!deliveryMode.selected) return <DeliveryModeWrapper deliveryMode={deliveryMode} onSelectDeliveryMethod={onSelectDeliveryMethod} />;

    switch (deliveryMode.type) {
        case 'PICK_UP_STORE':
            return (
                <DeliveryModeWrapper deliveryMode={deliveryMode} onSelectDeliveryMethod={onSelectDeliveryMethod}>
                    <PickupInStoreDetails
                        selectedPointOfService={cart.selectedPointOfService}
                        deliveryMode={deliveryMode}
                        deliveryAddress={cart.deliveryAddress}
                        onSelectPointOfService={onSelectPointOfService}
                        onSelectAddress={onSelectAddress}
                    />
                </DeliveryModeWrapper>
            );
        case 'PICKUP_POST_OFFICE':
            return (
                <DeliveryModeWrapper deliveryMode={deliveryMode} onSelectDeliveryMethod={onSelectDeliveryMethod}>
                    <PickupPostOfficeDetails deliveryAddress={cart.deliveryAddress} deliveryMode={deliveryMode} onSelectAddress={onSelectAddress} />
                </DeliveryModeWrapper>
            );
        case 'HOME_DELIVERY':
            return (
                <DeliveryModeWrapper deliveryMode={deliveryMode} onSelectDeliveryMethod={onSelectDeliveryMethod}>
                    <HomeDeliveryDetails deliveryAddress={cart.deliveryAddress} deliveryMode={deliveryMode} onSelectAddress={onSelectAddress} />
                </DeliveryModeWrapper>
            );
        case 'LETTER_MAIL':
            return (
                <DeliveryModeWrapper deliveryMode={deliveryMode} onSelectDeliveryMethod={onSelectDeliveryMethod}>
                    <LetterMailDetails deliveryAddress={cart.deliveryAddress} deliveryMode={deliveryMode} onSelectAddress={onSelectAddress} />
                </DeliveryModeWrapper>
            );
        case 'EMAIL':
            return (
                <DeliveryModeWrapper deliveryMode={deliveryMode} onSelectDeliveryMethod={onSelectDeliveryMethod}>
                    <EmailDetails deliveryMode={deliveryMode} />
                </DeliveryModeWrapper>
            );
    }
}

function DeliveryModeWrapper({
    children,
    deliveryMode,
    onSelectDeliveryMethod,
}: PropsWithChildren<{ deliveryMode: DeliveryMode; onSelectDeliveryMethod(type: DeliveryMode['type']): void }>) {
    const { items, value, currency } = useContext(TrackingContext);
    return (
        <div key={deliveryMode.type} className={deliveryMode.selected ? 'selected' : ''}>
            <label className="option-list-item-content">
                <RadioButton
                    selected={deliveryMode.selected}
                    value={deliveryMode.name}
                    name={'LeveringsMetode'}
                    onSelect={() => {
                        onSelectDeliveryMethod(deliveryMode.type);
                        gtag('event', 'add_shipping_info', {
                            items,
                            value,
                            currency,
                            shipping_tier: deliveryMode.name.toLowerCase(),
                        });
                    }}
                />
                <div className="delivery-mode-name">{deliveryMode.name}</div>
                {deliveryMode.formattedLeadTime && (
                    <div className="lead-time-info">
                        <span>{deliveryMode.formattedLeadTime}</span>
                    </div>
                )}
                <span className="cart-price">{deliveryMode.deliveryCost && <Price price={deliveryMode.deliveryCost} showFree />}</span>
            </label>
            {children}
        </div>
    );
}
