import { createContext } from "react";

export interface Toast {
    id: number,
    message: string
}

export interface ToastContext {
    toasts: Toast[],
    addToast(value: string): void,
    removeToast(id: number): void
}

export const defaultToastContext: ToastContext = {
    toasts: [],
    addToast: () => undefined,
    removeToast: () => undefined
};

export const ToastContext = createContext<ToastContext>(defaultToastContext);
