import { useContext } from 'react';
import Icon from '../generic/Icon';
import { TrackingContext } from '../generic/TrackingContext';
import type { FacetValue } from '../model/Facets';
import preventDefault from '../utils/preventDefault';
import style from './FacetValue.module.less';

interface Props {
    value: FacetValue;
    onFacetValueSelect(query: string): void;
    count?: boolean;
}

export default function FacetValue({ count = false, value, onFacetValueSelect }: Props) {
    const { filter_type } = useContext(TrackingContext);

    return (
        <a
            href={value.href}
            className={style.facetValue}
            onClick={preventDefault(() => {
                gtag('event', value.selected ? 'filter_remove' : 'filter', {
                    filter_type,
                    filter_value: value.name,
                });
                onFacetValueSelect(value.query);
            })}
        >
            {!!value.iconImageClass && <Icon className={`product-icon ${value.iconImageClass}`} />}
            <span className={style.name}>{value.name}</span>
            {count && !!value.count && <span className={style.count}>{value.count}</span>}
            {value.description && <p className={style.description}>{value.description}</p>}
        </a>
    );
}
