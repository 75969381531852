import { useEffect } from 'react';
import Address, { AddressT } from '../../account/Address';
import { PointOfService } from '../../model/types';
import StoreLocator from '../../storelocator/StoreLocator';
import useBooleanState from '../../utils/useBooleanState';
import AddressFinder from './AddressFinder';
import CheckoutButton from './CheckoutButton';
import { DeliveryProps } from './DeliveryMode';

export interface PickupInStoreDeliveryMode extends DeliveryProps {
    type: 'PICK_UP_STORE';
    infoMessage?: string;
}

export interface Props {
    deliveryMode: PickupInStoreDeliveryMode;
    selectedPointOfService: PointOfService;
    deliveryAddress?: AddressT;
    onSelectPointOfService(name: string): void;
    onSelectAddress(addressId: string): void;
}

export default function PickupInStoreDetails({
    deliveryMode,
    selectedPointOfService,
    deliveryAddress,
    onSelectPointOfService,
    onSelectAddress,
}: Props) {
    const [isStoreFinderVisible, setIsStoreFinderVisible] = useBooleanState(!selectedPointOfService);
    const [isAddressFinderVisible, setIsAddressFinderVisible] = useBooleanState();

    useEffect(() => {
        if (isStoreFinderVisible) {
            setIsAddressFinderVisible.toFalse();
        } else {
            setIsAddressFinderVisible(!deliveryAddress);
        }
    }, [isStoreFinderVisible]);

    return (
        <>
            {selectedPointOfService && (
                <div className="option-block">
                    <h3>
                        Butikk
                        {!isStoreFinderVisible && (
                            <CheckoutButton buttonText="Endre" onButtonClick={setIsStoreFinderVisible.toTrue} ariaLabel="Endre butikk" />
                        )}
                    </h3>
                    <Address address={selectedPointOfService.address} displayName={selectedPointOfService.displayName} />
                    {deliveryMode.infoMessage && <div>{deliveryMode.infoMessage}</div>}
                </div>
            )}
            {isStoreFinderVisible && (
                <div className="option-block store-finder">
                    <h3>
                        {selectedPointOfService == null ? 'Velg butikk' : 'Endre butikk'}
                        {selectedPointOfService != null && <CheckoutButton buttonText="Avbryt" onButtonClick={setIsStoreFinderVisible.toFalse} />}
                    </h3>
                    <StoreLocator
                        onSelectPointOfService={(pos: string) => {
                            setIsStoreFinderVisible.toFalse();
                            onSelectPointOfService(pos);
                        }}
                        pageSize={6}
                    />
                </div>
            )}
            {deliveryAddress && (
                <div className="option-block">
                    <h3>
                        Mottaker
                        {!isAddressFinderVisible && (
                            <CheckoutButton buttonText="Endre" onButtonClick={setIsAddressFinderVisible.toTrue} ariaLabel="Endre motakker" />
                        )}
                    </h3>
                    <Address address={deliveryAddress} showAddress={false} showPhone />
                </div>
            )}
            {isAddressFinderVisible && (
                <AddressFinder
                    selectedAddress={deliveryAddress}
                    onSelectAddress={({ id }) => {
                        setIsAddressFinderVisible.toFalse();
                        onSelectAddress(id);
                    }}
                    onCancel={setIsAddressFinderVisible.toFalse}
                />
            )}
        </>
    );
}
