import classNames from 'classnames';
import { Children, PropsWithChildren, useLayoutEffect, useRef } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import stil001 from '../../../images/style/stil001.png';
import stil002 from '../../../images/style/stil002.png';
import stil003 from '../../../images/style/stil003.png';
import stil004 from '../../../images/style/stil004.png';
import stil005 from '../../../images/style/stil005.png';
import stil006 from '../../../images/style/stil006.png';
import stil007 from '../../../images/style/stil007.png';
import stil008 from '../../../images/style/stil008.png';
import stil009 from '../../../images/style/stil009.png';
import stil010 from '../../../images/style/stil010.png';
import Icon from '../../generic/Icon';
import useBooleanState from '../../utils/useBooleanState';
import css from './ProductCharacteristics.module.less';
import ProductStickers, { Props as ProductStickerProps } from './ProductStickers';

export interface ProductCharacteristicsProps extends ProductStickerProps {
    content?: ProductContent;
}

export interface ProductContent {
    ingredients?: Ingredient[];
    isGoodFor?: IsGoodFor[];
    characteristics?: Characteristic[];
    storagePotential?: StoragePotential;
    style?: Style;
    traits?: Trait[];
}

export interface Ingredient {
    code: string;
    formattedValue: string;
    readableValue: string;
}

export interface IsGoodFor {
    code: string;
    name: string;
}

export interface Characteristic {
    name: string;
    readableValue: string;
    value: string;
}

export interface StoragePotential {
    code: string;
    formattedValue: string;
}

export interface Style {
    code: string;
    description: string;
    name: string;
    url: string;
}

export interface Trait {
    formattedValue: string;
    name: string;
    readableValue: string;
}

export default function ProductCharacteristics({ content, stickers, sustainable }: ProductCharacteristicsProps) {
    if (!content) return null;

    const { isGoodFor, characteristics, traits, ingredients, storagePotential, style } = content;

    return (
        <div className={css.wrapper}>
            <div className={css.properties}>
                <Block description="Passer til" horizontal>
                    {isGoodFor?.map(({ name, code }) => <IconProperty key={name} label={name} icon={`isGoodfor ${code}`} />)}
                </Block>
                <Block description="Karakteristikk" horizontal>
                    {characteristics?.map(({ name, value, readableValue }) => (
                        <IconProperty key={name} label={name} icon={`icon-pie-${value}`} aria-label={readableValue} />
                    ))}
                </Block>
                <Block description="Stil, lagring og råstoff">
                    <StyleProperty style={style} />
                    {!!storagePotential && (
                        <IconProperty key="storage" icon={`Lagringsgrad ${storagePotential.code}`} label={storagePotential.formattedValue} />
                    )}
                    {!!ingredients?.length && <IngredientsProperty key="ingredients" icon="icon-raastoff" ingredients={ingredients} />}
                </Block>
                <Block>
                    {traits?.map(({ name, formattedValue, readableValue }) => (
                        <li key={name} className={css.contentItem}>
                            <strong>{name}</strong> <span aria-label={readableValue}>{formattedValue}</span>
                        </li>
                    ))}
                </Block>
            </div>

            <ProductStickers stickers={stickers} sustainable={sustainable} />
        </div>
    );
}

function StyleProperty({ style }: { style?: Style }) {
    const [isOpen, setIsOpen] = useBooleanState();
    const containerRef = useRef<HTMLDivElement>(null);
    useOnClickOutside(containerRef, setIsOpen.toFalse);

    if (!style) return null;

    return (
        <li className={css.propertiesItem} aria-label={style.name}>
            <Icon className="product-icon style" />
            <div className={css.styleLabel} ref={containerRef}>
                {style.name}
                <button className={css.styleButton} onClick={setIsOpen.toggle}>
                    <Icon className="icon-alert-info" />
                </button>
                {isOpen && <StylePopover {...style} />}
            </div>
        </li>
    );
}

const styleImg = {
    stil001,
    stil002,
    stil003,
    stil004,
    stil005,
    stil006,
    stil007,
    stil008,
    stil009,
    stil010,
};

function StylePopover(style: Style) {
    const popoverRef = useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
        const node = popoverRef.current;

        if (!node) return;

        const { left } = node.getBoundingClientRect();

        if (left < 0) {
            node.style.right = `calc(${left}px - var(--product__layout-padding))`;
        }
    }, [popoverRef.current]);

    return (
        <div className={css.stylePopover} ref={popoverRef}>
            <img src={styleImg[style.code as keyof typeof styleImg]} />
            <div>
                <h3>Smak og aroma</h3>
                <h2>{style.name}</h2>
                <p>{style.description}</p>
            </div>
        </div>
    );
}

function IconProperty({ icon, label, 'aria-label': ariaLabel }: { label: string; icon: string; 'aria-label'?: string }) {
    return (
        <li className={css.propertiesItem} aria-label={ariaLabel}>
            <Icon className={`product-icon ${icon}`} />
            <div className={css.label}>{label}</div>
        </li>
    );
}

function IngredientsProperty({ icon, ingredients, 'aria-label': ariaLabel }: { ingredients: Ingredient[]; icon: string; 'aria-label'?: string }) {
    return (
        <li className={css.propertiesItem} aria-label={ariaLabel}>
            <Icon className={`product-icon ${icon}`} />
            <div className={css.label}>
                {ingredients.map(({ code, formattedValue, readableValue }) => (
                    <div key={code} aria-label={readableValue}>
                        {formattedValue}
                    </div>
                ))}
            </div>
        </li>
    );
}

interface CharacteristicsProps {
    description?: string;
    horizontal?: boolean;
}

function Block({ description, horizontal = false, children }: PropsWithChildren<CharacteristicsProps>) {
    if (!Children.toArray(children).length) return null;

    return (
        <div className={classNames(css.block, { [css.horizontal]: horizontal })}>
            {!!description && <h2 className="sr-only">{description}</h2>}
            <ul className={css.propertiesList}>{children}</ul>
        </div>
    );
}
