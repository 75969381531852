import cn from 'classnames';
import style from './OkCancelButtons.module.less';
import SubmitButton from './forms/SubmitButton';

export interface Props {
    okButtonText?: string;
    cancelButtonText?: string;
    centered?: boolean;
    isLoading?: boolean;
    onOK?: string | (() => void);
    onCancel(): void;
}

export default function OkCancelButtons({ onCancel, onOK, okButtonText = 'OK', cancelButtonText = 'Avbryt', centered, isLoading = false }: Props) {
    return (
        <div className={cn(style.container, { [style.centered]: centered })}>
            <button className="btn-cancel" type="button" onClick={onCancel}>
                {cancelButtonText}
            </button>
            {typeof onOK === 'string' ? (
                <a className="btn-primary" href={onOK}>
                    {okButtonText}
                </a>
            ) : onOK ? (
                <button className="btn-primary" type="button" onClick={onOK}>
                    {okButtonText}
                </button>
            ) : (
                <SubmitButton isLoading={isLoading}>{okButtonText}</SubmitButton>
            )}
        </div>
    );
}

export interface OkProps {
    onOK?(): void;
    buttonText?: string;
}

export function OkButton({ onOK, buttonText = 'OK' }: OkProps) {
    return (
        <div className={style.container}>
            <button className="btn-primary" type="button" onClick={onOK}>
                {buttonText}
            </button>
        </div>
    );
}
