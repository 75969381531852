import { PropsWithChildren, createContext, useState } from 'react';
import { User } from '../model/types';
import { login, logout, onUserChange, refreshUser } from '../utils/auth';
import useComponentDidMount from '../utils/useComponentDidMount';

interface UserContextFunctions {
    login(username: string, password: string): Promise<void>;
    refreshUser(): Promise<void>;
    logout(): Promise<void>;
}

interface PendingUser extends UserContextFunctions {
    isLoggedIn: undefined;
    user: undefined;
    showLoginModal(): undefined;
}

export interface LoggedInUser extends UserContextFunctions {
    isLoggedIn: true;
    user: Readonly<User>;
    showLoginModal(): false;
}

export interface AnonymousUser extends UserContextFunctions {
    isLoggedIn: false;
    user: null;
    showLoginModal(): true;
}

export type UserContext = Readonly<LoggedInUser | AnonymousUser | PendingUser>;

export interface LoginNeededContext {
    closeLoginModal(): void;
    loginModalOpen: boolean;
}

export const defaultUserContext: UserContext = {
    isLoggedIn: undefined,
    user: undefined,
    showLoginModal() {
        return undefined;
    },
    refreshUser: () => Promise.resolve(),
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
};

export const UserContext = createContext<UserContext>(defaultUserContext);

export const LoginNeededContext = createContext<LoginNeededContext>({
    closeLoginModal: () => undefined,
    loginModalOpen: false,
});

export function UserContextProvider({ children }: PropsWithChildren) {
    const [loginModalOpen, setLoginModalOpen] = useState(false);

    const userContext: UserContext = getUserContext(setLoginModalOpen);
    const loginNeededContext: LoginNeededContext = {
        loginModalOpen,
        closeLoginModal() {
            setLoginModalOpen(false);
        },
    };

    return (
        <UserContext.Provider value={userContext}>
            <LoginNeededContext.Provider value={loginNeededContext}>{children}</LoginNeededContext.Provider>
        </UserContext.Provider>
    );
}

function getUserContext(setLoginModalOpen: (open: boolean) => void): UserContext {
    const user = getUser();

    switch (user) {
        case null:
            return {
                isLoggedIn: false,
                user,
                login,
                logout,
                refreshUser,
                showLoginModal() {
                    setLoginModalOpen(true);
                    return true;
                },
            };
        case undefined:
            return {
                isLoggedIn: undefined,
                user,
                login,
                logout,
                refreshUser,
                showLoginModal() {
                    return undefined;
                },
            };
        default:
            return {
                isLoggedIn: true,
                user,
                login,
                logout,
                refreshUser,
                showLoginModal() {
                    return false;
                },
            };
    }
}

function getUser() {
    const [user, setUser] = useState<User | null | undefined>(undefined);

    useComponentDidMount(() => onUserChange(setUser));

    return user;
}
