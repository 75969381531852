import { JSX } from 'react';
import Icon from './Icon';
export interface Props {
    onClickDelete?: () => void;
    onClickEdit?: () => void;
    editButton?: JSX.Element | false;
}

export default function ListActionButtons({ editButton, onClickEdit, onClickDelete }: Props) {
    return (
        <div className="list-action-buttons">
            {editButton ??
                (onClickEdit && (
                    <button className="list-item-button tooltip" aria-label="Rediger" onClick={onClickEdit}>
                        <Icon className="icon-edit" />
                        <span className="tooltiptext">Rediger</span>
                    </button>
                ))}
            {onClickDelete && (
                <button className="list-item-button tooltip" aria-label="Slett" onClick={onClickDelete}>
                    <Icon className="icon-delete" />
                    <span className="tooltiptext">Slett</span>
                </button>
            )}
        </div>
    );
}
