import { useState } from 'react';
import * as api from '../api';
import { passwordResetRequestURL } from '../config/MiscAPIConfig';
import Modal from '../generic/Modal';
import OkCancelButtons, { OkButton } from '../generic/OkCancelButtons';
import { InputGroup } from '../generic/forms';
import { ErrorType } from '../model/types';
import eventTargetValue from '../utils/eventTargetValue';
import preventDefault from '../utils/preventDefault';
import useBooleanState from '../utils/useBooleanState';
import usePropState from '../utils/usePropState';
import { isValidEmail } from '../utils/validation';

export interface Props {
    username: string;
    onClose: () => void;
}

export default function ForgottenPasswordModal({ username, onClose }: Props) {
    return (
        <Modal onClose={onClose} isOpen padding size="small">
            <ForgottenPasswordForm username={username} onClose={onClose} />
        </Modal>
    );
}

function ForgottenPasswordForm({ username, onClose }: Props) {
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useBooleanState();
    const [error, setError] = useState<ErrorType | undefined>(undefined);
    const [userName, setUserName] = usePropState(username);

    const onSubmit = async () => {
        if (!isValidEmail(userName)) {
            setError({ message: 'Du må fylle ut en gyldig e-postadresse.' });
        } else {
            setIsLoading.toTrue();
            setError(undefined);
            try {
                await api.post(passwordResetRequestURL(), { userId: userName });
                gtag('event', 'forgot_password');
                setMessage('Hvis e-postadressen er registrert hos oss, vil du motta en e-post for å bytte passord');
            } catch (error: any) {
                setError(error);
            } finally {
                setIsLoading.toFalse();
            }
        }
    };

    return (
        <>
            <h3>Glemt passord</h3>
            {message ? (
                <>
                    <p>{message}</p>
                    <OkButton onOK={onClose} />
                </>
            ) : (
                <>
                    <p>Vennligst oppgi e-postadressen til din konto. En link for å endre ditt passord sendes til deg på e-post.</p>
                    <form onSubmit={preventDefault(onSubmit)} noValidate>
                        <InputGroup
                            label="E-postadresse *"
                            id="username"
                            name="username"
                            error={error}
                            onChange={eventTargetValue(setUserName)}
                            value={userName}
                            type="email"
                            autoComplete="email"
                        />
                        <OkCancelButtons onCancel={onClose} isLoading={isLoading} centered />
                    </form>
                </>
            )}
        </>
    );
}
