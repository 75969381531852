import { StudioPathLike } from '@sanity/react-loader';
import { CreateDataAttribute, CreateDataAttributeProps, createDataAttribute } from '@sanity/visual-editing';
import { PropsWithChildren, createContext, useContext } from 'react';

type DataAttributeProps = CreateDataAttributeProps & {
    id: string;
    type: string;
};

export const DataAttributeContext = createContext<undefined | CreateDataAttribute<DataAttributeProps>>(undefined);

export function ScopeDataAttribute({ path, children }: PropsWithChildren<{ path: StudioPathLike }>) {
    const dataAttribute = useContext(DataAttributeContext);

    if (!dataAttribute) return children;

    return <DataAttributeContext.Provider value={dataAttribute.scope(path)}>{children}</DataAttributeContext.Provider>;
}

export function SetDataAttribute({ children, ...props }: PropsWithChildren<DataAttributeProps>) {
    const dataAttribute = useContext(DataAttributeContext);

    if (!dataAttribute) return children;

    return <DataAttributeContext.Provider value={createDataAttribute(props)}>{children}</DataAttributeContext.Provider>;
}
