import cn from 'classnames';
import Icon from '../generic/Icon';
import * as Product from '../model/Product';
import StoreStockLocatorModal from '../storestocklocator/StoreStockLocatorModal';
import useBooleanState from '../utils/useBooleanState';
import { ProductCardDetails } from './ProductCard';

export interface Props {
    product: ProductCardDetails;
    availability: Availability | undefined;
}

export interface Availability {
    deliveryAvailability: ProductAvailabilityInfo;
    storesAvailability: ProductAvailabilityInfo;
}

export interface ProductAvailabilityInfo {
    availableForPurchase: boolean;
    infos: AvailabilityInfo[];
    openStockLocator: boolean;
    infoAvailabilityAllStores?: string;
}

export interface AvailabilityInfo {
    availability: string;
    location?: string;
    additionalInfo?: string;
    readableValue: string;
}

export default function ProductAvailability({ product, availability }: Props) {
    const [stockLocatorOpen, setStockLocatorOpen] = useBooleanState();

    if (!availability || Product.isGiftCard(product)) {
        return null;
    }

    const { deliveryAvailability, storesAvailability } = availability;

    return (
        <>
            <div className="product-stock-status no-print">
                <Icon className={cn('icon-stock', 'icon-store', { unavailable: !storesAvailability.availableForPurchase })} />
                <div className="product-stock-status-line-text">
                    {storesAvailability.infos.map((info) => (
                        <div key={info.location ?? '-'} aria-label={info.readableValue}>
                            {info.location && <strong>{info.location}: </strong>}
                            {info.availability}
                            {info.additionalInfo}
                        </div>
                    ))}
                    {storesAvailability.openStockLocator ? (
                        <button className="link" onClick={setStockLocatorOpen.toTrue}>
                            {storesAvailability.infoAvailabilityAllStores}
                        </button>
                    ) : (
                        <em>{storesAvailability.infoAvailabilityAllStores}</em>
                    )}
                </div>
                <Icon className={cn('icon-stock', 'icon-delivery', { unavailable: !deliveryAvailability.availableForPurchase })} />
                <div className="product-stock-status-line-text">
                    {deliveryAvailability.infos.map((info) => (
                        <div key={info.location ?? '-'} aria-label={info.readableValue}>
                            {info.location && <strong>{info.location}: </strong>}
                            {info.availability}
                            {info.additionalInfo}
                        </div>
                    ))}
                </div>
            </div>
            {stockLocatorOpen && <StoreStockLocatorModal product={product} isOpen={stockLocatorOpen} onClose={setStockLocatorOpen.toFalse} />}
        </>
    );
}
