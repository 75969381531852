import { DeliveryProps } from './DeliveryMode';

export interface EmailDeliveryMode extends DeliveryProps {
    type: 'EMAIL';
    infoMessage?: string;
}

export interface Props {
    deliveryMode: EmailDeliveryMode;
}

export default function EmailDetails({ deliveryMode }: Props) {
    if (!deliveryMode.infoMessage) return null;

    return <div className="option-block">{deliveryMode.infoMessage}</div>;
}
