import ConfirmModal from '../generic/ConfirmModal';
import Icon from '../generic/Icon';
import ListActionButtons from '../generic/ListActionButtons';
import * as ListFunctions from '../model/List';
import useBooleanState from '../utils/useBooleanState';
import EditListForm from './EditListForm';
import { List } from './ListOfWishlistsPage';

interface Props {
    list: List;
    onSave: () => void;
    onDelete: (code: string) => void;
}

export default function ListOfListsItem({ list, onSave, onDelete }: Props) {
    const [editFormVisible, setEditFormVisible] = useBooleanState();
    const [deleteModalOpen, setDeleteModalOpen] = useBooleanState();

    const onSaveEdit = () => {
        setEditFormVisible.toFalse();
        onSave();
    };

    const deleteList = () => {
        setDeleteModalOpen.toFalse();
        onDelete(list.code);
    };

    return editFormVisible ? (
        <EditListForm list={list} labelText="Nytt navn på liste" onCancel={setEditFormVisible.toFalse} onSuccess={onSaveEdit} />
    ) : (
        <li>
            <div className="list-content">
                <a href={`/wishlist/${list.code}`} className="content">
                    <Icon className={ListFunctions.getListIcon(list)} />
                    <div className="inner_content">
                        <div className="title">{list.name}</div>
                        <div className="detail">{list.numberOfEntries} produkter</div>
                    </div>
                </a>
            </div>
            {list.userDefined && <ListActionButtons onClickEdit={setEditFormVisible.toTrue} onClickDelete={setDeleteModalOpen.toTrue} />}
            <ConfirmModal
                onClose={setDeleteModalOpen.toFalse}
                onConfirm={deleteList}
                mainText="Er du sikker på at du vil slette listen?"
                isOpen={deleteModalOpen}
            />
        </li>
    );
}
