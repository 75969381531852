import { useContext, useState } from 'react';
import { CartResponse } from '../api/cart';
import { removeGiftCard, setGiftCard } from '../api/giftcard';
import { setPaymentInfo } from '../api/payment';
import { CartContext } from '../cart/CartContext';
import CartEntries from '../cart/CartEntries';
import CheckoutOptionItem from '../cartAndCheckout/options/CheckoutOptionItem';
import { site } from '../config/index';
import ErrorBox, { ErrorBoxType } from '../generic/ErrorBox';
import InfoBox from '../generic/InfoBox';
import OptionList from '../generic/OptionList';
import Page from '../generic/Page';
import Authenticated from '../utils/Authenticated';
import useBooleanState from '../utils/useBooleanState';
import { useOnMount } from '../utils/useComponentDidMount';
import CheckoutSummary from './CheckoutSummary';
import PaymentCardDetails from './PaymentCardDetails';
import GiftCardModule from './giftcard/GiftCardModule';

export default function CheckoutPage() {
    const [isLoading, setIsLoading] = useBooleanState(true);
    const [showCards, setShowCards] = useBooleanState();
    const [cart, setCart] = useState<CartResponse | null>(null);
    const [error, setError] = useState<ErrorBoxType | null>(null);

    const cartContext = useContext(CartContext);

    const refreshCart = async (before?: () => Promise<void>) => {
        try {
            setIsLoading.toTrue();
            await before?.().catch(setError);
            const data = await cartContext.getCart();
            if (data.entries.length === 0) {
                location.assign(site.cartURL());
                return;
            }
            setCart(data);

            if (data.error) {
                setError(data.error.message);
            } else if (data.paymentError) {
                setError(data.paymentError.message);
            } else {
                setError(null);
            }

            return data;
        } catch (error: any) {
            setError(error);
        } finally {
            setIsLoading.toFalse();
        }
    };

    const refreshCartAfter =
        <T extends any[]>(action: (...args: T) => Promise<any>) =>
        (...args: T) =>
            refreshCart(() => action(...args));

    const selectPaymentInfo = async (id: string, saveCard?: boolean) => {
        setShowCards.toFalse();
        const cart = await refreshCart(() => setPaymentInfo(id, saveCard));

        if (!cart) return;

        gtag('event', 'checkout_payment', {
            items: cart.entries.map((e) => ({
                item_id: e.product.code,
                quantity: e.quantity,
                index: e.entryNumber,
            })),
            value: cart.subTotal.value,
            currency: 'NOK',
            payment_type: cart.paymentMode,
        });
    };

    const onContinue = () => {
        if (cart?.error) {
            setError(cart.error.message);
        } else {
            setIsLoading.toTrue();
            location.assign(site.beforeTerminalSpinnerPageURL());
            gtag('event', 'go_to_payment');
        }
    };

    useOnMount(async () => {
        const cart = await refreshCart();

        if (!cart) return;

        gtag('event', 'checkout_payment', {
            items: cart.entries.map((e) => ({
                item_id: e.product.code,
                quantity: e.quantity,
                index: e.entryNumber,
            })),
            value: cart.subTotal.value,
            currency: 'NOK',
        });
    });

    const isCardOptionSelected = cart?.paymentMode == null || cart?.paymentMode === 'PaymentCard' || cart?.paymentMode === 'NewCard' || showCards;

    return (
        <Authenticated>
            <Page title="Betaling" isSpinning={isLoading} sectionsWrapperClassName="cart">
                <div className="infobox-centered">
                    <InfoBox className="cart-section" message={cart?.broadcastMessage} />
                </div>
                {!cart ? (
                    <ErrorBox errors={error} />
                ) : (
                    <div className="checkout-page main-side-columns">
                        <div className="main-column">
                            <div className="cart-section" id="checkout-page-payment" tabIndex={-1}>
                                <OptionList ariaLabel="Velg betalingsmåte" legend="Betalingsinformasjon">
                                    <CheckoutOptionItem selected={isCardOptionSelected} onSelect={setShowCards.toTrue} title="Kort" logo="new-card">
                                        <PaymentCardDetails
                                            paymentInfo={cart.paymentInfo}
                                            paymentSaveCard={cart.paymentSaveCard}
                                            paymentMode={cart.paymentMode}
                                            selectPaymentInfo={selectPaymentInfo}
                                            selectNewPaymentInfo={(save) => selectPaymentInfo('', save)}
                                        />
                                    </CheckoutOptionItem>
                                    <CheckoutOptionItem
                                        selected={cart.paymentMode === 'Vipps' && !showCards}
                                        onSelect={() => selectPaymentInfo('Vipps')}
                                        title="Vipps"
                                        logo="vipps"
                                    />
                                </OptionList>
                            </div>
                            <GiftCardModule
                                cart={cart}
                                setGiftCardOnCart={refreshCartAfter(setGiftCard)}
                                removeGiftCard={refreshCartAfter(removeGiftCard)}
                            />
                            <ErrorBox errors={error} />
                            <CartEntries cart={cart} editCart={() => location.assign(site.cartURL())} />
                        </div>
                        <div className="side-column">
                            <div className="checkout-order-summary">
                                <CheckoutSummary
                                    cart={cart}
                                    canContinue={!showCards && !isLoading && !cart.error && !cart.entries.some((entry) => !!entry.error)}
                                    onConfirmAndPay={onContinue}
                                    onContinueShopping={() => location.assign(site.searchURL())}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </Page>
        </Authenticated>
    );
}
