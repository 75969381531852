import { ChangeEvent, Component } from 'react';
import * as api from "../api";
import { consentTemplateURL } from "../config/MiscAPIConfig";
import ErrorBox, { ErrorBoxType } from "../generic/ErrorBox";
import SpinnerContainer from "../generic/SpinnerContainer";
import { CheckboxGroup } from "../generic/forms";
import { ConsentTemplate, ErrorType } from "../model/types";

export interface Props {
    accepted: boolean,
    error?: ErrorType | null,
    onChange: (accepted: boolean) => void
}

interface State {
    error?: string | ErrorBoxType | null;
    isLoading: boolean;
    consentTemplate?: ConsentTemplate;
}

export default class ConsentSection extends Component<Props, State> {

    state: State = {
        error: null,
        isLoading: true
    };

    componentDidMount() {
        this.fetchConsent();
    }

    onCheckBoxChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.props.onChange(event.target.checked as boolean);
    }

    fetchConsent = () => {
        api.getAnonymously(consentTemplateURL())
            .then((data: ConsentTemplate) => {
                this.setState({
                    consentTemplate: data,
                    isLoading: false
                });
            },
            (error) => {
                this.setState({
                    error: error,
                    isLoading: false
                });
            });
    }

    render() {
        return (
            <SpinnerContainer isSpinning={this.state.isLoading}>
                <section className="white form-section form-section-checkbox">
                    <div className="form-section-info">
                        <h3>Behandling av personopplysninger</h3>
                        <p>
                            Vinmonopolet vil behandle opplysninger om deg i samsvar med gjeldende regler for personvern. Med personopplysninger
                            mener vi navn, adresse, fødselsdato, e-postadresse og telefonnummer. Vi trenger ditt samtykke til at vi kan lagre og
                            bruke disse opplysningene for å kontakte deg, levere varer og vite hvilke varer du har lov til å kjøpe.
                        </p>
                        <p>
                            Les{' '}
                            <a target="_blank" href="https://www.vinmonopolet.no/personvern" rel="noreferrer">
                                Vinmonopolets personvernerklæring
                            </a>{' '}
                            før du samtykker
                        </p>
                    </div>
                    {this.state.consentTemplate &&
                    <fieldset>
                        <CheckboxGroup
                            label={this.state.consentTemplate.description}
                            id="consentGiven"
                            name="consentGiven"
                            onChange={this.onCheckBoxChange}
                            checked={this.props.accepted}
                            error={this.props.error}
                        />
                    </fieldset>
                    }
                    {this.state.error &&
                    <ErrorBox errors={this.state.error}/>
                    }
                </section>
            </SpinnerContainer>
        );
    }
}