import { useState } from 'react';
import * as api from '../../js/api';
import * as faqApi from '../../js/config/FaqAPIConfig';
import { faqCategoryURL } from '../../js/config/SiteConfig';
import Page from '../../js/generic/Page';
import HeaderFooterLayout, { AssetProps } from '../../js/layout/HeaderFooterLayout';
import useBooleanState from '../../js/utils/useBooleanState';
import { useOnMount } from '../../js/utils/useComponentDidMount';
import { Article, FaqArticle } from './categories/[id]';
import style from './faq-page.module.less';

interface Response {
    categories: { id: string; label: string }[];
    topArticles: (Article & {
        id: string;
    })[];
}

export default function FaqPage() {
    const [data, setData] = useState<Response>();
    const [error, setError] = useState<unknown>(null);
    const [isLoading, setIsLoading] = useBooleanState(true);

    useOnMount(() => {
        api.getAnonymously<Response>(faqApi.getDatabaseURL()).then(setData, setError).finally(setIsLoading.toFalse);
    });

    return (
        <Page title="Hva kan vi hjelpe deg med?" isSpinning={isLoading} error={error}>
            {data?.categories && (
                <div className={style['faq-category-boxes']}>
                    {data.categories.map(({ id, label }) => (
                        <div key={id} className={style['faq-category-box']}>
                            <a className={style['faq-category-link'] + ' button'} href={faqCategoryURL(id)}>
                                {label}
                            </a>
                        </div>
                    ))}
                </div>
            )}
            {data?.topArticles && (
                <ul className="pagelist">
                    {data.topArticles.map(({ id, question, urlName, answer }) => (
                        <FaqArticle key={id} question={question} urlName={urlName} answer={answer} />
                    ))}
                </ul>
            )}
        </Page>
    );
}

export function StaticLayout({ assets }: AssetProps) {
    return <HeaderFooterLayout assets={assets} title="Ofte stilte spørsmål (FAQ)" />;
}
