import HeaderFooterLayout, { AssetProps } from '../../../js/layout/HeaderFooterLayout';
import PasswordResetPage from '../../../js/password';

export default function () {
    return <PasswordResetPage />;
}

export function StaticLayout({ assets }: AssetProps) {
    return <HeaderFooterLayout assets={assets} robots="noindex,nofollow" />;
}
