import { useState } from 'react';
import * as api from '../../js/api';
import { Entry } from '../../js/cart/CartEntry';
import { site } from '../../js/config';
import { orderURL, ordersURL } from '../../js/config/CartAPIConfig';
import ErrorBox, { ErrorBoxType } from '../../js/generic/ErrorBox';
import Spinner from '../../js/generic/Spinner';
import delay from '../../js/utils/delay';
import useBooleanState from '../../js/utils/useBooleanState';
import { useOnMount } from '../../js/utils/useComponentDidMount';

export { StaticLayout } from './external';

export default function CheckoutBeforeTerminalSpinnerPage() {
    const [error, setError] = useState<ErrorBoxType>();
    const [isLoading, setIsLoading] = useBooleanState(true);
    const [redirected, setRedirected] = useBooleanState();

    useOnMount(async () => {
        try {
            history.replaceState(null, '', site.checkoutURL());

            const data = await api.post(ordersURL(), {
                app: false,
            });

            if (data.completed) {
                try {
                    await trackPurchase(data.orderCode);
                } finally {
                    location.assign(site.orderConfirmationURL(data.orderCode));
                }
            } else {
                console.log('Order not complete - redirecting...');
                location.assign(data.terminalUrl);

                await delay(5000);
                setRedirected.toTrue();
            }
        } catch (error: any) {
            setError(error);
        } finally {
            setIsLoading.toFalse();
        }
    });

    if (error) {
        return (
            <div>
                <h2>Vi beklager...</h2>
                <ErrorBox errors={error} />
                <br />
                <a href={site.checkoutURL()} className="btn-primary">
                    Prøv igjen
                </a>
            </div>
        );
    }
    if (redirected) {
        return (
            <div>
                <h2>Behandles i app</h2>
                <p>
                    Hvis du ser dette, har du nok betalt med Vipps i app, og enten ikke ferdigstilt betaling der, eller du har brukt en ikke-standard
                    nettleser på din mobiltelefon...
                </p>
                <a href={site.ordersURL()} className="btn-primary">
                    Se dine bestillinger
                </a>
            </div>
        );
    }
    return (
        <div>
            <Spinner isSpinning={isLoading} />
            <h2>Videresender til betaling...</h2>
            <p>Vennligst ikke naviger bort fra, eller oppdater siden, før prosessen er fullført.</p>
            <p>Dette kan ta noen sekunder.</p>
        </div>
    );
}

export async function trackPurchase(orderCode: string) {
    const orderData = await api.get(orderURL(orderCode)).catch(() => null);
    if (orderData) {
        gtag('event', 'purchase', {
            transaction_id: orderCode,
            value: orderData.totalPriceWithTax.value,
            currency: 'NOK',
            shipping: orderData.deliveryCost.value,
            coupon: orderData.giftcardAmount ? 'gavekort' : undefined,
            shipping_tier: orderData.deliveryMode.name,
            payment_type: undefined, // TOOD: we don't get this in the orderData today
            items: orderData.entries.map((e: Entry) => ({
                item_id: e.product.code,
                quantity: e.quantity,
                index: e.entryNumber,
            })),
        });
    }
}
