import { useCallback, useContext, useState } from 'react';
import * as api from '../../js/api';
import { ReactPageFunction } from '../../js/cloudflare/types';
import { site, storeAPI, userAPI } from '../../js/config';
import Page from '../../js/generic/Page';
import TabButton from '../../js/generic/TabButton';
import { WrapTrackingContext } from '../../js/generic/TrackingContext';
import HeaderFooterLayout, { StaticLayoutProps } from '../../js/layout/HeaderFooterLayout';
import { UserContext } from '../../js/login/userContext';
import { ErrorType, Openings, StoreWithOpeningTimes } from '../../js/model/types';
import FavoriteStoreAction from '../../js/storelocator/FavoriteStoreAction';
import OpeningsTable from '../../js/storelocator/OpeningsTable';
import { StoreDetailsHeader, StoreSelectionLink } from '../../js/storelocator/StoreDetails';
import { setCacheControl } from '../../js/utils/responseHelpers';
import useBooleanState from '../../js/utils/useBooleanState';

interface Props {
    store: StoreWithOpeningTimes;
    url: string;
}

export const onRequestGet: ReactPageFunction<'/butikk/[id]', Props> = async ({ params, env, next, renderToResponse, request }) => {
    const storeId = params.id;
    const res = await env.BACKEND.fetch(storeAPI.storeURL(storeId) + '?fields=FULL');

    if (res.status === 404) return next();

    if (res.status !== 200) throw new Error(`Request to ${storeAPI.storeURL(storeId)} responded with ${res.status}`);

    const store = await res.json<StoreWithOpeningTimes>().catch(() => undefined);

    if (!store) return next();

    return renderToResponse({
        store,
        url: request.url,
    }).then(setCacheControl('public, max-age=300, stale-while-revalidate=30'));
};

export function StaticLayout({ assets, props }: StaticLayoutProps<Props>) {
    const title = `Vinmonopolets butikk - ${props.store.displayName}`;
    const description = `Vinmonopolets butikk på ${props.store?.address?.line1}, ${props.store.address?.postalCode} ${props.store.address?.town}. Se åpningstider og hva som er forventet trafikk.`;
    return (
        <HeaderFooterLayout
            assets={assets}
            className="page-storeinformation"
            title={title}
            page_title="Butikk"
            meta={[{ name: 'description', content: description }]}
        />
    );
}

export default function StoreDetailsPage({ store, url }: Props) {
    const [error, setError] = useState<ErrorType | null>(null);
    const [isLoading, setIsLoading] = useBooleanState();
    const [currentTab, setCurrentTab] = useState('OpeningHours');
    const { showLoginModal, refreshUser, user } = useContext(UserContext);

    const setAsFavoriteStore = useCallback(async (store: { name: string }) => {
        if (showLoginModal()) {
            return;
        }
        setIsLoading.toTrue();
        setError(null);
        try {
            await api.patchJson(userAPI.favouriteStoreURL(), { id: store.name });
            refreshUser();
        } catch (error: any) {
            setError(error);
        } finally {
            setIsLoading.toFalse();
        }
    }, []);

    const openingHours = currentTab === 'OpeningHours';
    const trafficLights = currentTab === 'TrafficLights';

    // only show tabs if we have trafficData
    const showTimeSlotTraffic = store.openingTimes?.some((openingtime: Openings) => 'timeSlotTraffic' in openingtime);

    const isFavoriteStore = store.name === user?.favouriteStore?.name;

    const structuredData = getStoreStructuredData(store, url);
    return (
        <Page isSpinning={isLoading} title={store.displayName} error={error} backUrl={site.findStoreUrl()} sectionClassname="store-finder-wrapper">
            <StoreDetailsHeader store={store} showAssortment={true} />
            {showTimeSlotTraffic && (
                <div className="tab-group--centered" role="tablist" aria-labelledby="tablist-store-info">
                    <WrapTrackingContext set={{ tab_name: 'butikkinformasjon' }}>
                        <TabButton selected={openingHours} onSelect={() => setCurrentTab('OpeningHours')} large>
                            Åpningstider
                        </TabButton>
                        <TabButton selected={trafficLights} onSelect={() => setCurrentTab('TrafficLights')} large>
                            Forventet trafikk
                        </TabButton>
                    </WrapTrackingContext>
                </div>
            )}
            <div className="store-details">
                <OpeningsTable openingTimes={store.openingTimes} showTraffic={trafficLights} />
            </div>
            <div className="store-info__actions">
                <FavoriteStoreAction store={store} isFavorite={isFavoriteStore} setFavoriteStore={setAsFavoriteStore} />
                <StoreSelectionLink name={store.name} />
            </div>
            {!!structuredData && <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: structuredData }} />}
        </Page>
    );
}

function getStoreStructuredData(store: StoreWithOpeningTimes, url: string) {
    try {
        return JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'LiquorStore',
            name: store.displayName,
            address: {
                '@type': 'PostalAddress',
                streetAddress: store.address.line1,
                addressLocality: store.address.town,
                postalCode: store.address.postalCode,
                addressCountry: store.address.country?.isocode ?? null,
            },
            geo: {
                '@type': 'GeoCoordinates',
                latitude: store.geoPoint.latitude.toString(),
                longitude: store.geoPoint.longitude.toString(),
            },
            telephone: '22015000',
            openingHoursSpecification: openingHoursData(store.openingTimes),
            url,
        });
    } catch (e) {
        console.error(e);
        return null;
    }
}

function openingHoursData(openingHours: Openings[]) {
    return openingHours
        .filter((o) => o?.openingTime)
        .map((o) => ({
            '@type': 'OpeningHoursSpecification',
            validFrom: new Date(o.date).toISOString().substring(0, 10),
            validThrough: new Date(o.date).toISOString().substring(0, 10),
            opens: o.openingTime?.formattedHour + ':00',
            closes: o.closingTime?.formattedHour + ':00',
        }));
}
