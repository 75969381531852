import CheckoutConfirmationPage from '../../../js/checkout/confirmation';
import HeaderFooterLayout, { AssetProps } from '../../../js/layout/HeaderFooterLayout';
import Authenticated from '../../../js/utils/Authenticated';

export default function () {
    return (
        <Authenticated>
            <CheckoutConfirmationPage />
        </Authenticated>
    );
}

export function StaticLayout({ assets }: AssetProps) {
    return <HeaderFooterLayout assets={assets} title="Ordrebekreftelse" robots="noindex,nofollow" page_title="Bekreftelse" />;
}
