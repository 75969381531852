import { PropsWithChildren, useContext } from 'react';
import { TrackingContext } from '../generic/TrackingContext';
import type { Facet } from '../model/Facets';
import FacetButton from '../search/FacetButton';
import FacetValue from '../search/FacetValue';
import ExpandableCollapse from './ExpandableCollapse';

interface Props {
    facet: Facet;
    onClick(query: string): void;
    removeFacetValue(query: string): void;
    isExpanded: boolean;
    toggleExpand(facetCode: string): void;
}

export default function Facet({ facet, onClick, removeFacetValue, isExpanded, toggleExpand, children }: PropsWithChildren<Props>) {
    const facetValue = facet.values.find((value) => value.selected);

    const { filter_type } = useContext(TrackingContext);

    return (
        <ExpandableCollapse
            title={facet.name}
            icon={
                facetValue && (
                    <FacetButton
                        onClick={() => {
                            gtag('event', 'filter_remove', {
                                filter_type,
                                filter_value: facetValue.name,
                            });
                            removeFacetValue(facetValue.query);
                        }}
                        selected
                        title={facetValue.name}
                    />
                )
            }
            isDisabled={!!facetValue}
            isExpanded={isExpanded}
            toggleExpand={() => toggleExpand(isExpanded ? '' : facet.code)}
            className="facet__list-expandable"
        >
            <div className={`facet__${facet.code}`}>
                {children}
                <div className={`facet__list ${facet.listClass}`}>
                    {facet.values.map((value) => (
                        <FacetValue key={value.code} value={value} onFacetValueSelect={onClick} />
                    ))}
                </div>
            </div>
        </ExpandableCollapse>
    );
}
