import { useState } from 'react';
import InfoBox from '../generic/InfoBox';
import Page from '../generic/Page';
import ZipGroup from '../generic/forms/ZipGroup';
import eventTargetValue from '../utils/eventTargetValue';
import preventDefault from '../utils/preventDefault';

export default function FreeDeliveryCheckPage() {
    const [postalCode, setPostalCode] = useState('');
    const [town, setTown] = useState('');
    const [deliveryNotFree, setDeliveryNotFree] = useState<boolean | null>(null);

    return (
        <Page title="Dekker vi dine fraktkostnader?">
            <section className="white centered">
                <p>
                    Hvis du bor i en kommune uten Vinmonopol eller det er mer enn 30 km til nærmeste butikk, dekker vi fraktkostnaden. Ved å fylle inn
                    postnummer under, kan du sjekke om dette gjelder din adresse.
                </p>
            </section>
            <form onSubmit={preventDefault()}>
                <section className="white form-section">
                    <div className="form-section-info">
                        <h3>Mitt postnummer</h3>
                    </div>
                    <fieldset>
                        <ZipGroup
                            zipCodeName="postalCode"
                            zipCodeLabelText="Postnummer *"
                            townLabelText="Poststed"
                            onChange={eventTargetValue(setPostalCode)}
                            onChangeTown={setTown}
                            zipCodeValue={postalCode}
                            townValue={town}
                            checkDeliveryNotFree={setDeliveryNotFree}
                        />
                        {deliveryNotFree !== null && (
                            <div className="form-group">
                                <InfoBox
                                    message={
                                        deliveryNotFree
                                            ? 'Vi dekker ikke fraktkostnader til dette postnummeret'
                                            : 'Vi dekker fraktkostnader til dette postnummeret'
                                    }
                                />
                            </div>
                        )}
                    </fieldset>
                </section>
            </form>
        </Page>
    );
}
