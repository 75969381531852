import eventTargetChecked from '../utils/eventTargetChecked';
import preventDefault from '../utils/preventDefault';
import useBooleanState from '../utils/useBooleanState';
import NewPaymentInfo from './NewPaymentInfo';

export interface Props {
    selectNewPaymentInfo(saveCard: boolean): void;
    saveCardSelected: boolean;
}

export default function NewPaymentInfoForm({ saveCardSelected, selectNewPaymentInfo }: Props) {
    const [saveCard, setSaveCard] = useBooleanState(saveCardSelected);

    const handleCheck = (checked: boolean) => {
        setSaveCard(checked);
        selectNewPaymentInfo(checked);
    };

    return (
        <>
            <NewPaymentInfo />
            <div className="checkbox-wrapper">
                <input id="saveCard" name="saveCard" type="checkbox" checked={saveCard} onChange={preventDefault(eventTargetChecked(handleCheck))} />
                <label htmlFor="saveCard">Lagre nytt kort</label>
            </div>
        </>
    );
}
