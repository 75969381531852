import { useState } from 'react';
import { PaymentMode } from '../api/cart';
import { CardPaymentInfo, getCardPaymentInfo } from '../api/payment';
import ErrorBox from '../generic/ErrorBox';
import Spinner from '../generic/Spinner';
import useBooleanState from '../utils/useBooleanState';
import { useOnMount } from '../utils/useComponentDidMount';
import NewPaymentInfoButton from './NewPaymentInfoButton';
import PaymentInfoButton from './PaymentInfoButton';

export interface Props {
    selectPaymentInfo(id: string): void;
    selectNewPaymentInfo(saveCard: boolean): void;
    selectedPaymentId?: string;
    selectedPaymentMode: PaymentMode;
}

export default function PaymentCardFinder({ selectedPaymentMode, selectedPaymentId, selectPaymentInfo, selectNewPaymentInfo }: Props) {
    const [isLoading, setIsLoading] = useBooleanState();
    const [error, setError] = useState(null);
    const [paymentInfos, setPaymentInfos] = useState<CardPaymentInfo[]>([]);

    useOnMount(async () => {
        setIsLoading.toTrue();
        try {
            const { payments } = await getCardPaymentInfo();
            const cards = payments.filter((pi) => selectedPaymentId !== pi.id);
            setPaymentInfos(cards);
        } catch (error: any) {
            setError(error);
        } finally {
            setIsLoading.toFalse();
        }
    });

    if (isLoading) {
        return <Spinner isSpinning={true} />;
    }

    return (
        <div className="payment-infos">
            <ErrorBox errors={error} className="margin-bottom" />
            {paymentInfos.map((pi) => (
                <PaymentInfoButton key={pi.id} paymentInfo={pi} selectPaymentInfo={selectPaymentInfo} />
            ))}
            {selectedPaymentMode !== 'NewCard' && <NewPaymentInfoButton selectNewPaymentInfo={selectNewPaymentInfo} />}
        </div>
    );
}
