import { PropsWithChildren } from 'react';
import LoginNeededModal from '../login/LoginNeededModal';
import ErrorBox from './ErrorBox';
import PageHeading from './PageHeading';
import SpinnerContainer from './SpinnerContainer';
import ToastContainer from './Toast/ToastContainer';

export interface Props {
    title?: string;
    subTitle?: string;
    backUrl?: string;
    isSpinning?: boolean;
    error?: any;
    sectionsWrapperClassName?: string;
    sectionClassname?: string;
}

export default function Page({
    title,
    subTitle,
    backUrl,
    isSpinning = false,
    error,
    sectionsWrapperClassName,
    sectionClassname = '',
    children,
}: PropsWithChildren<Props>) {
    const renderSections = () => (
        <>
            <ToastContainer />
            <LoginNeededModal />
            <div className={'section ' + sectionClassname}>
                <div className="section__inner-container">
                    {title && <PageHeading heading={title} subHeading={subTitle} backHref={backUrl} />}
                    <SpinnerContainer isSpinning={isSpinning}>
                        <ErrorBox errors={error} />
                        {children}
                    </SpinnerContainer>
                </div>
            </div>
        </>
    );

    return sectionsWrapperClassName ? <div className={sectionsWrapperClassName}>{renderSections()}</div> : renderSections();
}
