const baseURL = '/vmpws/v2/vmp';

export function townLookupURL() {
    return `${baseURL}/town`;
}

export function giftCardBalanceUrl(giftCardNumber: string) {
    return `${baseURL}/giftcards/${giftCardNumber}`;
}

export function passwordResetRequestURL() {
    return `${baseURL}/password/resetrequest`;
}

export function passwordResetURL() {
    return `${baseURL}/password/reset`;
}

export function consentTemplateURL() {
    return `${baseURL}/consenttemplates/CONSENT_REGISTRATION_VMP`;
}