import HeaderFooterLayout, { AssetProps } from '../../js/layout/HeaderFooterLayout';
import Authenticated from '../../js/utils/Authenticated';
import ListOfWishlistsPage from '../../js/wishlist/ListOfWishlistsPage';

export default function () {
    return (
        <Authenticated>
            <ListOfWishlistsPage />
        </Authenticated>
    );
}

export function StaticLayout({ assets }: AssetProps) {
    return <HeaderFooterLayout assets={assets} className="page-wishlistPage" robots="noindex, nofollow" page_title="Lister" />;
}
