import { ImageUrlBuilder } from '@sanity/image-url/lib/types/builder';
import { ImageFormat, SanityImageObject } from '@sanity/image-url/lib/types/types';
import { DetailedHTMLProps, ImgHTMLAttributes, useContext } from 'react';
import { ImageUrlBuilderContext } from './ImageUrlBuilderContext';

export const imageWithMetadataGroq = /* groq */ `{
    ...,
    asset-> {
        _id,
        url,
        altText,
        description,
        metadata {
            palette {
                dominant {
                    background
                }
            }
        }
    }
}`;

export type ImageWithMetadata = SanityImageObject & {
    asset?: {
        title?: string;
        altText?: string;
        description?: string;
        metadata?: {
            palette?: {
                dominant: {
                    background: string;
                };
            };
        };
    };
};

export interface Props extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    image: ImageWithMetadata;
    /**
     * The sizes this image will have on the page for different media queries.
     *
     * For example: `(min-width: 1440px) 1440px, 100vw`
     * * When the viewport is above 1440px the image will be 1440px wide,
     * * when the viewport is below that it will be the size of the viewport
     *
     */
    sizes?: string;
    /**
     * A list of widths in pixels that the image might be loaded in.
     * You should specify at least any width specified in sizes and double that, for
     * 2x dpr devices
     */
    widths?: number[];
    formats?: ImageFormat[] | ImageFormat;
}

export default function SanityImage({ image, sizes, formats = ['webp', 'jpg'], widths, ...props }: Props) {
    const builder = useContext(ImageUrlBuilderContext);
    const src = builder.image(image);
    return (
        <picture>
            {Array.isArray(formats) ? (
                formats.map((format) => <source key={format} type={`image/${format}`} srcSet={toSrcSet(src, format, widths)} sizes={sizes} />)
            ) : (
                <source key={formats} type={`image/${formats}`} srcSet={toSrcSet(src, formats, widths)} sizes={sizes} />
            )}
            <img src={src.url()} style={{ background: image.asset.metadata?.palette?.dominant.background }} alt={image.asset.altText} {...props} />
        </picture>
    );
}

function toSrcSet(src: ImageUrlBuilder, format: ImageFormat, widths?: number[]) {
    return widths?.map((w) => `${src.width(w).format(format).url()} ${w}w`).join(', ');
}
