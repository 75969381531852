import cn from 'classnames';
import AltSR from '../../generic/AltSR';
import Icon from '../../generic/Icon';
import * as Cart from '../../model/Cart';
import { FormattedPrice } from '../../model/types';
import useBooleanState from '../../utils/useBooleanState';
import CheckoutButton from '../delivery/CheckoutButton';
import GreetingCardForm from './GreetingCardForm';

export interface Props {
    cart: GreetingCard;
    onUpdateGreetingCard(): void;
    removeGreetingCard(): void;
}

export interface GreetingCard {
    greetingCardFrom?: string;
    greetingCardTo?: string;
    greetingCardMessage?: string;
    greetingCardCost: FormattedPrice;
}

export default function GreetingCardModule({ cart, onUpdateGreetingCard, removeGreetingCard }: Props) {
    const [isFormVisible, setIsFormVisible] = useBooleanState();

    const hasGreetingCard = Cart.hasGreetingCard(cart);

    return (
        <div className="cart-section">
            <h2>Hilsningskort</h2>
            <div
                className={cn('greeting-card', 'expandable-form', {
                    'greeting-card--saved': hasGreetingCard && !isFormVisible,
                    'expandable-form--active': hasGreetingCard || isFormVisible,
                })}
            >
                <span className="cart-price">
                    {cart.greetingCardCost.value === 0 ? (
                        'Gratis'
                    ) : (
                        <AltSR text={cart.greetingCardCost.formattedValue} sr={cart.greetingCardCost.readableValue} />
                    )}
                </span>
                {isFormVisible ? (
                    <GreetingCardForm
                        cart={cart}
                        onUpdateGreetingCard={() => {
                            setIsFormVisible.toFalse();
                            onUpdateGreetingCard();
                        }}
                        onCancel={setIsFormVisible.toFalse}
                    />
                ) : hasGreetingCard ? (
                    <div>
                        <div className="dispersed-alignment">
                            <h3>
                                Ditt hilsningskort
                                <CheckoutButton
                                    className="greeting-card__btn-cancel"
                                    buttonText="Endre"
                                    onButtonClick={setIsFormVisible.toTrue}
                                    ariaLabel="Endre hilsningskort"
                                />
                            </h3>
                            <button className="btn--transparent" onClick={removeGreetingCard} aria-label="Fjern hilsningskort">
                                <Icon className="icon-delete" />
                            </button>
                        </div>
                        <div>
                            <label>Til:</label>
                            <span>{cart.greetingCardTo}</span>
                        </div>
                        <div>
                            <label>Fra:</label>
                            <span>{cart.greetingCardFrom}</span>
                        </div>
                        <div className="full-width">
                            <label>Din hilsen:</label>
                            <span>{cart.greetingCardMessage}</span>
                        </div>
                    </div>
                ) : (
                    <button
                        className="option-list-item-content"
                        onClick={setIsFormVisible.toTrue}
                        aria-label="Tast [Enter] for å legge til et hilsningskort"
                    >
                        Legg til hilsningskort
                    </button>
                )}
            </div>
        </div>
    );
}
