import InfoBox from '../../js/generic/InfoBox';
import Page from '../../js/generic/Page';
import HeaderFooterLayout, { AssetProps } from '../../js/layout/HeaderFooterLayout';

export default function () {
    // This page is statically rendered
    return null;
}

export function StaticLayout({ assets }: AssetProps) {
    return (
        <HeaderFooterLayout assets={assets} title="Mobilutsjekk" className="informative-page" page_title="Mobilutsjekk">
            <main className="site__body" id="page" tabIndex={-1}>
                <Page title="Mobilutsjekk">
                    <div className="informative-page__content">
                        <div className="informative-page__icon link__icon icon icon-mobile-checkout" />
                        <div className="informative-page__text">
                            <p>For å bruke mobilutsjekk må du laste ned Vinmonopolet-appen.</p>
                            <InfoBox
                                className="margin-bottom"
                                message="Etter at du har installert appen må du skanne QR-koden på nytt for å benytte mobilutsjekk."
                            />
                            <a className="btn btn-primary centered margin-bottom" href="https://apps.apple.com/no/app/vinmonopolet/id1080930756">
                                Last ned fra App Store
                            </a>
                            <a className="btn btn-primary centered" href="https://play.google.com/store/apps/details?id=no.vinmonopolet.android">
                                Last ned fra Google Play
                            </a>
                        </div>
                    </div>
                </Page>
            </main>
        </HeaderFooterLayout>
    );
}
