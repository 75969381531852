import AltSR from '../generic/AltSR';
import Store from '../model/Store';
import { Store as StoreT } from '../model/types';

export interface Props {
    store: StoreWithDistance;
    onClickSuggestion(StoreName: string): void;
    showArrow: boolean;
}

export interface StoreWithDistance extends StoreT {
    formattedDistance: string;
}

export default function StoreInfo({store, onClickSuggestion, showArrow}: Props) {
    return (
        <li className="store-info">
            <button className="btn--white" onClick={() => onClickSuggestion(store.name)}>
                <span className="store-info__name">{store.displayName}</span>
                <span className="store-info__distance">
                <AltSR
                    text={store.formattedDistance}
                    sr={Store.getReadableDistance(store.formattedDistance)}
                />
                </span>
                {showArrow && <span className="store-info__right-arrow"></span>}
            </button>
        </li>
    );
}
