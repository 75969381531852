import cn from 'classnames';
import { useRef } from 'react';
import { useOnClickOutside, useToggle } from 'usehooks-ts';
import Icon from '../generic/Icon';
import preventDefault from '../utils/preventDefault';
import style from './sort-selector.module.less';

export interface SortValue {
    name: string;
    selected: boolean;
    code: string;
}

export interface Props {
    values: SortValue[];
    onValueSelect(oldCode: string, newCode: string): void;
}

export default function SortSelector({ values, onValueSelect }: Props) {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [expanded, toggleExpanded, setExpanded] = useToggle(false);
    useOnClickOutside(buttonRef, () => setExpanded(false));

    if (!values?.length) {
        return null;
    }

    const selectedValue = values.find((value) => value.selected) ?? values[0];

    return (
        <div className={style.container}>
            <button className={cn(style.button, { [style.expanded]: expanded })} onClick={toggleExpanded} ref={buttonRef}>
                <div className={style.selected}>
                    {selectedValue.name}
                    <Icon className={expanded ? 'icon-chevron-up' : 'icon-chevron-down'} />
                </div>
                {expanded && (
                    <ul className={style.list}>
                        {values
                            .filter((v) => !v.selected)
                            .map((value) => (
                                <li key={value.code}>
                                    <a
                                        className="link--no-border"
                                        onClick={preventDefault(() => {
                                            onValueSelect(selectedValue.code, value.code);
                                            gtag('event', 'sort', {
                                                list_sort: value.name,
                                            });
                                        })}
                                        href="#"
                                    >
                                        {value.name}
                                    </a>
                                </li>
                            ))}
                    </ul>
                )}
            </button>
        </div>
    );
}
