import cn from 'classnames';
import { MouseEventHandler, PropsWithChildren, memo, useCallback, useContext } from 'react';
import style from './TabButton.module.less';
import { TrackingContext } from './TrackingContext';

interface Props {
    selected: boolean;
    onSelect(): void;
    disabled?: boolean;
    large?: boolean;
}

export default memo(function TabButton({ selected, onSelect, disabled = false, large, children }: PropsWithChildren<Props>) {
    const { tab_name } = useContext(TrackingContext);
    const onClick: MouseEventHandler<HTMLButtonElement> = useCallback(
        (e) => {
            onSelect();
            gtag('event', 'tabs', {
                tab_name,
                link_text: e.currentTarget.textContent,
            });
        },
        [onSelect, tab_name],
    );
    return (
        <button
            role="tab"
            className={cn(style.tabBtn, { [style.selected]: selected, [style.disabled]: disabled, [style.large]: large })}
            aria-selected={selected}
            onClick={!selected ? onClick : undefined}
        >
            {children}
        </button>
    );
});
