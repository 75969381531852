import cn from 'classnames';
import { createRef, useEffect } from 'react';
import Icon from '../generic/Icon';
import { FacetData, FacetGroup as FacetGroupT } from '../model/Facets';
import { Coords } from '../storelocator/StoreLocatorForm';
import { onElementBlur } from '../utils/onElementBlur';
import { FacetList, Facets } from './Facets';
import style from './SearchFacets.module.less';
import SelectedFacets from './SelectedFacets';

interface Props {
    facets: FacetData;
    totalResults: number;
    facetsPopover: FacetGroupT | boolean;
    onFacetValueSelect(query: string): void;
    onLocationChange(location: Coords): void;
    onFacetsCloseClicked(): void;
    onResetClicked(): void;
}

export default function SearchFacets({
    facets: { groupedFacets, selectedFacets },
    totalResults,
    facetsPopover,
    onFacetValueSelect,
    onLocationChange,
    onFacetsCloseClicked,
    onResetClicked,
}: Props) {
    const visible = !!facetsPopover;
    const popupRef = createRef<HTMLDivElement>();

    useEffect(() => {
        // when popup opens, place focus within it
        if (visible) {
            popupRef?.current?.querySelector('button')?.focus();
        }
    }, [visible]);

    return (
        <div
            tabIndex={-1}
            id="search-refinements"
            className={cn('section__refinements', { visible, 'selected-facet': typeof facetsPopover === 'object' })}
            ref={popupRef}
            onBlur={onElementBlur(onFacetsCloseClicked)}
        >
            {typeof facetsPopover === 'object' ? (
                <>
                    <FacetsTopBar title={facetsPopover.name} onFacetsCloseClicked={onFacetsCloseClicked} />
                    <div className={style.selectedFacets}>
                        <SelectedFacets
                            selectedFacets={selectedFacets.filter((f) => f.code === facetsPopover.code)}
                            onFacetValueUnselect={onFacetValueSelect}
                        />
                    </div>
                    <FacetList facets={facetsPopover.facets} onFacetValueSelect={onFacetValueSelect} onLocationChange={onLocationChange} />
                </>
            ) : (
                <>
                    <FacetsTopBar
                        title={selectedFacets.length > 0 ? 'Valgte filtre' : 'Ingen filtre valgt'}
                        showResetButton={selectedFacets.length > 0}
                        onResetClicked={onResetClicked}
                        onFacetsCloseClicked={onFacetsCloseClicked}
                    />

                    <div className={style.selectedFacets}>
                        <SelectedFacets selectedFacets={selectedFacets} onFacetValueUnselect={onFacetValueSelect} />
                    </div>
                    <Facets facets={groupedFacets} onFacetValueSelect={onFacetValueSelect} onLocationChange={onLocationChange} />
                </>
            )}
            <div className={style.applyButtonWrapper}>
                <button onClick={onFacetsCloseClicked}>Vis resultat ({totalResults})</button>
            </div>
        </div>
    );
}
interface FacetsTopBarProps {
    title: string;
    showResetButton?: boolean;
    onResetClicked?(): void;
    onFacetsCloseClicked(): void;
}

function FacetsTopBar({ title, showResetButton, onResetClicked, onFacetsCloseClicked }: FacetsTopBarProps) {
    return (
        <div className={style.topBar}>
            <h2>{title}</h2>
            {showResetButton && onResetClicked && (
                <button
                    className="link"
                    onClick={() => {
                        gtag('event', 'filter_remove', { filter_type: 'alle' });
                        onResetClicked();
                    }}
                >
                    Nullstill
                </button>
            )}
            <button className={cn('button', style.closeFacetsButton)} onClick={onFacetsCloseClicked}>
                <Icon className="icon-close-button" />
            </button>
        </div>
    );
}
