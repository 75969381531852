import React, { Component } from 'react';
import * as api from '../api';
import { site, userAPI } from '../config';
import ErrorBox from '../generic/ErrorBox';
import Page from '../generic/Page';
import PasswordStrengthIndicator from '../generic/PasswordStrengthIndicator';
import { ToastContext } from '../generic/Toast/toast-context';
import { InputGroup } from '../generic/forms';
import { ErrorType } from '../model/types';
import { getValidationErrors, isValidationErrors } from '../utils/validation';
import ConsentSection from './ConsentSection';

interface State {
    tokenVerificationError?: ErrorType | null;
    errors: Partial<Record<'birthDate' | 'password' | 'consentGiven', ErrorType>>;
    error: any;
    isLoading: boolean;
    birthDate: string;
    token?: string | null;
    password: string;
    checkPassword: string;
    consentGiven: boolean;
}

export default class ImportedAccountPage extends Component<any, State> {
    context!: ToastContext;
    static contextType = ToastContext;

    state: State = {
        error: null,
        errors: {},
        isLoading: true,
        birthDate: '',
        token: '',
        password: '',
        checkPassword: '',
        consentGiven: false,
    };

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        this.setState(
            {
                token: urlParams.get('token'),
            },
            this.verifyToken
        );
    }

    handleConsentChange = (accepted: boolean) => {
        this.setState({
            consentGiven: accepted,
        });
    };

    verifyToken = () => {
        this.setState({
            isLoading: true,
        });
        api.postJson(
            userAPI.importedAccountTokenURL(),
            {
                token: this.state.token,
            })
            .then(() => {
                this.setState({
                    isLoading: false,
                });
            },
            (error) => {
                this.setState({
                    tokenVerificationError: error,
                    isLoading: false,
                });
            }
        );
    };

    completeRegistration = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.setState({
            error: null,
            isLoading: true,
        });
        api.postJson(
            userAPI.importedAccountURL(),
            {
                birthDate: this.state.birthDate,
                password: this.state.password,
                checkPassword: this.state.checkPassword,
                token: this.state.token,
                consentGiven: this.state.consentGiven,
            })
            .then(() => {
                this.context.addToast('Din konto hos vinmonopolet.no er opprettet.');
                setTimeout(() => {
                    window.location.replace(site.loginURL());
                }, 3000);
            },
            (error) => {
                this.setState({
                    isLoading: false,
                });
                this.handleErrors(error);
            }
        );
    };

    handleErrors = (errors: { subject: string; message: string; reason: string }[]) => {
        if (!isValidationErrors(errors)) {
            this.setState({ error: errors });
        } else {
            this.setState({ errors: getValidationErrors(errors) });
        }
    };

    render() {
        const { tokenVerificationError, isLoading } = this.state;
        const showContent = !tokenVerificationError && !isLoading;
        return (
            <Page isSpinning={isLoading} error={tokenVerificationError}>
                {showContent && (
                    <form onSubmit={this.completeRegistration}>
                        <section className="white centered">
                            <h1>Velkommen som bruker på vinmonopolet.no</h1>
                            <p>Vi trenger hjelp fra deg til å fullføre registreringen. Fyll ut feltene under.</p>
                        </section>
                        <section className="white form-section">
                            <div className="form-section-info">
                                <h2>Informasjon vi trenger</h2>
                                <p>Passordet må bestå av minst 6 tegn.</p>
                            </div>
                            <div className="form-section-content">
                                <ErrorBox errors={this.state.error} className="margin-bottom" />
                                <InputGroup
                                    label="Fødselsdato (DDMMÅÅÅÅ) *"
                                    id="birthDate"
                                    name="birthDate"
                                    value={this.state.birthDate}
                                    error={this.state.errors.birthDate}
                                    type="number"
                                    autoComplete="off"
                                    onChange={(e) => {
                                        this.setState({ birthDate: e.target.value });
                                    }}
                                />
                                <InputGroup
                                    label="Passord *"
                                    id="pwd"
                                    name="pwd"
                                    value={this.state.password}
                                    error={this.state.errors.password}
                                    type="password"
                                    autoComplete="new-password"
                                    onChange={(e) => {
                                        this.setState({ password: e.target.value });
                                    }}
                                />
                                <PasswordStrengthIndicator password={this.state.password} />
                                <InputGroup
                                    label="Bekreft passord *"
                                    id="checkPassword"
                                    name="checkPassword"
                                    value={this.state.checkPassword}
                                    type="password"
                                    autoComplete="new-password"
                                    onChange={(e) => {
                                        this.setState({ checkPassword: e.target.value });
                                    }}
                                />
                            </div>
                        </section>
                        <ConsentSection
                            accepted={this.state.consentGiven}
                            onChange={this.handleConsentChange}
                            error={this.state.errors.consentGiven}
                        />
                        <section className="white">
                            <div className="form-buttons-nomargin">
                                <button type="submit" className="btn-primary">
                                    Bekreft
                                </button>
                            </div>
                        </section>
                    </form>
                )}
            </Page>
        );
    }
}
