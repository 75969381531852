import Address, { AddressT } from '../../account/Address';
import ErrorBox from '../../generic/ErrorBox';
import useBooleanState from '../../utils/useBooleanState';
import AddressFinder from './AddressFinder';
import CheckoutButton from './CheckoutButton';
import { DeliveryProps } from './DeliveryMode';

export interface HomeDeliveryMode extends DeliveryProps {
    type: 'HOME_DELIVERY';
    error?: { message: string };
    infoMessage?: string;
}

export interface Props {
    deliveryMode: HomeDeliveryMode;
    deliveryAddress?: AddressT;
    onSelectAddress(id: string): void;
}

export default function HomeDeliveryDetails({ deliveryAddress, deliveryMode, onSelectAddress }: Props) {
    const [isAddressFinderVisible, setIsAddressFinderVisible] = useBooleanState(!deliveryAddress);

    return (
        <>
            {deliveryMode.infoMessage && <div className="option-block">{deliveryMode.infoMessage}</div>}
            {!!deliveryAddress && (
                <div className="option-block">
                    <h3>
                        Mottaker
                        {!isAddressFinderVisible && (
                            <CheckoutButton buttonText="Endre" onButtonClick={setIsAddressFinderVisible.toTrue} ariaLabel="Endre mottaker" />
                        )}
                    </h3>
                    <Address address={deliveryAddress} />
                    <ErrorBox errors={deliveryMode.error?.message} />
                </div>
            )}
            {isAddressFinderVisible && (
                <AddressFinder
                    selectedAddress={deliveryAddress}
                    onSelectAddress={({ id }) => {
                        setIsAddressFinderVisible.toFalse();
                        onSelectAddress(id);
                    }}
                    onCancel={setIsAddressFinderVisible.toFalse}
                />
            )}
        </>
    );
}
