import { useContext, useEffect, useState } from 'react';
import * as api from '../api';
import { getListOfWishlistsURL, getWishlistURL } from '../config/WishListAPIConfig';
import Page from "../generic/Page";
import { ToastContext } from '../generic/Toast/toast-context';
import Authenticated from '../utils/Authenticated';
import useBooleanState from '../utils/useBooleanState';
import AddListForm from './AddListForm';
import ListOfListsItem from './ListOfListsItem';

export interface List {
    code: string;
    name: string;
    numberOfEntries: number;
    userDefined: boolean;
}
export interface Lists {
    defaultLists: List[];
    customLists: List[];
}

export default function ListOfWishlistsPage () {
    const toast = useContext(ToastContext);
    const [isLoading, setIsLoading] = useBooleanState(true);
    const [error, setError] = useState(null);
    const [addList, setAddList] = useBooleanState();
    const [lists, setLists] = useState<Lists>();

    const fetchLists = () => {
        setError(null);
        setIsLoading.toTrue();
        api.get(getListOfWishlistsURL())
            .then((data) => {setLists(data)})
            .catch(onFail)
            .finally(setIsLoading.toFalse)
    };

    useEffect(() => {
        fetchLists();
    }, []);

    const onAddSuccess = () => {
        setAddList.toFalse();
        fetchLists();
    };

    const deleteWishlist = (code:string) => {
        setError(null);
        setIsLoading.toTrue();
        api.delete(
            getWishlistURL(code))
            .then(() => {
                fetchLists();
                toast.addToast('Listen er slettet.');
            },
            onFail);
    };

    const onFail = (error: any) => {
        setError(error);
        setIsLoading.toFalse();
    };

    const wishList = (list: List) => <ListOfListsItem key={list.code} list={list} onSave={fetchLists} onDelete={deleteWishlist}/>;

    return (
        <Authenticated>
            <Page title='Mine lister' isSpinning={isLoading} error={error} sectionsWrapperClassName='listoflists-page'>
                {!!lists && (
                    <>
                        <ul className='pagelist'>{lists.defaultLists.map((list:List) => wishList(list))}</ul>
                        <ul className='pagelist'>
                            {lists.customLists.map((list:List) => wishList(list))}
                            {addList ? (
                                <AddListForm labelText='Navn på ny liste' onCancel={setAddList.toFalse} onSuccess={onAddSuccess}/>
                            ) : (
                                <li className="flex-centered">
                                    <button onClick={setAddList.toTrue}>Opprett ny liste</button>
                                </li>
                            )}
                        </ul>
                    </>
                )}
            </Page>
        </Authenticated>
    )
}
