import cn from 'classnames';

export interface AddressT {
    id: string;
    defaultAddress: boolean;
    firstName: string;
    lastName: string;
    line1: string;
    postalCode: string;
    town: string;
    phone: string;
}

export interface Props {
    displayName?: string;
    address: AddressT;
    showAddress?: boolean;
    showPhone?: boolean;
    showPhoneLabel?: boolean;
    className?: string;
}
export default function Address({
    address,
    displayName = `${address.firstName} ${address.lastName}`,
    showAddress = true,
    showPhone = false,
    showPhoneLabel = false,
    className,
}: Props) {
    return (
        <div className={cn('address', className)}>
            <div className="main">{displayName}</div>
            {showAddress && address && (
                <div>
                    {address.line1}
                    <br />
                    {address.postalCode} {address.town}
                </div>
            )}
            {showPhone && address && (
                <div>
                    {showPhoneLabel && 'Telefon: '}
                    {address.phone}
                </div>
            )}
        </div>
    );
}
