import cn from 'classnames';
import { PropsWithChildren } from 'react';
import { useLockedBody } from 'usehooks-ts';
import preventDefault from '../utils/preventDefault';
import Icon from './Icon';
import style from './Modal.module.less';

export interface Props {
    isOpen?: boolean;
    onClose?: () => void;
    padding?: boolean;
    size?: 'small' | 'medium' | 'large';
}

export default function Modal({ isOpen = false, size = 'medium', padding = false, onClose, children }: PropsWithChildren<Props>) {
    useLockedBody(isOpen);

    if (!isOpen) return;

    return (
        <dialog
            ref={(e) => {
                if (!e) return;
                if (e.open) e.close(); // this is a workaround for a bug in Chrome 114
                e.showModal();
            }}
            className={cn(style.dialog, style[size])}
            onCancel={preventDefault(onClose)}
            onClick={(e) => e.target === e.currentTarget && onClose?.()}
        >
            <div className={cn(style.dialogContent, { [style.noPadding]: !padding })}>
                {!!onClose && (
                    <button className={style.dialogClose} onClick={onClose} aria-label="Lukk">
                        <Icon className="icon-xmark" />
                    </button>
                )}
                {children}
            </div>
        </dialog>
    );
}
