import cn from 'classnames';
import { useContext } from 'react';
import { useDebounce, useToggle } from 'usehooks-ts';
import { UserContext } from '../login/userContext';
import preventDefault from '../utils/preventDefault';

export default function Logout() {
    const { isLoggedIn, logout } = useContext(UserContext);
    const [isBusy, toggleBusy] = useToggle(false);

    const onClick = async () => {
        toggleBusy();
        await logout();
        location.href = '/';
    };

    const isLoggedInDelayed = useDebounce(isLoggedIn, 1000);

    if (!isLoggedInDelayed) return null;

    return (
        <div className="primary-navigation__top-nav-item primary-navigation__logout">
            <a href="/" className={cn({ 'btn-spinner': isBusy })} onClick={preventDefault(onClick)}>
                Logg ut
            </a>
        </div>
    );
}
