import { get, post, putJson } from '.';
import * as cartAPI from '../config/CartAPIConfig';
import * as userAPI from '../config/UserAPIConfig';

export type PaymentCardLogos = 'new-card' | 'visa' | 'master' | 'vipps';
export interface CardPaymentInfo {
    id: string;
    defaultPayment: boolean;
    cardType: {
        code: PaymentCardLogos;
        name: string;
    };
    cardNumber: string;
    expired: boolean;
    expiryMonth: string;
    expiryYear: string;
}

export async function getCardPaymentInfo() {
    return await get<{ payments: CardPaymentInfo[] }>(userAPI.paymentDetailsURL());
}

export async function setPaymentInfo(id: string, saveCard = false) {
    return await putJson(`${cartAPI.paymentDetailsURL()}?paymentDetailsId=${id}&saveCard=${saveCard}`);
}

export type ProcessPaymentResponse =
    | {
          cancelled: boolean;
          completed: false;
          transactionId: string;
      }
    | {
          cancelled: false;
          completed: true;
          orderCode: string;
          transactionId: string;
      };

export async function processPayment(transactionId: string, responseCode: string) {
    return await post<ProcessPaymentResponse>(cartAPI.processPaymentURL(), {
        transactionId,
        responseCode,
    });
}

export type GetOrderStatusResponse =
    | {
          cancelled: boolean;
          completed: false;
          transactionId: string;
          retrySeconds: number;
      }
    | {
          cancelled: false;
          completed: true;
          orderCode: string;
          transactionId: string;
      };

export async function getOrderStatus(transactionId: string, retryCount: number) {
    return await get<GetOrderStatusResponse>(cartAPI.orderStatusURL(), {
        transactionId,
        retryCount: retryCount.toString(),
    });
}
