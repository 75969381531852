import { PropsWithChildren } from 'react';

export default function SummaryBox({ children }: PropsWithChildren) {
    return (
        <div className="cart-section">
            <h2>Bestillingsoversikt</h2>
            <div className="cart-summary" id="nav-cart-summary" tabIndex={-1}>
                {children}
            </div>
        </div>
    );
}
