import MyStorePage from '../../js/account/MyStorePage';
import HeaderFooterLayout, { AssetProps } from '../../js/layout/HeaderFooterLayout';
import Authenticated from '../../js/utils/Authenticated';

export default function () {
    return (
        <Authenticated>
            <MyStorePage />
        </Authenticated>
    );
}

export function StaticLayout({ assets }: AssetProps) {
    return <HeaderFooterLayout assets={assets} title="Mitt Vinmonopol" robots="noindex, nofollow" page_title="Mitt Vinmonopol" />;
}
