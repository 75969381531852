import { SanityDocument } from '@sanity/client';
import { ComponentType } from 'react';
import { SetDataAttribute } from './DataAttributeContext';
import style from './Grid.module.less';

interface Props<T extends SanityDocument> {
    list: T[];
    component: ComponentType<T>;
}

export default function Grid<T extends SanityDocument>({ list, component: Component }: Props<T>) {
    return (
        <ul className={style.list}>
            {list.map((d) => (
                <SetDataAttribute key={d._id} id={d._id} type={d._type}>
                    <Component {...d} />
                </SetDataAttribute>
            ))}
        </ul>
    );
}
