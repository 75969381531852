import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { FormattedPrice } from '../model/types';
import Price from './PriceFormat';

export interface Props {
    isTotal?: boolean;
    price: FormattedPrice;
}

export default function SummaryItem({ price, isTotal, children }: PropsWithChildren<Props>) {
    return (
        <div className={classNames('summary-item', { total: isTotal })}>
            <div>{children}</div>
            <Price price={price} />
        </div>
    );
}
