import { Dispatch, SetStateAction, useState } from "react";

export interface SetBooleanState extends Dispatch<SetStateAction<boolean>> {
  /**
   * Toggle the boolean value
   */
  toggle: () => void;

  /**
   * Set the boolean value to false
   */
  toFalse: () => void;

  /**
   * Set the boolean value to true
   */
  toTrue: () => void;
}

/**
 * Create a state for a boolean value with useful methods
 *
 * @param initial The initial value or factory function, defaults to false
 * @returns A pair of state and setState
 */
export default function useBooleanState(
  initial: boolean | (() => boolean) = false
) {
  const [state, setState] = useState<boolean>(initial);

  const setBooleanState = makeSetter(setState);

  return [state, setBooleanState] as const;
}

function makeSetter(setState: Dispatch<SetStateAction<boolean>>) {
  if (mustInitialize(setState)) {
    setState.toggle = () => setState((x) => !x);
    setState.toFalse = () => setState(false);
    setState.toTrue = () => setState(true);
    return setState;
  }

  return setState as SetBooleanState
}

function mustInitialize(setState: Dispatch<SetStateAction<boolean>>): setState is SetBooleanState {
  return ('toggle' in setState) === false;
}
