import { useEffect, useState } from 'react';
import * as api from '../api';
import { site } from '../config';
import { notificationUrl } from '../config/ProductAPIConfig';
import { productNotificationURL } from '../config/UserAPIConfig';
import ConfirmModal from '../generic/ConfirmModal';
import EmptyState from '../generic/EmptyState';
import Icon from '../generic/Icon';
import Page from '../generic/Page';
import Pagination from '../generic/Pagination';
import { ErrorType, PaginationType, Product } from '../model/types';
import { ProductCardDetails, ProductImageAndInfo } from '../product/ProductCard';
import Authenticated from '../utils/Authenticated';
import preventDefault from '../utils/preventDefault';
import * as url from '../utils/url';
import useBooleanState from '../utils/useBooleanState';

interface Notification {
    creationgDate: {
        formattedValue: string;
    };
    product: ProductCardDetails;
}

export default function NotificationPage() {
    const [notifications, setNotifications] = useState<Notification[]>();
    const [isLoading, setIsLoading] = useBooleanState(true);
    const [error, setError] = useState<ErrorType | null>(null);
    const [pagination, setPagination] = useState<PaginationType>();

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        let page = parseInt(urlParams.get('currentPage') ?? '0');
        if (!Number.isInteger(page) || page < 0) page = 0;
        getNotfificationList(page, true);

        window.addEventListener('popstate', () => {
            const currentParams = new URLSearchParams(location.search);
            const page = parseInt(currentParams.get('currentPage') ?? '0');
            getNotfificationList(page, true);
        });

        window.addEventListener('pageshow', (event) => {
            if (event.persisted) {
                getNotfificationList(page, true);
            }
        });
    }, []);

    const getNotfificationList = (page = 0, replaceHistory = false) => {
        setIsLoading.toTrue();
        setError(null);

        const urlParams = new URLSearchParams(location.search);
        if (page > 0) {
            urlParams.set('currentPage', page.toString());
        } else {
            urlParams.delete('currentPage');
        }

        url.setUrl(urlParams.toString(), replaceHistory);
        api.get(productNotificationURL(), { pageSize: '12', currentPage: page.toString() })
            .then((data) => {
                /* in case of bogus url currentPage param */
                if (page > 0 && page >= data.pagination.totalPages) return getNotfificationList();

                setPagination(data.pagination);
                setNotifications(data.productStatusNotifications);
            })
            .catch((error: ErrorType) => setError(error))
            .finally(setIsLoading.toFalse);
    };

    const onDelete = async (product: Product) => {
        setIsLoading.toTrue();
        setError(null);
        try {
            await api.delete(notificationUrl(product.code));
        } catch (error: any) {
            setError(error);
        } finally {
            getNotfificationList(pagination?.currentPage);
            setIsLoading.toFalse();
        }
    };

    return (
        <Authenticated>
            <Page
                title="Mine varslinger"
                backUrl={site.accountURL()}
                sectionsWrapperClassName="my-page"
                error={error}
                isSpinning={isLoading}
                subTitle={notifications?.length ? `${pagination?.totalResults} produkter` : undefined}
            >
                {notifications &&
                    pagination &&
                    (notifications.length ? (
                        <div className="notifications-list">
                            <p className="info-text">
                                Du har bedt om varsling hvis disse varene blir tilgjengelig. Når varselet sendes for en vare vil den forsvinne fra
                                listen.
                            </p>
                            <ul className="product-list row-max-4">
                                {notifications.map((notification: Notification) => (
                                    <ProductNotification key={notification.product.code} product={notification.product} onDelete={onDelete} />
                                ))}
                            </ul>
                            <Pagination {...pagination} onGotoPage={getNotfificationList} />
                        </div>
                    ) : (
                        <EmptyState
                            icon="icon-my-notifications"
                            text="Om du har bedt om varsel hvis varer blir tilgjengelige igjen, vil de dukke opp her."
                        />
                    ))}
            </Page>
        </Authenticated>
    );
}

function ProductNotification({ product, onDelete }: { product: ProductCardDetails; onDelete: (product: ProductCardDetails) => void }) {
    const [isModalOpen, setIsModalOpen] = useBooleanState();

    return (
        <li className="product-item">
            <ProductImageAndInfo product={product} showAddToCart={false} />
            <div className="product-tools no-print">
                <button className="btn-icon" onClick={preventDefault(setIsModalOpen.toTrue)} title="Min liste" aria-label="Min liste">
                    <Icon className="icon-delete black" />
                </button>
                <ConfirmModal
                    onClose={setIsModalOpen.toFalse}
                    onConfirm={() => {
                        onDelete(product);
                        setIsModalOpen.toFalse();
                    }}
                    mainText={`Ønsker du å slette varsling for ${product.name}?`}
                    isOpen={isModalOpen}
                />
            </div>
        </li>
    );
}
