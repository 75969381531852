import GiftcardBalanceCheckerPage from '../js/giftcard';
import HeaderFooterLayout, { AssetProps } from '../js/layout/HeaderFooterLayout';

export default function () {
    return <GiftcardBalanceCheckerPage />;
}

export function StaticLayout({ assets }: AssetProps) {
    return <HeaderFooterLayout assets={assets} title="Sjekk saldo på gavekort" />;
}
