import { accountURL } from '../config/SiteConfig';

export function getParams() {
    const params = new URLSearchParams(location.search);

    return Object.fromEntries(params.entries());
}

export function getIdFromPath(url: Location) {
    return url.pathname.split('/').at(-1);
}

export function getParameter(paramaterName: string) {
    const urlParams = new URLSearchParams(location.search);
    return urlParams.get(paramaterName);
}

export function getBooleanParameter(parameterName: string) {
    const value = getParameter(parameterName);
    switch (value?.toLowerCase()) {
        case 'true':
            return true;
        case 'false':
            return false;
        default:
            return undefined;
    }
}

export function getRedirectUrl() {
    const redirectUrl = new URLSearchParams(window.location.search).get('redirect') ?? accountURL();
    if (new URL(document.baseURI).origin === new URL(redirectUrl, document.baseURI).origin) {
        return redirectUrl;
    } else {
        return accountURL();
    }
}

export function setUrl(params: string, replaceHistory: boolean) {
    const url = params ? `${location.pathname}?${params}` : location.pathname;
    if (replaceHistory) {
        history.replaceState(null, '', url);
    } else if (url !== location.search) {
        history.pushState(null, '', url);
    }
}
