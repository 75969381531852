import { PropsWithChildren } from 'react';
import Icon from '../generic/Icon';

interface Props {
    title: string;
    isExpanded: boolean;
    toggleExpand(): void;
    isDisabled: boolean;
    className?: string,
    icon?: React.ReactNode
}

export default function ExpandableCollapse({ title, isExpanded, toggleExpand, isDisabled, icon, children, className }: PropsWithChildren<Props>) {
    return (
        <div className={`expandable ${isExpanded ? 'expanded' : 'collapsed'} ${className ?? ''}`}>
            {isDisabled ?
                (
                    <div className='expandable__header expandable__header--disabled'>
                        <span dangerouslySetInnerHTML={{ __html: title }} />
                        <div className="expandable__icon-btn">
                            {icon}
                        </div>
                    </div>
                ) : (
                    <button
                        aria-expanded={isExpanded}
                        className='expandable__header'
                        onClick={toggleExpand}
                    >
                        <span dangerouslySetInnerHTML={{ __html: title }} />
                        <div className="expandable__icon-btn">
                            <span className={isExpanded ? 'expandable-icon expandable-icon--open' : 'expandable-icon'}>
                                <Icon className={isExpanded ? 'icon-chevron-up' : 'icon-chevron-down'} />
                            </span>
                        </div>
                    </button>
                )}
            {isExpanded && !isDisabled && children}
        </div>
    )
}
