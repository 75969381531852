export default function prettyBytes(bytes: number) {
    const suffix = [' bytes', 'kB', 'MB', 'GB'];
    let i = 0;
    while (bytes > 1024) {
        i++;
        bytes = bytes / 1024;
    }

    return (bytes < 10 ? bytes.toFixed(1) : bytes.toFixed(0)) + suffix[i];
}
