import NewPaymentInfo from './NewPaymentInfo';

export interface Props {
    selectNewPaymentInfo(saveCard: boolean): void;
}

export default function NewPaymentInfoButton({ selectNewPaymentInfo }: Props) {
    return (
        <button
            className="payment-info-button"
            onClick={() => selectNewPaymentInfo(true)}
            aria-label="Velg nytt kort. Betalings detaljene legges til senere."
        >
            <NewPaymentInfo />
        </button>
    );
}
