import React from "react";

export interface Props {
    ariaLabel: string;
    legend?: string;
}

export default function OptionList({ariaLabel, legend, children}: React.PropsWithChildren<Props>) {
    return (
        <fieldset className='options-list' aria-label={ariaLabel}>
            <legend>{legend ? legend : ariaLabel}</legend>
            {children}
        </fieldset>
    );
}
