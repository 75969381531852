import { site } from '../../config';
import style from './ProductStickers.module.less';
import ProductTags, { Sticker } from './ProductTags';

export interface Props {
    stickers?: Sticker[];
    sustainable?: boolean;
}

export default function ProductStickers({ stickers, sustainable }: Props) {
    if (!stickers) return null;

    return (
        <div className={style.stickerContainer}>
            {sustainable && (
                <div className={style.sustainableContainer}>
                    <SustainableCorner />
                    <h5>Bærekraft</h5>
                    <p>
                        Produkter med ett eller flere bærekraftselementer er merket med et grønt hjørne.{' '}
                        <a href={site.sustainabilitySymbolsUrl()}>Les mer</a>
                    </p>
                    <ProductTags tags={stickers.filter((tag) => tag.sustainability)} />
                </div>
            )}
            <ProductTags tags={stickers.filter((tag) => !tag.sustainability)} />
        </div>
    );
}

export function SustainableCorner() {
    return <div className={style.sustainable}></div>;
}
