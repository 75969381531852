import { PaymentMode } from '../api/cart';
import { CardPaymentInfo } from '../api/payment';
import CheckoutButton from '../cart/delivery/CheckoutButton';
import useBooleanState from '../utils/useBooleanState';
import NewPaymentInfoForm from './NewPaymentInfoForm';
import PaymentCardFinder from './PaymentCardFinder';
import PaymentInfo from './PaymentInfo';

export interface Props {
    selectPaymentInfo(id: string): void;
    selectNewPaymentInfo(saveCard: boolean): void;
    paymentInfo?: CardPaymentInfo;
    paymentSaveCard?: boolean;
    paymentMode: PaymentMode;
}

export default function PaymentCardDetails({ paymentInfo, paymentSaveCard = true, paymentMode, selectNewPaymentInfo, selectPaymentInfo }: Props) {
    const hasSelectedPaymentMethod = !!paymentInfo || paymentMode === 'NewCard';
    const [isCardFinderVisible, setIsCardFinderVisible] = useBooleanState(!hasSelectedPaymentMethod);

    return (
        <>
            {hasSelectedPaymentMethod && (
                <div className="option-block">
                    <h4>
                        Valgt betalingskort
                        {!isCardFinderVisible ? (
                            <CheckoutButton buttonText="Velg annet kort" onButtonClick={setIsCardFinderVisible.toTrue} />
                        ) : (
                            <CheckoutButton buttonText="Avbryt" onButtonClick={setIsCardFinderVisible.toFalse} />
                        )}
                    </h4>
                    {paymentInfo ? (
                        <div className={'payment-info payment-info--selected'}>
                            <PaymentInfo paymentInfo={paymentInfo} />
                        </div>
                    ) : (
                        <div className={'payment-info payment-info--selected payment-info__type--new-card'}>
                            <NewPaymentInfoForm selectNewPaymentInfo={selectNewPaymentInfo} saveCardSelected={paymentSaveCard} />
                        </div>
                    )}
                </div>
            )}
            {isCardFinderVisible && (
                <PaymentCardFinder
                    selectedPaymentId={paymentInfo?.id}
                    selectedPaymentMode={paymentMode}
                    selectPaymentInfo={(id) => {
                        setIsCardFinderVisible.toFalse();
                        selectPaymentInfo(id);
                    }}
                    selectNewPaymentInfo={(saveCard) => {
                        setIsCardFinderVisible.toFalse();
                        selectNewPaymentInfo(saveCard);
                    }}
                />
            )}
        </>
    );
}
