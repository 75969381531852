import HeaderFooterLayout, { AssetProps } from '../../../js/layout/HeaderFooterLayout';
import OrderDetailsPage from '../../../js/order/OrderDetailsPage';
import Authenticated from '../../../js/utils/Authenticated';

export default function () {
    return (
        <Authenticated>
            <OrderDetailsPage />
        </Authenticated>
    );
}

export function StaticLayout({ assets }: AssetProps) {
    return <HeaderFooterLayout assets={assets} title="Kjøp" robots="noindex, nofollow" page_title="Kjøp" />;
}
