import { LoggedOutModal } from '../login/LoggedOutModal';
import { AppStore } from './AppStore';
import HeaderCategoryBar from './HeaderCategoryBar';
import PrimaryNavigationTop from './PrimaryNavigationTop';
import SearchBox from './SearchBox';
import SecondaryNavigation from './SecondaryNavigation';

export default function Header() {
    return (
        <>
            <AppStore />
            <div className="section__logo-and-nav-toggler">
                <div className="section__inner-container">
                    <div className="site__logo-and-nav-toggler">
                        <a className="site__go-home" href="/" aria-label="Tilbake til forsiden" />

                        <div className="site__search-container">
                            <SearchBox />
                        </div>

                        <div className="primary-navigation-desktop">
                            <div className="primary-navigation-items-desktop">
                                <PrimaryNavigationTop />
                            </div>
                        </div>

                        <SecondaryNavigation />
                    </div>
                </div>
            </div>
            <HeaderCategoryBar />
            <LoggedOutModal />
        </>
    );
}
