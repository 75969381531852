import HeaderFooterLayout, { AssetProps } from '../js/layout/HeaderFooterLayout';
import LoginRegisterPage from '../js/login';

export default function () {
    return <LoginRegisterPage />;
}

export function StaticLayout({ assets }: AssetProps) {
    return <HeaderFooterLayout assets={assets} className="page-login" title="Logg inn" page_title="Logg inn" />;
}
