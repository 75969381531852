import { useContext } from 'react';
import { useTimeout } from 'usehooks-ts';
import InfoBox from '../InfoBox';
import { ToastContext } from './toast-context';

export interface Props {
    id: number;
    message: string;
}

export default function Toast({ id, message }: Props) {
    const context = useContext(ToastContext);

    useTimeout(() => context.removeToast(id), 3000);

    return <InfoBox message={message} background="deep-sea-green" />;
}