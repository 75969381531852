import { AddressT } from '../../account/Address';
import OptionList from '../../generic/OptionList';
import { PointOfService } from '../../model/types';
import DeliveryMode, { DeliveryMode as DeliveryModeT } from './DeliveryMode';

export interface Props {
    cart: {
        selectedPointOfService: PointOfService;
        deliveryAddress?: AddressT;
    };
    deliveryModes: DeliveryModeT[];
    onSelectDeliveryMethod(type: string): void;
    onSelectPointOfService(name: string): void;
    onSelectAddress(addressId: string): void;
}

export default function DeliveryModes({ deliveryModes, cart, onSelectDeliveryMethod, onSelectPointOfService, onSelectAddress }: Props) {
    return (
        <div className="cart-section" id="delivery-method-section" tabIndex={-1}>
            <OptionList ariaLabel="Velg leveringsmåte" legend="Levering">
                {deliveryModes.map((deliveryMode) => (
                    <DeliveryMode
                        key={deliveryMode.code}
                        deliveryMode={deliveryMode}
                        onSelectDeliveryMethod={onSelectDeliveryMethod}
                        cart={cart}
                        onSelectPointOfService={onSelectPointOfService}
                        onSelectAddress={onSelectAddress}
                    />
                ))}
            </OptionList>
        </div>
    );
}
