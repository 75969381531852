const baseURL = '/vmpws/v2/vmp/faq';

export function getDatabaseURL() {
    return baseURL;
}

export function getCategoryURL(categoryId: string) {
    return `${baseURL}/categories/${categoryId}`;
}

export function getArticleURL(articleId: string) {
    return `${baseURL}/articles/${articleId}`;
}

export function getSearchArticlesURL(searchTerm: string) {
    return `${baseURL}/articles?searchTerm=${searchTerm}`;
}
