import { useContext } from 'react';
import SummaryBox from '../cartAndCheckout/summary/SummaryBox';
import ErrorBox, { ErrorBoxType } from '../generic/ErrorBox';
import SummaryItem from '../generic/SummaryItem';
import { UserContext } from '../login/userContext';
import * as Cart from '../model/Cart';
import { FormattedPrice, PointOfService } from '../model/types';
import { Entry } from './CartEntry';

export interface CartSummaryData {
    entries: Entry[];
    selectedPointOfService: PointOfService;
    subTotal: FormattedPrice;
    greetingCardCost: FormattedPrice;
    deliveryCost: FormattedPrice;
    packageMaterialCost: FormattedPrice;
    totalTax: FormattedPrice;
    totalPriceWithTax: FormattedPrice;
    totalUnitCount: number;
    checkoutDisabled: boolean;
    deliveryMode: {
        name: string;
        type: string;
    };
}

export interface Props {
    cart: CartSummaryData;
    cartHasError: boolean;
    error: ErrorBoxType;
    onGoToCheckout(): void;
    onContinueShopping(): void;
}

export default function CartSummary({ cart, cartHasError, error, onGoToCheckout, onContinueShopping }: Props) {
    const { isLoggedIn } = useContext(UserContext);

    const { totalUnitCount, subTotal, greetingCardCost, deliveryCost, totalTax, totalPriceWithTax, packageMaterialCost, deliveryMode } = cart;

    return (
        <SummaryBox>
            <div className="cart-summary-block">
                <SummaryItem price={subTotal}>{totalUnitCount > 1 ? `${totalUnitCount} varer` : `${totalUnitCount} vare`}</SummaryItem>
                {Cart.hasGreetingCard(cart) && <SummaryItem price={greetingCardCost}>Hilsningskort</SummaryItem>}
                {isLoggedIn && Cart.hasDeliveryCost(cart) && <SummaryItem price={deliveryCost}>{deliveryMode.name}</SummaryItem>}
                {isLoggedIn && Cart.hasPackageMaterialCost(cart) && <SummaryItem price={packageMaterialCost}>Emballasje</SummaryItem>}
            </div>
            <div className="cart-summary-block">
                <SummaryItem price={totalPriceWithTax} isTotal>
                    {isLoggedIn ? 'Totalsum' : 'Totalsum uten frakt'}
                </SummaryItem>
                <SummaryItem price={totalTax}>Herav mva</SummaryItem>
            </div>
            <ErrorBox errors={error} />
            <button type="button" className="btn-secondary" onClick={onContinueShopping}>
                Fortsett å handle
            </button>
            <button type="button" className="btn-primary" onClick={onGoToCheckout} disabled={isLoggedIn && (cartHasError || cart.checkoutDisabled)}>
                {isLoggedIn ? 'Til betaling' : 'Til levering'}
            </button>
        </SummaryBox>
    );
}
