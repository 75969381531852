import { useEffect, useState } from 'react';
import { CartSummaryData } from '../cart/CartSummary';
import SummaryBox from '../cartAndCheckout/summary/SummaryBox';
import { site } from '../config';
import ErrorBox from '../generic/ErrorBox';
import SummaryItem from '../generic/SummaryItem';
import * as Cart from '../model/Cart';
import { FormattedPrice } from '../model/types';
import eventTargetChecked from '../utils/eventTargetChecked';
import preventDefault from '../utils/preventDefault';
import useBooleanState from '../utils/useBooleanState';

export interface Props {
    canContinue: boolean;
    cart: CartSummaryData & {
        formattedLeadTime?: string;
        restAmount: FormattedPrice;
        giftcardAmount?: FormattedPrice;
    };
    onConfirmAndPay(): void;
    onContinueShopping(): void;
}

export default function CheckoutSummary({ cart, canContinue, onConfirmAndPay, onContinueShopping }: Props) {
    const [error, setError] = useState<string | null>(null);
    const [termsAccepted, setTermsAccepted] = useBooleanState();
    const extendedDeliveryInfo = Cart.getExtendedDeliveryInfo(cart);

    useEffect(() => {
        if (termsAccepted) {
            setError(null);
        }
    }, [termsAccepted]);

    const confirmAndPay = () => {
        if (!termsAccepted) {
            setError('Du må akseptere salgsbetingelsene før du kan gå videre.');
            return;
        }
        onConfirmAndPay();
    };

    if (cart == null) return null;

    return (
        <SummaryBox>
            <div className="cart-summary-block">
                <SummaryItem price={cart.subTotal}>{cart.totalUnitCount > 1 ? `${cart.totalUnitCount} varer` : '1 vare'}</SummaryItem>
                {Cart.hasGreetingCard(cart) && <SummaryItem price={cart.greetingCardCost}>Hilsningskort</SummaryItem>}
                {Cart.hasDeliveryCost(cart) && (
                    <SummaryItem price={cart.deliveryCost}>
                        {cart.deliveryMode.name}
                        <br />
                        {extendedDeliveryInfo != null && (
                            <span>
                                {extendedDeliveryInfo}
                                <br />
                            </span>
                        )}
                        {cart.formattedLeadTime}
                    </SummaryItem>
                )}
                {Cart.hasPackageMaterialCost(cart) && <SummaryItem price={cart.packageMaterialCost}>Emballasje</SummaryItem>}
            </div>
            <div className="cart-summary-block">
                <SummaryItem price={cart.totalPriceWithTax} isTotal>
                    Totalsum
                </SummaryItem>
                <SummaryItem price={cart.totalTax}>Herav mva</SummaryItem>
                {cart.giftcardAmount && (
                    <>
                        <SummaryItem price={cart.giftcardAmount}>Betales med gavekort</SummaryItem>
                        <SummaryItem price={cart.restAmount} isTotal>
                            Å betale
                        </SummaryItem>
                    </>
                )}
            </div>
            {!cart.checkoutDisabled && (
                <form className="accept--terms-form checkbox-wrapper">
                    <input
                        id="termsAccepted"
                        name="termsAccepted"
                        type="checkbox"
                        checked={termsAccepted}
                        onChange={eventTargetChecked(setTermsAccepted)}
                    />
                    <label htmlFor="termsAccepted" aria-label="Jeg aksepterer salgsbetingelsene">
                        Jeg aksepterer{' '}
                        <a href={site.termsAndConditionsURL()} target="_blank" aria-label="Vis salgsbetingelsene" rel="noreferrer">
                            salgsbetingelsene
                        </a>
                    </label>
                </form>
            )}
            <ErrorBox errors={error} />
            <button type="button" className="btn-secondary" onClick={preventDefault(onContinueShopping)}>
                Fortsett å handle
            </button>
            <button type="submit" className="btn-primary" onClick={preventDefault(confirmAndPay)} disabled={!canContinue || cart.checkoutDisabled}>
                Bekreft og betal
            </button>
        </SummaryBox>
    );
}
