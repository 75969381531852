import React from 'react';
import { ErrorType } from '../../model/types';

export interface Props {
    className?: string;
    id: string;
    label?: string;
    error?: ErrorType;
}

export default function FormGroup({ className, label, error, children, ...inputProps }: React.PropsWithChildren<Props>) {
    return (
        <div className={`form-group ${className || ''}`}>
            {label && <label htmlFor={inputProps.id}>{label}</label>}
            {children}
            <div id={"error_feedback_" + inputProps.id} className="error" >{error && error.message}</div>
        </div>
    );
}
