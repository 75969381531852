import { useLayoutEffect, useRef } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import Icon from '../../generic/Icon';
import useBooleanState from '../../utils/useBooleanState';
import style from './ProductTags.module.less';

export interface Props {
    tags?: Sticker[];
}

export interface Sticker {
    description: string;
    group: { color: string };
    icon: string;
    sustainability: boolean;
    title: string;
}

export default function ProductTags({ tags }: Props) {
    if (!tags?.length) {
        return null;
    }
    return (
        <ul className={style.tagList}>
            {tags.map((tag) => (
                <Tag key={tag.title} {...tag} />
            ))}
        </ul>
    );
}

function Tag(props: Sticker) {
    const [isOpen, setIsOpen] = useBooleanState();
    const containerRef = useRef<HTMLLIElement>(null);
    useOnClickOutside(containerRef, setIsOpen.toFalse);

    return (
        <li ref={containerRef} style={{ '--tag-color': props.group.color }}>
            <button onClick={setIsOpen.toggle}>{props.title}</button>
            {isOpen && <StickerContainer {...props} />}
        </li>
    );
}

function StickerContainer({ title, icon, description }: Sticker) {
    const popoverRef = useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
        const node = popoverRef.current;

        if (!node) return;

        const { right } = node.getBoundingClientRect();
        const overflowWidth = right - document.body.clientWidth;

        if (overflowWidth > 0) {
            node.style.left = `calc(-${overflowWidth}px - var(--product__layout-padding))`;
        }
    }, [popoverRef.current]);

    return (
        <div className={style.popup} ref={popoverRef}>
            <div className={style.title}>
                <h3>{title}</h3>
                {!!icon && <Icon className={`icon-${icon}`}></Icon>}
            </div>
            <p>{description}</p>
        </div>
    );
}
