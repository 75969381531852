import { useContext, useState } from 'react';
import * as api from '../api';
import { userAPI } from '../config';
import ConfirmModal from '../generic/ConfirmModal';
import ErrorBox from '../generic/ErrorBox';
import ListActionButtons from '../generic/ListActionButtons';
import Spinner from '../generic/Spinner';
import { ToastContext } from '../generic/Toast/toast-context';
import useBooleanState from '../utils/useBooleanState';
import Address, { AddressT } from './Address';
import AddressModal from './AddressModal';

export interface Props {
    address: AddressT;
    onChange(): void;
}

export default function AddressListItem({ address, onChange }: Props) {
    const toast = useContext(ToastContext);
    const [isEditModalOpen, setEditModalOpen] = useBooleanState();
    const [isDeleteModalOpen, setDeleteModalOpen] = useBooleanState();
    const [isLoading, setIsLoading] = useBooleanState();
    const [error, setError] = useState(null);

    const onSavedAddress = () => {
        setEditModalOpen.toFalse();
        onChange();
    };

    const deleteAddress = async () => {
        setDeleteModalOpen.toFalse();
        setIsLoading.toTrue();
        setError(null);
        try {
            await api.delete(userAPI.addressURL(address.id));
            toast.addToast('Adressen er slettet.');
            onChange();
        } catch (error: any) {
            setError(error);
        } finally {
            setIsLoading.toFalse();
        }
    };

    return (
        <li className={address.defaultAddress ? 'spinner-container selected' : 'spinner-container'}>
            <Spinner isSpinning={isLoading} className="spinner--internal" />
            <ErrorBox errors={error} className="margin-bottom" />
            <Address className="list-content" address={address} showPhone showPhoneLabel />
            <ListActionButtons
                onClickEdit={setEditModalOpen.toTrue}
                onClickDelete={!address.defaultAddress ? setDeleteModalOpen.toTrue : undefined}
            />
            <AddressModal address={address} isOpen={isEditModalOpen} onClose={setEditModalOpen.toFalse} onConfirm={onSavedAddress} />
            <ConfirmModal
                onClose={setDeleteModalOpen.toFalse}
                onConfirm={deleteAddress}
                mainText="Er du sikker på at du vil slette adressen?"
                isOpen={isDeleteModalOpen}
            />
        </li>
    );
}
