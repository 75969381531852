import HeaderFooterLayout, { AssetProps } from '../js/layout/HeaderFooterLayout';
import NotFoundPage from '../js/notFoundPage';

export default function () {
    // return nothing, everything on this page is statically rendered
    return null;
}

export function StaticLayout({ assets }: AssetProps) {
    return (
        <HeaderFooterLayout assets={assets}>
            <main className="site__body" id="page" tabIndex={-1}>
                <NotFoundPage />
            </main>
        </HeaderFooterLayout>
    );
}
