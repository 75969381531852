import PaymentInfoLogo from './PaymentInfoLogo';

export default function NewPaymentInfo() {
    return (
        <div className="payment-info">
            <PaymentInfoLogo logo="new-card" />
            <div>
                <div className="payment-info__card-number">Nytt betalingskort</div>
                <div>Kortdetaljene legges til senere</div>
            </div>
        </div>
    );
}
