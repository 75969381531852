import HeaderFooterLayout, { AssetProps } from '../../js/layout/HeaderFooterLayout';
import SearchPage from '../../js/search/SearchPage';

export default function () {
    return (
        <div className="search-page">
            <SearchPage pageTitle="Søkeresultat" press="true" searchType="product" />
        </div>
    );
}
export function StaticLayout({ assets }: AssetProps) {
    return <HeaderFooterLayout assets={assets} className="page-search" robots="noindex,nofollow" />;
}
