import { useContext, useState } from 'react';
import ErrorBox from '../../generic/ErrorBox';
import SubmitButton from '../../generic/forms/SubmitButton';
import * as Cart from '../../model/Cart';
import eventTargetValue from '../../utils/eventTargetValue';
import preventDefault from '../../utils/preventDefault';
import useBooleanState from '../../utils/useBooleanState';
import { CartContext } from '../CartContext';
import CheckoutButton from '../delivery/CheckoutButton';
import { GreetingCard } from './GreetingCardModule';

export interface Props {
    cart: GreetingCard;
    onUpdateGreetingCard(): void;
    onCancel(): void;
}

export default function GreetingCardForm({ cart, onUpdateGreetingCard, onCancel }: Props) {
    const cartContext = useContext(CartContext);
    const [from, setFrom] = useState(cart.greetingCardFrom ?? '');
    const [to, setTo] = useState(cart.greetingCardTo ?? '');
    const [message, setMessage] = useState(cart.greetingCardMessage ?? '');
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useBooleanState();

    const onSubmit = async () => {
        setError(null);
        setIsLoading.toTrue();
        try {
            await cartContext.setGreetingCard(from, to, message);
            onUpdateGreetingCard();
        } catch (error: any) {
            setError(error);
        } finally {
            setIsLoading.toFalse();
        }
    };

    return (
        <div>
            <h3>
                Skriv ditt hilsningskort
                <CheckoutButton
                    className="greeting-card__btn-cancel"
                    buttonText="Avbryt"
                    onButtonClick={onCancel}
                    aria-label="Avbryt hilsningskort"
                />
            </h3>

            <div className="greeting-card__instructions">
                Legg ved en hyggelig hilsen. <br />
                Vær varsom og unngå sensitivt innhold.
            </div>

            <ErrorBox errors={error} />

            <form onSubmit={preventDefault(onSubmit)}>
                <div className="form-group half-width">
                    <label aria-hidden="true" htmlFor="to">
                        Til *
                    </label>
                    <input
                        type="text"
                        aria-required="true"
                        className="form-control"
                        name="to"
                        id="to"
                        value={to}
                        onChange={eventTargetValue(setTo)}
                        placeholder="Hilsningskort til"
                        disabled={isLoading}
                    />
                </div>
                <div className="form-group half-width right">
                    <label aria-hidden="true" htmlFor="from">
                        Fra *
                    </label>
                    <input
                        type="text"
                        aria-required="true"
                        className="form-control"
                        name="from"
                        id="from"
                        value={from}
                        onChange={eventTargetValue(setFrom)}
                        placeholder="Hilsningskort fra"
                        disabled={isLoading}
                    />
                </div>
                <div className="form-group full-width last">
                    <label aria-hidden="true" htmlFor="message" className="greeting-card__message-label">
                        Din hilsen (maks 132 tegn) *{' '}
                        {!!message?.length && <div className="greeting-card__char-count">{132 - message.length} tegn igjen</div>}
                    </label>
                    <textarea
                        aria-required="true"
                        name="message"
                        className="form-control"
                        rows={3}
                        id="message"
                        value={message}
                        placeholder="Hilsningskort hilsen"
                        onChange={eventTargetValue((v) => setMessage(v.substring(0, 132)))}
                        aria-label="Tast hilsen tekst (maks 132 tegn)"
                        disabled={isLoading}
                    />
                </div>

                <div className="right-button">
                    <SubmitButton isLoading={isLoading}>{Cart.hasGreetingCard(cart) ? 'Lagre endringer' : 'Legg til hilsningskort'}</SubmitButton>
                </div>
            </form>
        </div>
    );
}
