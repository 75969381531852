import { useContext, useEffect, useRef, useState } from 'react';
import { productGuideSearch } from '../api/search';
import { site } from '../config';
import Page from '../generic/Page';
import { WrapTrackingContext } from '../generic/TrackingContext';
import { UserContext } from '../login/userContext';
import { Facet as FacetProps, configureProductGuideFacets } from '../model/Facets';
import StoreFacet from '../search/StoreFacet';
import { Coords } from '../storelocator/StoreLocatorForm';
import { scrollToTop } from '../utils/documentUtils';
import { setUrl } from '../utils/url';
import useBooleanState from '../utils/useBooleanState';
import { useOnMount } from '../utils/useComponentDidMount';
import Facet from './Facet';

export default function ProductGuide() {
    const productGuideRef = useRef<HTMLDivElement>(null);
    const { user } = useContext(UserContext);

    const [facets, setFacets] = useState<FacetProps[] | null>(null);
    const [selectedFacets, setSelectedFacets] = useState<{ code: string }[]>([]);
    const [currentQuery, setCurrentQuery] = useState<string>();
    const [expandedFacet, setExpandedFacet] = useState('isGoodfor');
    const [isLoading, setLoading] = useBooleanState(true);
    const [showWarningMsg, setShowWarningMsg] = useBooleanState();
    const [error, setError] = useState(null);

    const getFacets = (queryString?: string, queryParams?: Coords) => {
        setLoading.toTrue();
        productGuideSearch(queryString, queryParams)
            .then((data: any) => {
                const { selectedFacets, unselectedFacets, facetOrder } = configureProductGuideFacets(data.facets, user?.favouriteStore);
                const orderedFacets = sortFacets([...selectedFacets, ...unselectedFacets], facetOrder);
                setFacets(orderedFacets);
                setSelectedFacets(selectedFacets);
                setCurrentQuery(data.currentQuery.query.value);
                setExpandedFacet(getNextFacet(orderedFacets, selectedFacets));
            })
            .catch((error) => setError(error))
            .finally(setLoading.toFalse);
    };

    useOnMount(() => {
        const query = new URLSearchParams(location.search).get('q') ?? undefined;
        getFacets(query);
    });

    useEffect(() => {
        if (!currentQuery) return;
        if (currentQuery === ':relevance') return;
        setUrl(`q=${currentQuery}`, true);
    }, [currentQuery]);

    const onClickFacet = (facetQuery: string) => {
        setShowWarningMsg.toFalse();
        getFacets(facetQuery);
        scrollToTop(productGuideRef.current);
    };

    const goToSearchResults = () => {
        const query = (currentQuery ? currentQuery : '') + '&title=' + encodeURIComponent('Anbefalinger fra drikkevelger');
        if (selectedFacets.length !== 0) {
            window.location.href = site.searchURL(query);
        } else {
            setShowWarningMsg.toTrue();
        }
    };

    return (
        <Page title="Drikkevelger" isSpinning={isLoading} error={error}>
            {!!facets && (
                <div className="product-guide" ref={productGuideRef}>
                    {facets.map((facet: FacetProps) => (
                        <WrapTrackingContext key={facet.code} set={{ filter_type: facet.name }}>
                            <Facet
                                facet={facet}
                                onClick={onClickFacet}
                                toggleExpand={setExpandedFacet}
                                isExpanded={expandedFacet === facet.code}
                                removeFacetValue={getFacets}
                            >
                                {facet.code === 'availableInStores' && (
                                    <StoreFacet onLocationChange={(location) => getFacets(currentQuery, location)} />
                                )}
                            </Facet>
                        </WrapTrackingContext>
                    ))}
                    <button className="product-guide__btn btn-primary" onClick={goToSearchResults}>
                        Se drikkeforslag
                    </button>
                    {showWarningMsg && <p className="product-guide__warning-msg">Fortell oss hva du ønsker ved å ta minst ett valg over</p>}
                </div>
            )}
        </Page>
    );
}

const sortFacets = (facets: FacetProps[], order: string[]) => facets.sort((a, b) => order.indexOf(a.code) - order.indexOf(b.code));

const getNextFacet = (facets: FacetProps[], selectedFacets: { code: string }[]) => (expandedFacet: string) => {
    // find the next facets in line and return the next unselected facet
    const restOfFacets = facets.slice(facets.findIndex((x) => x.code === expandedFacet));
    return restOfFacets?.find((facet) => !selectedFacets.includes(facet))?.code ?? '';
};
