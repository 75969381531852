import SummaryItem from '../generic/SummaryItem';
import { FormattedPrice } from '../model/types';

interface Props {
    amounts: {
        amounts: Amounts[];
        total: Amounts;
    };
}

export interface Amounts {
    caption: string;
    amount: FormattedPrice;
}

export default function OrderSummary({ amounts }: Props) {
    return (
        <div className="order-detail-summary">
            {amounts.amounts.map((amounts) => (
                <SummaryItem key={amounts.caption} price={amounts.amount}>
                    {amounts.caption}
                </SummaryItem>
            ))}
            <SummaryItem price={amounts.total.amount} isTotal>
                {amounts.total.caption}
            </SummaryItem>
        </div>
    );
}
