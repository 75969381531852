import { Store } from '../model/types';

export interface Props {
    isFavorite: boolean;
    setFavoriteStore(store:Store): void;
    store: Store;
}

export default function FavoriteStoreAction(props: Props) {
    if (props.isFavorite) {
        return <p>Denne butikken er mitt Vinmonopol</p>;
    }
    return (
        <button className="btn-secondary" onClick={() => props.setFavoriteStore(props.store)}>
            Lagre som mitt Vinmonopol
        </button>
    );
}
